import coolremoteSDK from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  Thunk,
  thunk,
} from "easy-peasy";
import _ from "lodash";
import { addLocale, useLocale } from "ttag";
import CommonUtils from "../utils/CommonUtils";
import { IRootStoreModel } from "./RootStore";
import { boolean } from "yup";

export interface IUser {
  id: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  role?: string;
  customer?: string;
  site?: string;
  temperatureScale?: number; // 0 - undefined, 1 - celcius, 2 - fahrenheit
  permissions?: any;
  language?: string;
  alertGroups?: string[];
  isAcceptedTOU?: boolean;
  measurementUnits?: number;
  timeFormat?: number;
  dateFormat?: number;
  impersonatingUserId?: any;
  isImpersonation?: boolean;
  is2FA?: boolean;
  enableReportsScheduler?: boolean;
}

export interface IDisplayFlagsMap {
  [key: string]: IDisplayFlags;
}
export interface IDisplayFlags {
  globalEnable: boolean;
  enableSystemDiagnostics: boolean;
  enableSystemDiagnosticsDatepicker: boolean;
  enableUnitDiagnostics: boolean;
  enableUnitDiagnosticsDatepicker: boolean;
  enableUnitDiagnosticsExport: boolean;
  enableAnomalies: boolean;
  enableOperationalAnomalies: boolean;
  enableAlertLog: boolean;
  enableAuditLog: boolean;
  enableSiteManagement: boolean;
  enableUserManagement: boolean;
  enableSettings: boolean;
  enableAlertDelete: boolean;
  enableLanguageSelection?: boolean;
  enableControl?: boolean;
  enableScripts?: boolean;
  enablePowerDistribution?: boolean;
  enableSettingsSystems?: boolean;
  enableSettingsUnitAppControls?: boolean;
  enableSettingsWRCRestrictions?: boolean;
  enableSettingsGroups?: boolean;
  enableSettingsNotifications?: boolean;
  enableUnitStatistics?: boolean;
  enableTrapTemplates?: boolean;
  enableUserManagementUsers?: boolean;
  enablePowerDistributionUsageAnalysis?: boolean;
  enablePowerDistributionZonesSetting?: boolean;
  enablePowerDistributionReportScheduler?: boolean;
  enableScriptsLogs?: boolean;
  enableScriptsProcedures?: boolean;
  enableRealTimeDiagnostics?: boolean;
  enableAutomaticChangeover?: boolean;
  enableAutomationLogic?: boolean;
  enableOperationAutomate?: boolean;
  showRealTimeDiagnostics?: boolean;
  showPowerDistribution?: boolean;
  showSettingsSystems?: boolean;
  showTrapTemplates?: boolean;
  showSettingsUnitAppControls?: boolean;
  showSettingsWRCRestrictions?: boolean;
  showSettingsGroups?: boolean;
  showSettingsNotifications?: boolean;
  showControl?: boolean;
  showUnitDiagnostics?: boolean;
  showSystemDiagnostics?: boolean;
  showUnitStatistics?: boolean;
  showAnomalies?: boolean;
  showAlertLog?: boolean;
  showAuditLog?: boolean;
  showSiteManagement?: boolean;
  showUserManagement?: boolean;
  showUserManagementUsers?: boolean;
  showSettings?: boolean;
  showPowerDistributionUsageAnalysis?: boolean;
  showPowerDistributionZonesSetting?: boolean;
  showPowerDistributionReportScheduler?: boolean;
  showScripts?: boolean;
  showScriptsLogs?: boolean;
  showScriptsProcedures?: boolean;
  showAutomaticChangeover?: boolean;
  showAutomationLogic?: boolean;
  showOperationAutomate?: boolean;
  showReports?: boolean;
  enableReports?: boolean;
  showUnitUsagePattern?: boolean;
  enableUnitUsagePattern?: boolean;
  enableAdvancedSearch?: boolean;
  enableAdvancedSearchLogs?: boolean;
  [index: string]: any;
}

export interface IUserMap {
  [key: string]: IUser;
}

export interface IUsersModel {
  me: IUser;
  dateFormat: any;
  timeFormat: any;
  displayFlagsMap: IDisplayFlagsMap;
  users: IUserMap;
  setUsers: Action<IUsersModel, any>;
  setMe: Action<IUsersModel, any>;
  setFlags: Action<IUsersModel, { displayFlagsMap: IDisplayFlagsMap }>;
  // addUserToMap: Action<IUsersModel, IUser>;
  _storeUpdateUser: Action<
    IUsersModel,
    { userId: string; updatedUserData: IUser }
  >;
  _storeUpdateMe: Action<IUsersModel, { updatedUserData: IUser }>;
  _storeAddUser: Action<IUsersModel, { id: string; user: IUser }>;
  // removeUserFromMap: Action<IUsersModel, { userId: string }>;
  onInitialized: ActionOn<IUsersModel, IRootStoreModel>;
  initialize: Thunk<IUsersModel>;
  initializeAgain: Thunk<IUsersModel>;
  getUsers: Thunk<IUsersModel>;
  getCustomerUsers: Thunk<IUsersModel, string>;
  getRecoveryToken: Thunk<IUsersModel, any>;
  resetPassword: Thunk<IUsersModel, { token: any; password: any }>;
  reportProblem: Thunk<IUsersModel, FormData>;
  updateUser: Thunk<
    IUsersModel,
    { userId: string; updatedData: any },
    any /* injections */,
    IUsersModel
  >;
  updateMe: Thunk<IUsersModel, any, any /* injections */, IUsersModel>;
  updatePassword: Thunk<
    IUsersModel,
    { oldPassword: string; newPassword: string }
  >;
  acceptTOU: Thunk<IUsersModel, void, any /* injections */, IUsersModel>;
  // deleteUser: Thunk<IUsersModel, { userId: string }>;
  createCustomerUser: Thunk<
    IUsersModel,
    { id: string; user: IUser },
    IRootStoreModel
  >;
  myFullName: Computed<IUsersModel, string>;
  getFullName: Computed<IUsersModel, (userId: string) => string>;
  getUsername: Computed<IUsersModel, (userId: string) => string>;
  conditionalConvertToAmericanValue: Computed<
    IUsersModel,
    (value: any, fromMeasurementUnits: string, accuracy?: number) => number
  >;
  conditionalConvertFromAmericanValue: Computed<
    IUsersModel,
    (value: any, toMeasurementUnits: string, accuracy?: number) => number
  >;
  conditionalConvertToAmericanThreshold: Computed<
    IUsersModel,
    (value: any, fromMeasurementUnits: string, accuracy?: number) => number
  >;
  conditionalConvertThresholdToFahrenheit: Computed<
    IUsersModel,
    (value: any, fromMeasurementUnits: string, accuracy?: number) => number
  >;
  conditionalConvertValueToFahrenheit: Computed<
    IUsersModel,
    (value: any, fromMeasurementUnits: string, accuracy?: number) => number
  >;
  conditionalConvertValue: Computed<
    IUsersModel,
    (value: any, fromMeasurementUnits: string, accuracy?: number) => number
  >;
  conditionalConvertFromAmericanThreshold: Computed<
    IUsersModel,
    (value: any, toMeasurementUnits: string, accuracy?: number) => number
  >;
  getScaleDisplay: Computed<IUsersModel, (scale: string) => string>;
  getTemperatureScaleDisplayPlainText: Computed<IUsersModel, () => string>;
  canLoggedInUserViewTriggerTemplates: Computed<IUsersModel, boolean>;
  isLoggedInUserCustomerUser: Computed<IUsersModel, () => boolean>;
  canLoggedInUserViewTriggers: Computed<IUsersModel, boolean>;
  getTemperatureScaleDisplay: Computed<IUsersModel, () => string>;
  getUserPreferences: Thunk<IUsersModel>;
  updateUserPreferences: Thunk<IUsersModel, {}>;
  updateUserSystemPreferences: Thunk<
    IUsersModel,
    { systemId: string; paramsOrder: any; type: string }
  >;
  userPreferences: any;
  setUserPreferences: Action<IUsersModel, any>;
  checkUsernameAvailability: Thunk<IUsersModel, string, any>;
  getUserFlags: Thunk<IUsersModel>;
  getMe: Thunk<IUsersModel>;
  getUserStorePreferences: Thunk<IUsersModel, void>;
}

export const usersModel: IUsersModel = {
  me: { id: "" },
  displayFlagsMap: {},
  users: {},
  timeFormat: computed(
    [(state: any) => state.me, (state, storeState: any) => storeState.types],
    (me, types) => {
      if (!types || !types.timeFormat) {
        return "HH:mm";
      }
      const { timeFormat: selectedTime = "0" } = me;
      const timeFormatObject = types.timeFormat[selectedTime];
      if (timeFormatObject.text === "24 hours") {
        return "HH:mm";
      }
      if (timeFormatObject.text === "12 hours") {
        return "hh:mma";
      }
    }
  ),
  dateFormat: computed(
    [(state: any) => state.me, (state, storeState: any) => storeState.types],
    (me, types) => {
      if (!types || !types.dateFormat) {
        return "DD/MM/YY";
      }
      const { dateFormat: selectedDate = "0" } = me;
      return types.dateFormat[selectedDate].text;
    }
  ),
  setUsers: action((state, payload) => {
    state.users = payload;
  }),
  setMe: action((state, payload) => {
    (window as any).impersonateMode = !!payload?.impersonatingUserId;
    state.me = payload;
  }),

  setFlags: action((state, payload) => {
    state.displayFlagsMap = payload.displayFlagsMap;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => { }
  ),
  getUserFlags: thunk(async (actions, paylaod, { injections }) => {
    const displayFlagsMap =
      await coolremoteSDK.CommercialApplication.getFlags();
    actions.setFlags({ displayFlagsMap });
    return displayFlagsMap;
  }),
  getUsers: thunk((actions, paylaod, { injections }) => {
    const { sdkUser } = injections;
    return sdkUser.getUsers();
  }),
  getMe: thunk(async actions => {
    const me = await coolremoteSDK.User.getMe();
    actions.setMe(me);
    if (!!document.getElementById("root")) {
      document.getElementById("wm-unique-username")?.remove();
    }

    let userP = document.createElement("p");
    userP.id = "wm-unique-username";
    userP.style.display = "none";
    userP.innerHTML = me.username;

    document.getElementById("root")?.appendChild(userP);
    return me;
  }),
  initialize: thunk(async (actions, payload) => {
    const displayFlagsMap =
      await coolremoteSDK.CommercialApplication.getFlags();
    actions.setFlags({ displayFlagsMap });

    coolremoteSDK.User.getMe()
      .then((me: any) => {
        actions.setMe(me);

        if (!!document.getElementById("root")) {
          document.getElementById("wm-unique-username")?.remove();
        }

        let userP = document.createElement("p");
        userP.id = "wm-unique-username";
        userP.style.display = "none";
        userP.innerHTML = me.username;

        document.getElementById("root")?.appendChild(userP);

        return me;
      })
      .catch((err: any) => {
        coolremoteSDK.User.logout();
      });
  }),
  initializeAgain: thunk(async actions => {
    const me = await coolremoteSDK.User.getMe();
    return me;
  }),
  acceptTOU: thunk(async (actions, payload, state) => {
    const updatedUserData = await coolremoteSDK.User.updateUser({
      isAcceptedTOU: true,
    });
    actions._storeUpdateMe({ updatedUserData });
  }),
  getRecoveryToken: thunk(async (actions, payload) => {
    const data = payload.username ? payload.username : payload.email,
      type = payload.username ? "username" : "email",
      app = "commercial";
    return coolremoteSDK.User.getRecoveryToken(data, type, app);
  }),
  resetPassword: thunk((actions, payload) => {
    return coolremoteSDK.User.resetPassword(payload.token, payload.password);
  }),
  reportProblem: thunk(async (actions, payload) => {
    await coolremoteSDK.User.reportProblem(payload);
  }),
  _storeUpdateMe: action((state, payload) => {
    state.me = { ...state.me, ...payload.updatedUserData };
    if (state.users[payload.updatedUserData.id]) {
      state.users[payload.updatedUserData.id] = payload.updatedUserData;
    }
  }),
  _storeUpdateUser: action((state, payload) => {
    if (state.users[payload.userId]) {
      state.users[payload.userId] = payload.updatedUserData;
    }
  }),
  updatePassword: thunk(async (actions, payload) => {
    await coolremoteSDK.User.updateMyPassword(
      payload.oldPassword,
      payload.newPassword
    );
  }),
  updateUser: thunk(async (actions, payload, state) => {
    const storeActions: any = state.getStoreActions(),
      { _handleSocketMessage } = storeActions,
      updatedUserData = await coolremoteSDK.User.update(
        payload.userId,
        payload.updatedData
      ),
      myId = state.getState().me.id;

    if (payload.userId === myId) {
      await coolremoteSDK.User.closeWebSocket();
      await actions.initializeAgain();
      await coolremoteSDK.User.openWebSocket(_handleSocketMessage);
      actions._storeUpdateMe({ updatedUserData });
    } else {
      actions._storeUpdateUser({ userId: payload.userId, updatedUserData });
    }
  }),
  updateMe: thunk(async (actions, payload, state) => {
    const storeActions: any = state.getStoreActions();
    const { _handleSocketMessage } = storeActions;
    const updatedUserData = await coolremoteSDK.User.updateUser(payload);
    const myId = state.getState().me.id;

    if (updatedUserData.id === myId) {
      await coolremoteSDK.User.closeWebSocket();
      await actions.initializeAgain();
      await coolremoteSDK.User.openWebSocket(_handleSocketMessage);
      actions._storeUpdateMe({ updatedUserData });
    } else {
      actions._storeUpdateUser({ userId: payload.userId, updatedUserData });
    }
  }),

  _storeAddUser: action((state, payload) => {
    state.users[payload.id] = payload.user;
  }),

  createCustomerUser: thunk(async (actions, payload, { getStoreActions }) => {
    const newUserData = await coolremoteSDK.Customer.createUser(
      payload.id,
      payload.user
    );
    if (!newUserData) {
      // throw ErrorUtils.errorUnexpected;
    } else {
      actions._storeAddUser({ id: newUserData.id, user: newUserData });
    }

    return newUserData;
  }),

  myFullName: computed(
    [state => state.me, state => state.users],
    (me, users) => {
      return _createUserNamePresentation(
        me.username,
        me.firstName,
        me.lastName
      );
    }
  ),

  getFullName: computed(
    [state => state.me, state => state.users],
    (me, users) => userId => {
      if (_.isNil(users[userId])) {
        return "-";
      }
      const user = users[userId];
      return _createUserNamePresentation(
        user.username,
        user.firstName,
        user.lastName
      );
    }
  ),

  getUsername: computed(
    [state => state.me, state => state.users],
    (me, users) => userId => {
      if (_.isNil(users[userId])) {
        return "-";
      }
      return users[userId].username || "-";
    }
  ),

  conditionalConvertToAmericanValue: computed(
    [state => state.me],
    me =>
      (value: any, fromMeasurementUnits: string, accuracy: number = 1) => {
        if (
          fromMeasurementUnits === "MPa" ||
          fromMeasurementUnits.toLowerCase() === "mpa"
        ) {
          if (me.measurementUnits === 2) {
            return CommonUtils.MPaToPSI(value, accuracy);
          }
          return value;
        }
        if (me.measurementUnits === 2 && fromMeasurementUnits === "kg/cm2") {
          return CommonUtils.kgcm2ToPSI(value, accuracy);
        }
        if (
          me.temperatureScale === 2 &&
          (fromMeasurementUnits === "°C" ||
            fromMeasurementUnits.toLowerCase() === "celsius")
        ) {
          return CommonUtils.celsiusToFahrenheit(value, accuracy);
        }
        return value;
      }
  ),

  conditionalConvertFromAmericanValue: computed(
    [state => state.me],
    me =>
      (value: any, toMeasurementUnits: string, accuracy: number = 1) => {
        if (
          toMeasurementUnits === "MPa" ||
          toMeasurementUnits.toLowerCase() === "mpa"
        ) {
          if (me.measurementUnits === 2) {
            return CommonUtils.PSIToMPa(value, accuracy);
          }
          return value;
        }
        if (me.measurementUnits === 2 && toMeasurementUnits === "kg/cm2") {
          return CommonUtils.PSITokgcm2(value, accuracy);
        }
        if (
          me.temperatureScale === 2 &&
          (toMeasurementUnits === "°C" ||
            toMeasurementUnits.toLowerCase() === "celsius")
        ) {
          return CommonUtils.fahrenheitToCelsius(value, accuracy);
        }
        return value;
      }
  ),

  conditionalConvertToAmericanThreshold: computed(
    [state => state.me, state => state.conditionalConvertToAmericanValue],
    (me, conditionalConvertToAmericanValue) =>
      (value: any, fromMeasurementUnits: string, accuracy: number = 1) => {
        const accuracyMultiplier = Math.pow(10, accuracy);
        // Why Math.round the diff?
        // Run node in your favorite shell. Write 76.8 - 77.7. Enjoy :)
        return (
          Math.round(
            (conditionalConvertToAmericanValue(
              value,
              fromMeasurementUnits,
              accuracy
            ) -
              conditionalConvertToAmericanValue(
                0,
                fromMeasurementUnits,
                accuracy
              )) *
            accuracyMultiplier
          ) / accuracyMultiplier
        );
      }
  ),

  conditionalConvertValue: computed(
    [state => state.me],
    me =>
      (value: any, measurementUnits: string, accuracy: number = 1) => {
        if (
          measurementUnits === "MPa" ||
          measurementUnits.toLowerCase() === "mpa"
        ) {
          if (me.measurementUnits === 2) {
            return CommonUtils.MPaToPSI(value, accuracy);
          }
          return value;
        }
        if (me.measurementUnits === 2 && measurementUnits === "kg/cm2") {
          return CommonUtils.kgcm2ToPSI(value, accuracy);
        }
        if (
          me.temperatureScale === 2 &&
          (measurementUnits === "°C" ||
            measurementUnits.toLowerCase() === "celsius")
        ) {
          return value;
        }
        return value;
      }
  ),
  conditionalConvertValueToFahrenheit: computed(
    [state => state.me],
    me =>
      (value: any, measurementUnits: string, accuracy: number = 1) => {
        if (
          me.temperatureScale === 2 &&
          (measurementUnits === "°C" ||
            measurementUnits.toLowerCase() === "celsius")
        ) {
          return CommonUtils.celsiusToFahrenheit(value, accuracy);
        }
        return value;
      }
  ),
  conditionalConvertThresholdToFahrenheit: computed(
    [state => state.me, state => state.conditionalConvertToAmericanValue],
    (me, conditionalConvertToAmericanValue) =>
      (value: any, fromMeasurementUnits: string, accuracy: number = 1) => {
        const accuracyMultiplier = Math.pow(10, accuracy);
        // Why Math.round the diff?
        // Run node in your favorite shell. Write 76.8 - 77.7. Enjoy :)
        return (
          Math.round(
            (conditionalConvertToAmericanValue(
              value,
              fromMeasurementUnits,
              accuracy
            ) -
              conditionalConvertToAmericanValue(
                0,
                fromMeasurementUnits,
                accuracy
              )) *
            accuracyMultiplier
          ) / accuracyMultiplier
        );
      }
  ),

  conditionalConvertFromAmericanThreshold: computed(
    [state => state.me, state => state.conditionalConvertFromAmericanValue],
    (me, conditionalConvertFromAmericanValue) =>
      (value: any, toMeasurementUnits: string, accuracy: number = 1) => {
        const accuracyMultiplier = Math.pow(10, accuracy);
        // Why Math.round the diff?
        // Run node in your favorite shell. Write 76.8 - 77.7. Enjoy :)
        return (
          Math.round(
            (conditionalConvertFromAmericanValue(
              value,
              toMeasurementUnits,
              accuracy
            ) -
              conditionalConvertFromAmericanValue(
                0,
                toMeasurementUnits,
                accuracy
              )) *
            accuracyMultiplier
          ) / accuracyMultiplier
        );
      }
  ),
  getTemperatureScaleDisplay: computed([state => state.me], me => () => {
    if (me.temperatureScale === 2) {
      return "°F";
    }
    return "°C";
  }),
  getScaleDisplay: computed([state => state.me], me => (scale: string) => {
    if (scale === "°C" || scale.toLowerCase() === "celsius") {
      if (me.temperatureScale === 2) {
        return "°F";
      }
    }

    if (
      scale === "MPa" ||
      scale.toLowerCase() === "mpa" ||
      scale === "kg/cm2"
    ) {
      if (me.measurementUnits === 2) {
        return "PSI";
      }
    }

    return scale;
  }),

  getTemperatureScaleDisplayPlainText: computed(
    [state => state.me],
    me => () => {
      if (me.temperatureScale === 2) {
        return "F";
      }
      return "C";
    }
  ),

  canLoggedInUserViewTriggerTemplates: computed([state => state.me], me => {
    if (me.permissions === "globalAdmin") {
      return true;
    }

    return false;
  }),
  isLoggedInUserCustomerUser: computed([state => state.me], me => () => {
    return !_.isUndefined(me.customer);
  }),

  canLoggedInUserViewTriggers: computed([state => state.me], me => {
    if (me.customer) {
      return true;
    }
    return false;
  }),

  getUserPreferences: thunk(async (actions, payload) => {
    const data = await coolremoteSDK.User.getUserPreferences();
    actions.setUserPreferences(data || {});
    const translationsObj = require(`../locale/${data.professionalLanguage || "en"
      }.po.json`);
    addLocale(data.professionalLanguage || "en", translationsObj);
    useLocale(data.professionalLanguage || "en");
    return data;
  }),
  updateUserPreferences: thunk(async (actions, payload) => {
    const data = await coolremoteSDK.User.updateUserPreferences(payload);
    const translationsObj = require(`../locale/${data.professionalLanguage || "en"
      }.po.json`);
    addLocale(data.professionalLanguage || "en", translationsObj);
    useLocale(data.professionalLanguage || "en");
    actions.setUserPreferences(data);
    return data;
  }),
  updateUserSystemPreferences: thunk(async (actions, payload, { getState }) => {
    const { systemId, paramsOrder, type } = payload;
    const userPref = getState().userPreferences || {};
    const allSystemsPref = userPref?.serviceRecentSystemParamsO || {};
    const systemPref = allSystemsPref[systemId] || {};

    const data = await coolremoteSDK.User.updateUserPreferences({
      serviceRecentSystemParamsO: {
        ...allSystemsPref,
        [systemId]: { ...systemPref, [type]: paramsOrder },
      },
    });
    actions.setUserPreferences(data);
    return data;
  }),
  getUserStorePreferences: thunk((actions, payload, { getState }) => {
    return getState().userPreferences || {};
  }),
  userPreferences: {},
  setUserPreferences: action((state, payload) => {
    state.userPreferences = { ...state.userPreferences, ...payload };
  }),
  getCustomerUsers: thunk((actions, paylaod, { injections }) => {
    const { sdkCustomer } = injections;
    return sdkCustomer.getCustomerUsers(paylaod);
  }),
  checkUsernameAvailability: thunk(async (actions, payload, { injections }) => {
    const { sdkUser } = injections;
    return sdkUser.isUsernameAvailable(payload);
  }),
};

function _createUserNamePresentation(
  username?: string,
  firstName?: string,
  lastName?: string
) {
  if (firstName) {
    if (lastName) {
      return firstName + " " + lastName;
    } else {
      return firstName;
    }
  } else {
    return username || "no name";
  }
}
