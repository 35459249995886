import { useEffect, useState } from 'react';
import { getCurrentEnvName } from '@utils/env';
import { useStoreState } from 'easy-peasy';

/** The app Key for the ChurnZero plugin */
const APP_KEY = '1!P68loCfWoLrWFHdppfcFMsmSHueDEeiotu02y-wbJ24t1493';
/** A flag for enabling debug mode (from local storage) */
const debugMode = Boolean(localStorage.getItem('ChurnZeroDebugMode'));
/** A flag to force the plugin to load in every environment (from local storage) */
const churnZeroForce = Boolean(localStorage.getItem('churnZeroForce'));
/** A that indicate if the environment is production (overridden by churnZeroForce flag) */
const isProduction = getCurrentEnvName() === 'production' || churnZeroForce;

/**
 * A custom hook to manage ChurnZero integration in a React application.
 *
 * This hook initializes ChurnZero, identifies users, and tracks events.
 * It acts as an intermediary between the Easy Peasy store and ChurnZero,
 * ensuring seamless integration and easier maintenance.
 *
 * @returns An object containing `initChurnZero` and `trackEvent` functions.
 */
const useChurnZero = () => {

	const [isLoaded,setIsLoaded] = useState(false);

	const {username:userName,isImpersonation} = useStoreState((state) => state.users.me ?? {});

	const customerId = useStoreState((state) => state.selections?.selections?.customerId ?? '');

	const isChurnZeroLoaded = !!window.ChurnZero && isLoaded && !isImpersonation;

	const initializeChurnZero = (appKey) => {
		if (!isChurnZeroLoaded) {
			console.warn('ChurnZero is not available');
			return;
		}

		window.ChurnZero.push(['setAppKey', appKey]);
	};

	const identifyUser = () => {

		if (!isChurnZeroLoaded) {
			console.warn('ChurnZero is not available');
			return;
		}

		window.ChurnZero.push(['setContact', customerId, userName]);

	};

	/**
 * Tracks a custom event using ChurnZero.
 *
 * This function sends an event to ChurnZero with the specified details. If ChurnZero
 * is not loaded, it logs a warning to the console. If not in a production environment,
 * it logs the event details to the console for simulation purposes.
 *
 * @param {string} eventName - The name of the event to track.
 * @param {string} description - A description of the event.
 * @param {number} [quantity=1] - The quantity associated with the event. Defaults to 1.
 * @param {Object} [customFields={}] - Additional custom fields to include with the event. Defaults to an empty object.
 */
	const trackEvent = (eventName, description, quantity = 1, customFields = {}) => {
		if(!isProduction){
			console.log('ChurnZero simulated event log',{eventName, description, quantity,customFields});
			return;
		}

		if (!isChurnZeroLoaded) {
			console.warn('ChurnZero is not available');
			return;
		}
		if(debugMode){
			console.log('ChurnZero trackEvent',{eventName, description, quantity,customFields});
			window.ChurnZero.verify();
		}
		window.ChurnZero.push(['trackEvent', eventName, description, quantity, customFields]);
	};

	useEffect(()=>{
		if(customerId && userName && isChurnZeroLoaded){
			initializeChurnZero(APP_KEY);
			identifyUser();
		}
	},[customerId,userName,isChurnZeroLoaded]);



	/** This function initialize the ChurnZero tracking connection.
 * @return a cleanup function.
 *
 * Please use inside a useEffect like so:
 * @example
 * useEffect(()=>{
 * return initChurnZero()
 * },[])
 */
	const initChurnZero = () => {

		if(isImpersonation){
			console.warn('ChurnZero is disabled in Impersonation Mode!');
			return () => {};
		}

		if (!isProduction){
			console.warn('ChurnZero is only available in production! If you want to force the plugin to start add churnZeroForce = true in localStorage');
			return () => {};
		}

		const script = document.createElement('script');
		script.src = 'https://coolautomation.eu2app.churnzero.net/churnzero.js';
		script.async = true;
		document.body.appendChild(script);

		script.onload = () => {
			setIsLoaded(true);
			if(debugMode){
				window.ChurnZero.debug();
			}
			console.log('ChurnZero initialized at:',new Date());
		};

		return () => {
			document.body.removeChild(script);
		};
	};


	return {
		initChurnZero,
		trackEvent
	};
};

export default useChurnZero;
