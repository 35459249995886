import { Button as MUIButton, Dialog, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import { DeleteIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { AddIcon } from "../../svgComponents";
import useStyles from "./UserDefinedParamsTab.style";

export default function EditParamPopUp(props: any) {
  const firstOption: any = { type: 1, operation: 1, showOperation: false, paramCode: "", constant: "" };
  const otherOption: any = { type: 1, operation: 2, showOperation: true, paramCode: "", constant: "" };

  const { handleClose, customParam, unitType, brand, options, objectOptions, open, editMode } = props;

  const { name = "", formula = [] } = customParam || {};
  const [paramName, setParamName] = useState<string>(name);
  const [params, setParams] = useState<any>([]);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const { updateCustomParam, createCustomParam } = useStoreActions((action) => action.units);
  const { siteId } = useStoreState((s) => s.selections.selections);

  const customParameterOperationsOptions = useStoreState((s) => s.customParameterOperationsOptions);
  const updateServiceParams = useStoreActions((a) => a.updateServiceParams);
  const classes = useStyles();

  useEffect(() => {
    if (!editMode) {
      setParams([firstOption, otherOption]);
      return;
    }
    const params1: any = [];
    formula.forEach((param: any, index: number) => {
      if (param.type === 1) {
        params1.push({ ...param, showOperation: index === 0 ? false : true, constant: "" });
      } else {
        params1.push({ ...param, showOperation: index === 0 ? false : true, paramCode: -999 });
      }
    });

    if (params1.length === 0) {
      params1.push(firstOption);
    }

    if (params1.length === 1) {
      params1.push(otherOption);
    }
    setParams([...params1]);
  }, []);

  const addNewParam = () => {
    setParams([...params, otherOption]);
  };

  const onApply = () => {
    let showError = false;
    if (!paramName) {
      showError = true;
    }
    for (let index in params) {
      if (showError) {
        break;
      }
      const param = params[index];
      const { type, paramCode, constant } = param;
      if (type === 1) {
        if (!paramCode && +index < 2) {
          showError = true;
        }
      } else {
        if (!constant && constant !== 0) {
          showError = true;
        }
      }

    }

    if (showError) {
      !showErrors && setShowErrors(true);
      return;
    }

    showErrors && setShowErrors(false);
    const formula: any = [];

    params.forEach((param: any) => {
      const { type, operation, paramCode, constant } = param;
      if (type === 1) {
        paramCode !== "" && formula.push({ type, operation, paramCode });
      } else {
        constant !== "" && formula.push({ type, operation, constant });
      }
    });
    const data: any = { name: paramName, formula };

    if (editMode) {
      updateCustomParam({ data, id: customParam.id })
        .then((resp: any) => updateServiceParams({ code: resp.code, param: { ...resp, title: resp.name, plotable: true, isCustomParam: true } }))
        .catch((err: any) => addMessage({ message: err.message }))
        .finally(() => {
          handleClose();
        });
      return;
    }


    createCustomParam({ ...data, unitType, brand, name: paramName, site: siteId })
      .then((resp: any) => updateServiceParams({ code: resp.code, param: { ...resp, title: resp.name, plotable: true, isCustomParam: true } }))
      .catch((err: any) => addMessage({ message: err.message }))
      .finally(() => {
        handleClose();
      });

  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
      classes={{ paper: classes.dialog }}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{editMode ? t`Edit User Defined Parameter` : t`Add User Defined Parameter`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={handleClose}>
          <Close color="#7f7692" />
        </IconButton>
      </div>
      <div className={classes.dialogContent}>
        <OutlinedInput
          placeholder={t`User Defined Parameter Name`}
          value={paramName}
          onChange={(event: any) => setParamName(event.target.value)}
          error={!paramName && showErrors}
          className={classes.fullWidth}
        />
        <div className={classes.paramsContainer}>
          {params.map((param: any, index: number) =>
            <div key={`param-${index}`} className={classes.rowContainer}>
              {param.showOperation &&
                <FormControl variant="outlined" className={classes.operationSelectLabel}>
                  <InputLabel>{t`Operator`}</InputLabel>
                  <Select
                    value={param.operation}
                    onChange={(event: any) => {
                      params[index].operation = event.target.value;
                      setParams([...params]);
                    }}
                    label={t`Operator`}
                    className={classes.operationSelect}
                  >
                    {customParameterOperationsOptions.map((option: any, index: number) =>
                      <MenuItem key={`option-operation-${index}`} value={option.value}>{option.name}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              }
              {
                <>
                  <Autocomplete
                    className={classes.autoCompleteStyle}
                    id="parameters-searchable-select"
                    options={[{ code: -999, name: t`Constant` }, ...options]}
                    value={objectOptions[param.paramCode]}
                    onChange={(event: any, newValue: any) => {
                      params[index].paramCode = newValue?.code || "";
                      params[index].type = 1;
                      if (newValue?.code === -999) {
                        params[index].type = 2;
                      }
                      setParams([...params]);
                    }}
                    getOptionLabel={(option: any) => option?.name || ""}
                    renderInput={(params) => <TextField error={param.paramCode === "" && showErrors && (index === 0 || index === 1)} {...params} label={t` Select Parameter`} variant="outlined" />}
                  />
                  {param.type === 2 && <TextField
                    label={t`Constant`}
                    error={param.constant === "" && showErrors}
                    type="number"
                    value={param.constant}
                    variant="outlined"
                    onChange={(event: any) => {
                      params[index].constant = event.target.value;
                      setParams([...params]);
                    }}
                    className={classes.constantField}
                  />}
                  {index >= 2 &&
                    <IconButton
                      disableRipple
                      onClick={() => {
                        params.splice(index, 1);
                        setParams([...params]);
                      }}
                      classes={{ root: classes.overWriteIcon }}
                    >
                      <DeleteIcon />
                    </IconButton>}
                </>
              }
            </div>
          )}
        </div>
      </div>
      {params.length < 5 &&
        <MUIButton
          className={classes.addNewParamBtn}
          onClick={addNewParam}
        >
          <AddIcon color="#421a40" className={classes.addIcon} />
          <span className={classes.addNewParamSpan}>{t`Add Another Parameter`}</span>
        </MUIButton>}
      <div className={clsx(classes.actionsHolder, { [classes.addTopMargin]: params.length === 5 })}>
        <Button onClick={handleClose} white marginRight width={80}>{t`Cancel`}</Button>
        <Button onClick={onApply} width={80}>{t`Apply`}</Button>
      </div>
    </Dialog>

  );
}
