import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  inputRoot: {
    color: theme.palette.colors.menuFont,
    fontFamily: 'Roboto',
    fontSize: '13px',
    margin: 'auto',
    width: '300px',
    display: 'flex',
    flexDirection: 'row',
    height: '36px',
    borderRadius: '4px',
    padding: '0 15px',
    backgroundColor: theme.palette.colors.mainSearchBackground,
    maxWidth: '70%'
  },
  closeIconStyle: {
    color: 'white',
    padding: '7px',
    '& svg': {
      fontSize: '24px'
    }
  },
  view: {
    display: 'flex',
    height: '100%'
  },
  contentArea: {
    width: '100%',
    height: '100vh',
    maxWidth: 'calc(100% - 240px)',
    overflow: 'hidden'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 1rem',
    height: '70px',
    background: theme.palette.background.default
  },
  shareButton: {
    minHeight: '40px',
    maxHeight: '40px',
    minWidth: '169px',
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: 'normal',
    color: '#fff',
    textTransform: 'none',
    borderRadius: '6px',
    background: theme.gradiants.button,
    '&:hover': {
      boxShadow: 'none'
    },
    '&:focus': {
      boxShadow: 'none'
    }
  },
  loadingContainer: {
    height: '100%',
    background: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  wrapper: {
    height: 'calc(100vh - 130px)',
    padding: '0 1rem 1rem',
    background: 'rgb(236, 235, 239)',
    flexFlow: 'row nowrap'
  },
  tableWrapper: {
    height: '100%'
  },
  popUpContainer: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export default useStyles;
