import {
  Button,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { AcUnit, Close } from '@material-ui/icons';
import { debounce, some, isEmpty, isUndefined } from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { t } from 'ttag';
import Header from '../../../components/Header/Header';
import Loading from '../../../components/Loading/Loading';
import ServiceNavigationBar from '../../../components/Menu/ServiceNavigationBar';
import { useStoreActions, useStoreState } from '../../../models/RootStore';
import { MenuSearch as Search } from '../../../svgComponents';
import { IAuditsFilter } from '../AuditsFilter';
import useStyle from './AuditsPage.style';
import FilterRequire from '../../../components/FilterRequire/FilterRequire';
import PaginatedTable from '@components/PaginatedTable';
import AuditsDataPopUp from '../AuditsDataPopUp';
import useAudits, { AuditParams, IAudit, IAuditsMap } from '@hooks/useAudits';
import { Services as sdkServices } from "coolremote-sdk";
import AuditsFilterGroup from '../AuditsFilterGroup';
import AuditActions from '../AuditActions';
import AuditSource, { getSourceNames } from '../AuditSource';
import { toF } from '@services/converter';


/**
* Generates a filter map object based on the provided array and reference object.
*
* @param {string[]} array - The array of strings used to generate the filter map.
* @param {Record<string, any>} refObject - The reference object used for mapping keys to boolean values.
* @returns The filter map object where each string in the array is a key with a boolean value.
*/
const generateFilterMap = (array: string[] | number[], refObject: Record<string, boolean> = {}): Record<string, boolean> => {
  if (!array) {
    return {}; // Return an empty object if the array is empty
  }
  const obj: Record<string, boolean> = {};
  array.forEach((element: string | number) => {
    // Set each string in the array as a key in the object with a boolean value
    obj[element] = isUndefined(refObject[element]) ? true : refObject[element];
  });
  return obj;
};

/**
* Gets the relevant state properties needed for the component.
* @param {Object} state - The current state object.
* @param {Object} state.sites - The sites object containing site-related data.
* @param {Function} state.sites.getSite - Function to get site data.
* @param {boolean} state.isInitialized - Flag indicating if the app is initialized.
* @param {Object} state.users - The users object containing user-related data.
* @param {string} state.users.timeFormat - The time format preference.
* @param {string} state.users.dateFormat - The date format preference.
* @param {Function} state.users.getTemperatureScaleDisplayPlainText - Function to get temperature scale display preference.
* @param {Object} state.types - The types object containing various types of data.
* @param {Function} state.systems.getSystemName - Function to get system name.
* @param {Function} state.sensors.getSensorName - Function to get sensor name.
* @param {Function} state.groups.getGroupName - Function to get group name.
* @param {Function} state.devices.getDeviceName - Function to get device name.
* @param {Function} state.customers.getCustomerName - Function to get customer name.
* @param {Array} state.units.allUnits - Array containing all unit data.
* @param {Object} state.selections - The selections object containing selected data.
* @param {any} state.selections.selections - The selected cutomer/site/system/unit/dateRange from the top header.
* @param {Object} state.users.userPreferences - The user preferences object.
* @returns The relevant state properties.
*/
const getState = (state: any) => {
  return {
    getSite: state.sites.getSite,
    isInitialized: state.isInitialized,
    timeFormat: state.users.timeFormat,
    dateFormat: state.users.dateFormat,
    temperatureScaleDisplay: state.users.getTemperatureScaleDisplayPlainText,
    types: state.types,
    getSystemName: state.systems.getSystemName,
    getSensorName: state.sensors.getSensorName,
    getGroupName: state.groups.getGroupName,
    getDeviceName: state.devices.getDeviceName,
    getCustomerName: state.customers.getCustomerName,
    allUnits: state.units.allUnits,
    selections: state.selections.selections,
    userPreferences: state.users.userPreferences,
    temperatureScaleMirror: state.temperatureScaleMirror,
    userTemperatureScale: state.users.me.temperatureScale || 1,
  }
}

const AuditsPage: React.FC = (props) => {
  const classes = useStyle();
  const {
    getSite,
    isInitialized,
    timeFormat,
    dateFormat,
    temperatureScaleDisplay,
    types,
    getSystemName,
    getSensorName,
    getGroupName,
    getDeviceName,
    getCustomerName,
    allUnits,
    selections,
    userPreferences,
    temperatureScaleMirror,
    userTemperatureScale
  } = useStoreState(getState);

  const isCelsius = +temperatureScaleMirror.celsius === +userTemperatureScale;
  const {getAuditsPaginated, loading, auditsFilters} = useAudits();

  const { operationStatuses, operationModesExtended, fanModes, swingModes, curveTempModes } = types;
  const { customerId, siteId, systemId, unitId, dateRange } = selections;

  const setSelections = useStoreActions((s) => s.selections.setSelections);
  const updateUserPreferences = useStoreActions((actions) => actions.users.updateUserPreferences);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const getCustomerSchedules = useStoreActions((a) => a.schedules.getCustomerSchedules);
  const getCustomerChangeovers = useStoreActions((action) => action.operationAutomate.getCustomerChangeovers);
  const getCustomerTraps = useStoreActions((action) => action.traps.getCustomerTraps);

  const [allSchedules, setAllSchedules] = useState<{ [key: string]: object }>({});
  const [allChangeovers, setAllChangeovers] = useState<{ [key: string]: object }>({});
  const [allTraps, setAllTraps] = useState<{ [key: string]: object }>({});
  const [roles, setRoles] = useState<{ [key: string]: object }>({});

  const [actorsFilter, setActorsFilter] = useState<IAuditsFilter>({});
  const [actionsFilter, setActionsFilter] = useState<IAuditsFilter>({});
  const [sourcesFilter, setSourcesFilter] = useState<IAuditsFilter>({});
  const [loadingNextPage, setLoadingNextPage] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [auditsMap, setAuditsMap] = useState<IAuditsMap>({
    audits: [],
    totalCount: 0,
    totalPages: 0,
    pageSize: 20,
    page: 1,
    newerRecordsExist: false
  });

  const [selectedAudit, setSelectedAudit] = useState<IAudit | unknown>({});

  useEffect(() => {
    if (!!dateRange?.endDate && !!dateRange?.startDate) {
      return;
    }
    setSelections({
      dateRange: {
        startDate: moment().startOf('day').subtract(2, 'days').toDate(),
        endDate: moment().toDate()
      }
    });
  }, []);

  /**
  * Fetches customer schedules, changeovers, traps, and roles for a given customer ID.
  * @param {string} customerId - The ID of the customer.
  */
  useEffect(() => {
    // Ensure customerId is available
    if (!customerId) {
      return;
    }

    // Fetch customer schedules, changeovers, traps, and roles
    Promise.all([
      getCustomerSchedules(customerId),
      getCustomerChangeovers(customerId),
      getCustomerTraps({ customerId, type: 201 }),
      sdkServices.getRoles()
    ]).then((results) => {
      // Set fetched data to state
      setAllSchedules(results[0]);
      setAllChangeovers(results[1]);
      setAllTraps(results[2]);
      setRoles(results[3]);
    });
  }, [customerId]);

  /**
  * Sets up filters for actors, actions, and sources based on user preferences and audit data.
  * @param {object} userPreferences - The user preferences containing preferred filters.
  * @param {object} userPreferences.auditsFilters - The preferred filters for actors, actions, and sources.
  * @param {object} userPreferences.auditsFilters.actorsFilter - The preferred filter for actors.
  * @param {object} userPreferences.auditsFilters.actionsFilter - The preferred filter for actions.
  * @param {object} userPreferences.auditsFilters.sourcesFilter - The preferred filter for sources.
  */
  useEffect(() => {
    // Destructure preferred filters from user preferences or set defaults
    const { actorsFilter: preferedActorsFilter, actionsFilter: preferedActionsFilter, sourcesFilter: preferedSourcesFilter } = userPreferences?.auditsFilters || {};

    // Generate filter maps based on preferred filters and audit data
    const actorsFilterMap = generateFilterMap(Object.keys(auditsFilters.auditActors), preferedActorsFilter);
    const actionsFilterMap = generateFilterMap(auditsFilters?.auditActions, preferedActionsFilter);
    const sourcesFilterMap = generateFilterMap(auditsFilters?.auditSources, preferedSourcesFilter);

    // Set filter states
    setActorsFilter(actorsFilterMap);
    setActionsFilter(actionsFilterMap);
    setSourcesFilter(sourcesFilterMap);
  }, [auditsFilters]);

  useEffect(() => {
    if (!dateRange || !customerId) {
      return;
    }
    if ([actorsFilter, sourcesFilter, actionsFilter, userPreferences].some(isEmpty)) {
      return;
    }
    const newFilters = { actorsFilter, sourcesFilter, actionsFilter };
    // Check if filters differ from userPreferences and update if necessary
    const hasFilterChanged = Object.entries(newFilters).some(([filter, values]) =>
      Object.entries(values).some(([key, value]) =>
        !!userPreferences?.auditsFilters?.[filter]?.[key] !== !!value
      )
    );
    if (hasFilterChanged) {
      updateUserPreferences({ auditsFilters: newFilters });
    }
  }, [actorsFilter, sourcesFilter, actionsFilter, dateRange, customerId, userPreferences, userTemperatureScale]);

  useEffect(() => {
    try {
      fetchAudits();
    } catch (error) {
      addMessage(error);
    }
  }, [dateRange, customerId, siteId, systemId, unitId, refresh, loading]);

  /**
  * Fetches audits data based on the specified parameters.
  *
  * @param {number} [pageNum] - The page number for pagination (optional).
  * @param {number} [pageSize] - The page size for pagination (optional).
  * @returns A Promise that resolves when audits data is fetched and updated.
  */
  const fetchAudits = async (pageNum?: number, pageSize?: number): Promise<void> => {
    if (!dateRange || !customerId || loading) {
      return;
    }

    // Calculate UTC start and end dates
    const startDate = moment.utc(dateRange?.startDate).subtract(9, 'hours').valueOf();
    const endDate = moment.utc(dateRange?.endDate).endOf('day').add(9, 'hours').valueOf();

    // Extract selected filters
    const selectedActionArr = Object.keys(actionsFilter).filter(key => actionsFilter[key]);
    const selectedSourceArr = Object.keys(sourcesFilter).filter(key => sourcesFilter[key]);
    const selectedActorArr = Object.keys(actorsFilter).filter(key => actorsFilter[key]);

    // if no filters selected, show empty table
    if(some([selectedActionArr, selectedSourceArr, selectedActorArr], isEmpty)){
      setAuditsMap({
        audits: [],
        totalCount: 0,
        totalPages: 0,
        pageSize: 20,
        page: 1,
        newerRecordsExist: false
      });
      return;
    }

    // Construct params object for API request
    const params: AuditParams = {
      pageNum,
      pageSize,
      customerId,
      siteId,
      systemId,
      unitId,
      startDate,
      endDate,
    };

    // Precompute lengths
    const allActionsCount = auditsFilters.auditActions.length;
    const allSourcesCount = auditsFilters.auditSources.length;
    const allActorsCount = Object.keys(auditsFilters.auditActors).length;

    // Conditionally add arrays based on lengths
    if (selectedActionArr.length < allActionsCount) {
      params.actionArr = selectedActionArr;
    }

    if (selectedSourceArr.length < allSourcesCount) {
      params.sourceArr = selectedSourceArr;
    }

    if (selectedActorArr.length < allActorsCount) {
      params.actorArr = selectedActorArr;
    }

    setLoadingNextPage(true);
    try {
      // Fetch audits data from API
      const result = await getAuditsPaginated(params);
      setAuditsMap(result || {});
    } catch (error) {
      // Handle errors
      addMessage(error);
    } finally {
      setLoadingNextPage(false);
    }
  };



  /**
  * Resets the date range selection to the current date if either start date or end date is missing.
  */
  const resetData = () => {
    if (!dateRange?.endDate && !dateRange?.startDate) {
      return;
    }
    setSelections({
      dateRange: {
        startDate: dateRange.startDate,
        endDate: moment().toDate()
      }
    });
  };

  /**
  * Maps numerical values to their corresponding human-readable representations based on the action.
  *
  * @param {number} value - The numerical value to be mapped.
  * @param {string} action - The action associated with the value.
  * @returns The human-readable representation of the value.
  */
  const valueMapping = (value: number, action: string): string | number => {
    const lowerCaseAction = action.toLowerCase();

    if (lowerCaseAction.includes('setpoint')) {
      return  isCelsius ? Math.round(value) + t`°C` : Math.round(toF(value)) + t`°F`;
    }
    if (lowerCaseAction.includes('operationstatus')) {
      return operationStatuses[value] || value;
    }
    if (lowerCaseAction.includes('operationmode')) {
      return operationModesExtended[value] || value;
    }
    if (lowerCaseAction.includes('fanmode')) {
      return fanModes[value] || value;
    }
    if (lowerCaseAction.includes('swingmode')) {
      return swingModes[value] || value;
    }
    if (lowerCaseAction.includes('tempcurve')) {
      return `${curveTempModes[value] || value} (${value})`;
    }

    return value;
  };

  /**
  * Retrieves the username based on the provided user ID.
  * @param {string} id - The ID of the user.
  * @returns The username corresponding to the provided user ID, or a default value '-' if not found.
  */
  const getUsername = (id: string) => {
    if (!id) { return '-'; }
    const user = auditsFilters?.auditActors?.[id] || {};
    const { firstName, lastName, username } = user;
    if (firstName) {
      if (lastName) {
        return firstName + ' ' + lastName;
      } else {
        return firstName;
      }
    } else {
      return username || '-';
    }
  };

  /**
  * Retrieves the name of a unit based on its ID.
  * @param {string} unitId - The ID of the unit.
  * @returns The name of the unit, including control unit name and address if applicable.
  */
  const getUnitName = (unitId: string) => {
    const unit = allUnits[unitId];
    if (!unit || !unit.isVisible) {
      return '';
    }
    if (+unit.type === 3) { // is service unit
      const controlUnitId: any = unit.controlUnit;
      const controlUnit = allUnits[controlUnitId];
      const controlName = controlUnit?.name || t`Unassigned`;
      return `${controlName} (${unit.address})`;
    }
    return unit.name;
  };

  /**
  * Retrieves the name of the entity associated with the audit.
  * @param {Object} audit - The audit object.
  * @returns The name of the entity associated with the audit.
  */
  const getEntityName = (audit: IAudit) => {
    if (audit.unit) {
      return getUnitName(audit.unit);
    }
    if (audit.system) {
      return getSystemName(audit.system);
    }
    if (audit.sensor) {
      return getSensorName(audit.sensor);
    }
    if (audit.device) {
      return getDeviceName(audit.device);
    }
    if (audit.group) {
      return getGroupName(audit.group);
    }
    if (audit.customer) {
      return getCustomerName(audit.customer);
    }
    return audit.user;
  };

  /**
  * Renders the content for a cell in the audit table.
  * @param {Object} audit - The audit object.
  * @param {string} cellId - The ID of the cell.
  * @returns The rendered content for the cell.
  */
  const renderCell = (audit: IAudit, cellId: string): JSX.Element | string => {
    const value = audit[cellId];
    switch (cellId) {
      case 'icon':
        return <AcUnit />;
      case 'value':
        return valueMapping(audit.value, audit.action) as string;
     case 'timestamp': {
        const timezone = getSite(audit.site)?.timezone || '';
        const date = timezone ? moment(value).tz(timezone).format(`ll ${timeFormat}`) : moment(value).format(`ll ${timeFormat}`);
        return date;
      }
      case 'actor':
        return getUsername(value);
      case 'action':
        return <span>{AuditActions[audit.action] || audit.action}</span>;
      case 'unit':
        return getEntityName(audit);
      case 'customer':
        return getCustomerName(audit.customer ? audit.customer : getSite(audit.site)?.customer);
      case 'site':
        return getSite(audit.site)?.name || '-';
      case 'source':
        return <Tooltip title={getSourceNames(audit.source as unknown as string, audit.sourceType)}>
          <span>
            <AuditSource source={audit.source || ''} />
          </span>
        </Tooltip>;
      default:
        return JSON.stringify(value);
    }
  };

  /**
  * Object mapping data properties to their replacements.
  * Used for replacing specific data properties with more readable labels. G
  * @type {Object<string, any>}
  */
  const dataPropertiesReplacement: { [key: string]: any } = {
    isRegistered: 'Connected',
    isExpired: 'Expired'
  };

  /**
  * Converts raw data to a human-readable format based on the action type.
  * @param {any} data - The raw data to be converted.
  * @param {any} action - The action type associated with the data.
  * @returns The human-readable representation of the data.
  */
  const getReadableData = (data: any, action: string): string => {
    if (isEmpty(data)) {
      return '';
    }
    const lowerCaseAction = action?.toLowerCase();
    const { value } = data;

    if (lowerCaseAction.includes('setpoint')) {
      return `setpoint value: ${value} ${temperatureScaleDisplay()}`;
    }
    if (lowerCaseAction.includes('operationstatus')) {
      return `operation status: ${types?.operationStatuses[value]}`;
    }
    if (lowerCaseAction.includes('operationmode')) {
      return `operation mode: ${types?.operationModesExtended[value]}`;
    }
    if (lowerCaseAction.includes('fanmode')) {
      return `fan mode: ${types?.fanModes[value]}`;
    }
    if (lowerCaseAction.includes('swingmode')) {
      return `swing mode: ${types?.swingModes[value]}`;
    }
    if (lowerCaseAction.includes('tempcurve')) {
      return `temp curve: ${types?.curveTempModes[value]}`;
    }

    let dataString = '';
    Object.keys(data).forEach((key: any) => {
      if (typeof data[key] !== 'string') {
        return;
      }

      const replacement = dataPropertiesReplacement[key];
      dataString += `${dataString ? '| ' : ''}${replacement ? replacement : key}: ${data[key]}`;
    });
    return dataString;
  };

  /**
  * Handles the sharing of audit data by generating and downloading a CSV file.
  */
  const handleAuditShare = (): void => {
    // Define CSV headers
    const headers = 'SOURCE,UNIT,SITE,CUSTOMER,ACTION,USER,DATE/TIME,DATA';
    let rows = '';

    // Iterate through audits to format data rows
    auditsMap?.audits?.forEach((audit: IAudit) => {
      // Format data fields
      const formattedData = getReadableData(audit, audit.action);
      const siteObject = getSite(audit.site);
      const timezone = siteObject?.timezone || '';
      const dateFormatted = timezone ? moment(audit.timestamp).tz(timezone).format(`${dateFormat} ${timeFormat}`) : moment(audit.timestamp).format(`${dateFormat} ${timeFormat}`);
      const source = getSourceNames(audit.source as unknown as string, audit.sourceType);
      const row = [
        source,
        getUnitName(audit.unit),
        siteObject?.name || '-',
        getCustomerName(audit.customer),
        AuditActions[audit.action] || audit.action,
        getUsername(audit.actor),
        dateFormatted,
        formattedData
      ];

      // Concatenate rows with CSV format
      rows += row.join(',') + '\r\n';
    });

    // Create a download link for the CSV file
    const link = window.document.createElement('a');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(headers + '\r\n' + rows));
    link.setAttribute('download', 'Audits.csv');
    link.click();
  };

  // Usage of debounce with a 300ms delay (adjust as needed)
  const debouncedSetRefresh = useCallback(
    debounce(() => {
      setRefresh((prev) => !prev);
    }, 1500),
    []
  );

  /**
  * Handle changes to filters by updating the corresponding filter state and triggering a refresh.
  * @param {IAuditsFilter} filters - The new filter object containing filter options.
  * @param {string} type - The type of filter being changed (source, actor, or action).
  */
  const handleFilterChange = async (filters: IAuditsFilter, type: string) => {
    switch (type) {
      case 'source':
        setSourcesFilter(filters);
        break;
      case 'actor':
        setActorsFilter(filters);
        break;
      case 'action':
        setActionsFilter(filters);
        break;
      default:
        break;
    }
    debouncedSetRefresh();
  };

  /**
  * Defines the columns for the audit table.
  */
  const tableColumns: { id: string; title: string }[] = [
    { id: 'source', title: t`Source` },
    { id: 'unit', title: t`Unit` },
    { id: 'site', title: t`Site` },
    { id: 'action', title: t`Action` },
    { id: 'actor', title: t`User` },
    { id: 'timestamp', title: t`DATE/TIME` },
    { id: 'value', title: 'Value' }
  ];


  /**
   * Event handler for page change.
   * @param {React.MouseEvent<HTMLButtonElement> | null} event - The event object.
   * @param {number} newPage - The new page number.
   */
  const handleChangePage = (newPage: number) => {
    fetchAudits(newPage, auditsMap.pageSize);
  };

  /**
   * Event handler for rows per page change.
   * @param {React.ChangeEvent<{ value: unknown }>} event - The event object.
   */
  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as number;
    fetchAudits(auditsMap.page, value);
  };

  /**
  * Handle refreshing the data by resetting the page to the first page and resetting the data.
  */
  const handleRefreshData = () => {
    resetData();
  };

  /**
  * Component for rendering the search input.
  */
  const searchComponent = (
    <TextField
      placeholder={t`Search...`}
      value={searchTerm}
      onChange={(event: any) => setSearchTerm(event.target.value)}
      InputProps={{
        disableUnderline: true, classes: { root: classes.inputRoot },
        endAdornment:
          !searchTerm ? (<Search />) : (
            <IconButton
              onClick={() => setSearchTerm('')}
              className={classes.closeIconStyle}
            >
              <Close />
            </IconButton>
          )
      }}
    />
  );

  if (!isInitialized) { return <Loading />; }

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          path={['Audit']}
          showDateRangePicker
          searchComponent={searchComponent}
          screenTitle='audits'
          applySiteTypeFiltering
        />
        {!customerId ?
          <FilterRequire type={t`customer`} expand={true} /> :
          <>
            {!loading && <div className={classes.headerContainer}>
              <Button
                disableRipple
                variant='contained'
                className={classes.shareButton}
                startIcon={
                  <Icon style={{ transform: 'rotateY(180deg)', marginTop: '-3px' }}>reply</Icon>
                }
                onMouseUp={handleAuditShare}
              >
                {t`Export Log to CSV`}
              </Button>
            </div>}
            {loading &&
              <div className={classes.loadingContainer}>
                <CircularProgress />
                <Typography variant='h5'>{t`Loading Audit Reports...`}</Typography>
              </div>}

            <Grid container justifyContent='flex-start' alignItems='stretch' className={classes.wrapper}>
              <AuditsFilterGroup
                actorsFilter={actorsFilter}
                actionsFilter={actionsFilter}
                sourcesFilter={sourcesFilter}
                handleFilterChange={handleFilterChange}
                auditActors={auditsFilters?.auditActors}
              />
              <Grid item xs={10} className={classes.tableWrapper}>
                <PaginatedTable
                  totalCount={auditsMap?.totalCount}
                  data={auditsMap?.audits}
                  columns={tableColumns}
                  newerRecordsExist={auditsMap?.newerRecordsExist}
                  loading={loadingNextPage}
                  TooltipMessage={t`Double click to see full audit information`}
                  rowsPerPage={auditsMap?.pageSize}
                  page={auditsMap?.page}
                  renderCell={(cell, cellId) => renderCell(cell as IAudit, cellId)}
                  handleDoubleClick={setSelectedAudit}
                  refreshData={handleRefreshData}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </>
        }
      </div>
      {!isEmpty(selectedAudit) && <div className={classes.popUpContainer}>
        <AuditsDataPopUp
          setIsSeen={setSelectedAudit}
          audit={selectedAudit}
          users={auditsFilters?.auditActors}
          roles={roles}
          schedules={allSchedules}
          changeovers={allChangeovers}
          traps={allTraps}
        />
      </div>}

    </div >
  );
};

export default AuditsPage;
