import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import AddRule from "../../components/AutomationRule";
import Delete from "../../components/Delete/Delete";
import DisableWarning from "../../components/DisableWarning/DisableWarning";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import Button from "../../cool_widgets/Button";
import { CoolSwitch } from "../../cool_widgets/CoolSwitch";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { MenuSearch as Search } from "../../svgComponents";
import useStyles from "./AutomationLogic.style";

const TrapsList: React.FC = (props: any) => {
  const classes = useStyles();
  const types = useStoreState((s) => s.types);
  const powerMeters = useStoreState((s) => s.powerMeters.allPowerMeters);
  const isInitialized = useStoreState((s) => s.isInitialized);
  const { customerId, siteId } = useStoreState((s) => s.selections.selections);
  const units = useStoreState((state) => state.units.allUnits);
  const sensors = useStoreState((state) => state.sensors.allSensors);
  const allSites = useStoreState((state) => state.sites.allSites);
  const allSystems = useStoreState((state) => state.systems.allSystems);
  const getTrapsAPI = useStoreActions((action) => action.traps.getTraps);
  const addTrapAPI = useStoreActions((action) => action.traps.addTrap);
  const updateTrapAPI = useStoreActions((action) => action.traps.updateTrap);
  const deleteTrapAPI = useStoreActions((action) => action.traps.deleteTrap);

  const [openAddRule, setOpenAddRule] = useState<boolean>(false);
  const [rules, setRules] = useState<any>({});
  const [selectedRule, setSelectedRule] = useState<any>(null);
  const [filteredRules, setFilteredRules] = useState<any>([]);
  const [customerDataMap, setCustomerDataMap] = useState<any>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [objectToDisable, setObjToDisable] = useState<any>(null);
  const [canAdd, setCanAdd] = useState<boolean>(true);

  const {  getSiteSensorGroups } = useStoreActions((action) => action.sensors);
  const [siteSensorGroups, setSiteSensorGroups] = useState<any>({});

  useEffect(() => {
    if (!siteId) {
      return;
    }
    setCanAdd(allSites[siteId]?.permissions?.canCreateTraps);
    getTrapsAPI({ type: 201, siteId }).then((traps: any) => {
      Object.values(traps).forEach((trap: any) => {
        const { id, type, site } = trap;
        if (type !== 201 || (!allSites[site]?.hvacAdvancedOperations && !allSites[site]?.predictiveMaintenance)) {
          delete traps[id];
        }
      });
      setRules(traps);
    });
  }, [siteId]);

  useEffect(() => { 
    if (!siteId) {
      return;
    }
    getSiteSensorGroups(siteId).then((res:any)=>{
      setSiteSensorGroups(res)
    })
    .catch((error: any)=>{
      console.error("setSiteSensorGroups error: ",error.message)
    })
   }, [siteId]);

  useEffect(() => {
    if (!siteId) {
      return;
    }
    customerDataMap && setCustomerDataMap(null);
    let sitesOptions: any = [];
    const notSortedSites: any = [];
    const sites: any = {};
    const systems: any = {};
    const { sensorTypes } = types;

    const site = allSites[siteId];
    const { name, id, hvacAdvancedOperations, predictiveMaintenance } = site;

    if (!hvacAdvancedOperations && !predictiveMaintenance) {
      return;
    }
    notSortedSites.push({ value: id, name });
    sites[id] = { sensors: [], indoors: [], outdoors: [], systems: [], outputSensors: [], services: [], WRCLocks: [], id , sensorGroups: []};

    sitesOptions = _.orderBy(notSortedSites, ["name"], ["asc"]);

    Object.values(units).forEach((unit: any) => {
      const { type, subType, site, name, id, brand, controlUnit = "", serviceUnits = [], otherUnits = [], isVisible } = unit;
      if (!sites[site]) {
        return;
      }

      if (type === 1 && subType !== 1 && isVisible) {
        sites[site].indoors.push({ value: id, name, type, subType });
        sites[site].WRCLocks.push({ value: id, name, type: 8, subType });
      }
      if (type === 3 && isVisible) {
        sites[site].services.push({ value: id, name, type, subType, brand });
      }

      if (type === 1 && isVisible && serviceUnits?.length === 0) {
        sites[site].services.push({ value: id, name: `${name} (C)`, type, subType, brand });
      }

      if (type === 2 && isVisible) {
        sites[site].outdoors.push({ value: id, name, type, subType, brand });
      }
    });

    const powerMetersData = Object.values(powerMeters).reduce((data: any, ppd: any) => {
      if (ppd.site === siteId) {
        data.push({ ...ppd, type: "powerMeters", value: ppd.id });
      }
      return data;
    }, [])

    Object.values(sensors).forEach((sensor: any) => {
      const { type, site, name, id, sensorGroup} = sensor;
      if (!sites[site]) {
        return;
      }
      if (!sensorTypes[type]?.enableView) {
        return;
      }
      if(sensorGroup){
        let targetGroup: any = Object.values(siteSensorGroups).find((obj: any) => _.includes(obj.sensors,id));
        sites[site].sensors.push({ value: id, name: targetGroup ? `${name} (${targetGroup?.name})` : name, type });
      } else {
        sites[site].sensors.push({ value: id, name, type });
      }
      if (type === 129 || type === 130) {
        if (sensorGroup) {
          let targetGroup: any = Object.values(siteSensorGroups).find((obj: any) => _.includes(obj.sensors, id));
          sites[site].outputSensors.push({ value: id, name: targetGroup ? `${name} (${targetGroup?.name})` : name, type });
        } else {
          sites[site].outputSensors.push({ value: id, name, type });
        }
      }
    });

    // Object.values(siteSensorGroups).forEach((sensorGroup: any) => {
    //   const { site, name, id} = sensorGroup;
    //   if (!sites[site]) {
    //     return;
    //   }
    //   sites[site].sensorGroups.push({ value: id, name, isSensorGroup: true });
    // });

    Object.values(allSystems).forEach((system: any) => {
      const { site, name, id, brand } = system;
      if (!sites[site]) {
        return;
      }
      sites[site].systems.push({ value: id, name, brand });
    });

    sites[id]["powerMeters"] = powerMetersData
    setCustomerDataMap({ sitesOptions, sites, systems });
  }, [customerId, siteId, siteSensorGroups]);

  useEffect(() => {
    setFilteredRules(filterRules(rules));
  }, [searchValue, rules, customerId]);

  const addNewTrap = (data: any) => {
    return addTrapAPI({ data })
      .then((resp: any) => {
        setRules({ ...rules, [resp.id]: { ...resp } });
      })
      .catch((error: any) => {
        //addd message
      });
  };

  const deleteRule = (id: string) => {
    deleteTrapAPI({ id })
      .then(() => {
        delete rules[id];
        setRules({ ...rules });
      });
  };

  const updateExistingTrap = (data: any, trapId: string) => {
    return updateTrapAPI({ data, trapId })
      .then((resp: any) => {
        setRules({ ...rules, [trapId]: resp });
        if (!!objectToDisable) {
          setObjToDisable(null);
        }
      });
  };

  const closeDialog = () => {
    setOpenAddRule(false);
    setSelectedRule(null);
  };

  const filterRules = (traps: any[]) => {
    function applyFilters(traps: any[]) {
      return _(traps)
        .filter((trap) => {
          return trap.customer === customerId;
        }).filter((trap) => {
          return searchValue?.length
            ? (trap["name"].toLowerCase().indexOf(searchValue.toLowerCase()) > -1 || trap["description"].toLowerCase().indexOf(searchValue.toLowerCase()) > -1
              || (trap["isEnabled"] ? t`Active` : t`Disabled`).toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
            : true;
        })
        .value();
    }
    const filteredRows = applyFilters(traps);
    return filteredRows;
  };

  if (!isInitialized) { return <Loading />; }

  const searchComponent = (
    <TextField
      placeholder={t`Search...`}
      value={searchValue}
      onChange={(event: any) => setSearchValue(event.target.value)}
      InputProps={{
        disableUnderline: true, classes: { root: classes.inputRoot },
        endAdornment:
          !searchValue ? (<Search />) : (
            <IconButton
              onClick={() => setSearchValue("")}
              className={classes.closeIconStyle}
            >
              <Close />
            </IconButton>
          )
      }}
    />
  );

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header hideSystemSelection hideUnitSelection
          searchComponent={searchComponent}
          customGeneralNames={{ site: t`Select Site` }}
          screenTitle="automationLogic"
        />
        {!siteId ?
          <FilterRequire type={t`site`} />
          :
          <>
            <div className={classes.titleBar}>
              <div className={classes.barRIghtSide}>
                <Button
                  disabled={!canAdd}
                  onClick={() => setOpenAddRule(true)}
                >
                  {t`Add New Rule`}
                </Button>
              </div>
            </div>
            <Paper elevation={0} className={classes.paperTableContainer}>
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        classes={{ root: clsx(classes.tableHeadCell, classes.nameCell) }}
                        align="left"
                      >{t`Rule Name`}</TableCell>
                      <TableCell
                        classes={{ root: clsx(classes.tableHeadCell, classes.nameCell) }}
                        align="left"
                      >{t`Site`}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`Rule Description`}</TableCell>
                      <TableCell
                        classes={{ root: clsx(classes.tableHeadCell, classes.fixedWidth) }}
                        align="center"
                      >{t`Status`}</TableCell>
                      <TableCell
                        classes={{ root: clsx(classes.tableHeadCell, classes.fixedWidth) }}
                        align="center"
                      >{t`DUPLICATE`}</TableCell>
                      <TableCell
                        classes={{ root: clsx(classes.tableHeadCell, classes.fixedWidth) }}
                        align="center"
                      >{t`REMOVE`}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRules.map((rule: any) => {
                      const { id, name, description, isEnabled, site, permissions = {} } = rule;
                      const { canDelete, canUpdate } = permissions;
                      const { canUpdateAutomationRule = true, canDeleteAutomationRule = true } = allSites[site] || {};
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={id}
                          onDoubleClick={() => canUpdate && setSelectedRule(rule)}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            classes={{ root: clsx(classes.overWritePadding, classes.nameCell) }}
                            align="left"
                          >
                            {name}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            classes={{ root: clsx(classes.overWritePadding, classes.nameCell) }}
                            align="left"
                          >
                            {allSites[site]?.name}
                          </TableCell>
                          <TableCell
                            classes={{ root: clsx(classes.overWritePadding) }}
                            align="left"
                          >
                            {description}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            classes={{ root: clsx(classes.overWritePadding, classes.brandCell) }}
                            align="center"
                          >
                            <CoolSwitch className={!canUpdate && classes.disabledStyle} disabled={!canUpdateAutomationRule || !canUpdate} checked={isEnabled} switchChange={(e: any) => {
                              const checked = e?.target?.checked;
                              if (!checked) {
                                setObjToDisable(rule);
                                return;
                              }
                              updateExistingTrap({ isEnabled: e?.target?.checked }, id);
                            }} />
                          </TableCell>
                          <TableCell classes={{ root: clsx(classes.overWritePadding, classes.fixedWidth) }} align="center">
                            <Button
                              disabled={!canAdd}
                              variant="text"
                              size="small"
                              className={classes.duplicateButton}
                              onClick={() => {
                                const { description, isEnabled, name, site: siteId, trapSets, type, customer } = rule;
                                addNewTrap({ description, isEnabled, name: "Copy - " + name, site: siteId, trapSets, type });
                              }}
                            >
                              {t`Duplicate`}
                            </Button>
                          </TableCell>
                          <TableCell classes={{ root: clsx(classes.overWritePadding, classes.fixedWidth) }} align="center">
                            <Delete
                              disabled={!canDeleteAutomationRule || !canDelete}
                              type={t`Rule`}
                              object={rule}
                              detach={() => deleteRule(id)}
                              buttonClass={classes.deleteIcon}
                            ></Delete>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        }
        {(openAddRule || selectedRule) &&
          <AddRule
            close={closeDialog}
            create={addNewTrap}
            selectedItem={selectedRule}
            update={updateExistingTrap}
            customerData={customerDataMap}
            siteId={siteId}
          />
        }
        {!!objectToDisable &&
          <DisableWarning
            type={t`Automation Rule`}
            handleClose={() => setObjToDisable(null)}
            object={objectToDisable}
            approve={(id: any) => updateExistingTrap({ isEnabled: false }, id)}
          />
        }
      </div>
    </div>
  );
};

export default TrapsList;
