import {
  Checkbox,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Loading from "../../components/Loading/Loading";
import Button from "../../cool_widgets/Button";
import { AngleBracket as SvgAngleBracket, User1 as SvgUser } from "../../icons/";
import SearchIcon from "../../icons/SearchIcon";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import AddEditContact from "./AddEditContact";
import useStyles from "./AlertSettingsContacts.style";

interface IColumn {
  id: string;
  label: string;
}

const AlertGroupsSettingContacts: any = (props: any) => {
  const {
    selectedAlertGroup,
    setSelectedAlertGroup,
    onClose,
    openCreateNewRecipients,
    setOpenCreateNewRecipients,
    userContacts,
    setUserContacts,
    handleUpdateContactsAlertGroup
  } = props;

  const selectedAlertGroupContactsObj: any = {};

  selectedAlertGroup?.contacts?.forEach((contact: any) => {
    selectedAlertGroupContactsObj[contact.contact] = true;
  });

  const classes = useStyles();
  const isInitialized = useStoreState((s) => s.isInitialized);
  const updateContactAlertGroup = useStoreActions((action) => action.alertGroups.updateContactAlertGroup);
  const updateContactsAlertGroup = useStoreActions((action) => action.alertGroups.updateContactsAlertGroup);
  const deleteContactFromSite = useStoreActions((action) => action.contacts.deleteContact);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const allSites = useStoreState((state) => state.sites.allSites);
  const { siteId, customerId } = useStoreState((s) => s.selections.selections);

  const { permissions = {} } = siteId ? allSites[siteId] : {};
  const { canUpdateAlertGroups } = permissions;
  const [allContactsToShow, setAllContactsToShow] = useState<any>({});
  const [tempUserContacts, setTempUserContacts] = useState<any>(userContacts);
  const [contactsToAdd, setContactsToAdd] = useState<any>(selectedAlertGroup?.contacts || []);
  const [contactsToSave, setContactsToSave] = useState<any>(selectedAlertGroup?.contacts || []);
  const [allowSms, setAllowSms] = useState<boolean>(true);
  const [openAddRecipients, setOpenAddRecipients] = useState<boolean>(false);
  const [editContact, setEditContact] = useState<any>(null);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    if (!selectedAlertGroup) {
      return;
    }
    // (re-render contactsToAdd). this line to save the contactsToAdd array when adding new contacts
    setContactsToAdd([...contactsToAdd]);

    setAllContactsToShow(
      selectedAlertGroup?.contacts?.reduce((acc: any, contact: any) => {
        acc[contact.contact] = { ...acc[contact.contact], ...contact };
        return acc;
      }, { ...tempUserContacts })
    );
    checkAlertGroupSites(selectedAlertGroup);
  }, [props.selectedAlertGroup, tempUserContacts]);

  useEffect(() => {
    if (!selectedAlertGroup) {
      return;
    }
    setAllContactsToShow(
      (openAddRecipients ? contactsToAdd : contactsToSave)?.reduce((acc: any, contact: any) => {
        acc[contact.contact] = { ...acc[contact.contact], ...contact };
        return acc;
      }, { ...tempUserContacts })
    );
  }, [contactsToAdd, contactsToSave]);

  useEffect(() => {
    if (!selectedAlertGroup || !openAddRecipients) {
      return;
    }
    setContactsToAdd([...contactsToSave]);
  }, [openAddRecipients]);

  if (!isInitialized) { return <Loading />; }

  const columns: IColumn[] = [
    { id: "recipients", label: t`RECIPIENTS` },
    { id: "sms", label: t`SMS` },
    { id: "email", label: t`E-MAIL` },
    { id: "delete", label: "" }
  ];

  const checkAlertGroupSites = (selectedAlertGroup: any) => {
    const selectedAlertGroupContacts = selectedAlertGroup?.contacts || {};

    const siteObj = allSites[selectedAlertGroup.site];
    if (siteObj?.hvacAdvancedOperations || siteObj?.predictiveMaintenance || siteObj?.basicRemoteDiagnostics) {
      setAllowSms(true);
      return;
    }

    setAllowSms(false);
    Object.values(selectedAlertGroupContacts).forEach(async (contact: any) => {
      if (contact.sendSms) {
        let data: any = {
          sendEmail: contact.sendEmail,
          sendSms: false
        };
        !selectedAlertGroup.new && await updateContactAlertGroup({
          id: selectedAlertGroup.id,
          contactId: contact.contact,
          updatedData: data
        });
      }
    });
  };

  const handleAddContactToService = (contact: any, service: "sendSms" | "sendEmail") => {
    let data: any = {
      contact: contact.id,
      sendEmail: !!contact.sendEmail,
      sendSms: !!contact.sendSms
    };

    if (service === "sendSms" && _.isEmpty(contact?.phone) && !data.sendSms) {
      addMessage({ message: t`To get SMS notifications, you will need to add your phone number to your User Profile.` });
      return;
    }

    data[service] = !contact[service];
    const newContacts = (openAddRecipients ? contactsToAdd : contactsToSave).filter((c: any) => c.contact !== contact.id);
    (openAddRecipients ? setContactsToAdd : setContactsToSave)([...newContacts, { ...contact, ...data }]);

  };

  const deleteContact = (obj: any) => {
    const restOfContacts = (openAddRecipients ? contactsToAdd : contactsToSave).filter((contact: any) => contact.contact !== obj.id);
    (openAddRecipients ? setContactsToAdd : setContactsToSave)(restOfContacts);
  };

  const onSave = async () => {
    onClose();
    setUserContacts(tempUserContacts);
    Promise.allSettled(Object.keys(userContacts).map((id) => !tempUserContacts[id] && deleteContactFromSite({ id })));
    if (selectedAlertGroup.new) {
      handleUpdateContactsAlertGroup({ ...selectedAlertGroup, contacts: contactsToSave });
    }
    else {
      updateContactsAlertGroup({ ...selectedAlertGroup, contacts: contactsToSave }).then(handleUpdateContactsAlertGroup);
    }
  };
  const onAdd = () => {
    handleUpdateContactsAlertGroup(selectedAlertGroup);
    setOpenAddRecipients(false);
    setContactsToSave([...contactsToAdd]);
  };

  return (
    props.open ? (<>
      <TableContainer className={classes.tableContainer1}>
        <div className={classes.tableContainer1FirstSection}>
          {openAddRecipients && <Typography onClick={() => { setOpenAddRecipients(false); setContactsToAdd([]); }} className={classes.typographyButton} variant="h5">
            <SvgAngleBracket />
          </Typography>}
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Search"
            className={classes.contactsSearch}
            onChange={({ target }) => { setSearchText(target?.value || ""); }}
            value={searchText}
            InputProps={{
              classes: { input: classes.inputStyle },
              endAdornment: <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }}
          />
        </div>
        <div className={classes.innerTableContainer}>
          <Table
            size="small"
            stickyHeader
            aria-label="customized table"
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell classes={{ root: classes.tableHeadCell }} key={column.id} align={"left"}>
                    <Typography variant="body2">{column.label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {_.orderBy(
                ((Object.values(allContactsToShow))
                  .filter((c: any) => (c.sendEmail || c.sendSms || openAddRecipients || c.contact) && (`${c.firstName} ${c.lastName}`.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))))
                , ["new", "firstName", "lastName"], ["asc"])
                .map((alertGroupContact: any) => {
                  const contact = tempUserContacts[alertGroupContact.contact || alertGroupContact.id];
                  if (!contact) {
                    return;
                  }
                  const { id, firstName, lastName } = contact;
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={id} style={{ maxHeight: 33 }} className={classes.tableBodyRow}>
                      <TableCell onDoubleClick={() => setEditContact(contact)} align={"left"} style={{ minWidth: "120px", padding: 0 }}>
                        <div className={classes.userNameContainer}>
                          <SvgUser />
                          <Typography variant="body2" className={classes.userName}>
                            {`${firstName} ${lastName}`}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell align={"center"} style={{ padding: 0 }}>
                        <Checkbox
                          checked={!!alertGroupContact.sendSms}
                          onChange={() => handleAddContactToService({ ...alertGroupContact, ...contact }, "sendSms")}
                          color="default"
                          classes={{ root: classes.grayCheckbox }}
                          disabled={!allowSms || !canUpdateAlertGroups || (!selectedAlertGroup.permissions?.canUpdate && !selectedAlertGroup.new)}
                        />
                      </TableCell>
                      <TableCell align={"center"} >
                        <Checkbox
                          disabled={!canUpdateAlertGroups || (!selectedAlertGroup.permissions?.canUpdate && !selectedAlertGroup.new)}
                          checked={!!alertGroupContact.sendEmail}
                          onChange={() => handleAddContactToService({ ...alertGroupContact, ...contact }, "sendEmail")}
                          color="default"
                          classes={{ root: classes.grayCheckbox }}
                        />
                      </TableCell>
                      <TableCell align={"right"} style={{ padding: 0 }} >
                        <IconButton onClick={() => {
                          deleteContact(contact);
                          if (openAddRecipients) {
                            const { [contact.id]: deletedContact, ...tempNewContacts } = tempUserContacts;
                            setTempUserContacts(tempNewContacts);
                          }
                        }}>

                          <Close style={{ color: "#ccc" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>

        <div className={classes.buttonsContainer}>
          <Link
            href="#"
            className={classes.linkButton}
            onClick={() => !openAddRecipients ? setOpenAddRecipients(true) : setOpenCreateNewRecipients(true)}>
            {!openAddRecipients ? t`Add Recipients` : t`Create New`}
          </Link>
          {!openAddRecipients ? <div className={classes.flex}>
            <Button white marginRight={5} variant={"text"} className={classes.buttonStyle} onClick={() => { onClose(); setUserContacts({ ...userContacts, ...tempUserContacts }); }}>
              Cancel
            </Button>
            <Button className={classes.buttonStyle} onClick={onSave}>
              Save
            </Button>
          </div>
            : <div className={classes.flex}>
              <Button white marginRight={5} variant={"text"} className={classes.buttonStyle} onClick={() => { setOpenAddRecipients(false); setContactsToAdd([]); }}>
                Cancel
              </Button>
              <Button className={classes.buttonStyle} onClick={onAdd}>
                Add
              </Button>
            </div>}
        </div>

      </TableContainer>
      {(editContact || openCreateNewRecipients) && (
        <AddEditContact
          // canEdit={editContact?.permissions.canUpdate}
          onClose={() => { setOpenCreateNewRecipients(false); setEditContact(null); }}
          editContact={editContact}
          localContacts={tempUserContacts}
          setLocalContacts={setTempUserContacts}
          siteId={siteId}
          customerId={customerId}
        />
      )}
    </>
    ) : null
  );

};

export default AlertGroupsSettingContacts;
