import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@material-ui/core";


import { t } from "ttag";
import _ from 'lodash';

import FilterRequire from "../../../components/FilterRequire/FilterRequire";
import Header from "../../../components/Header/Header";
import Loading from "../../../components/Loading/Loading";
import ServiceNavigationBar from "../../../components/Menu/ServiceNavigationBar";
import CoolButton from "../../../cool_widgets/Button";

import { useStoreActions, useStoreState } from "../../../models/RootStore";
import AddEditDialog from "./components/AddEditDialog";
import Delete from '../../../components/Delete/Delete';


import useStyle from "./ReportsScheduler.style";

//todo: rename component to ReportScheduler.
//todo: convert to js and refactor properly!
const TenantList: React.FC<{ scheduledReportType: string, enableImmediateReport?: boolean }> = (props: any) => {
  const { scheduledReportType, enableImmediateReport } = props

  const classes = useStyle();

  const scheduledReports = useStoreState((s: any) => s.scheduledReports.allReports);

  const getReport = useStoreActions((action: any) => action.scheduledReports.getReport);

  const allSites = useStoreState((s: any) => s.sites.allSites ?? {});

  const selections = useStoreState((s: any) => s.selections.selections);
  const types = useStoreState((s: any) => s.types);

  const [chosenSite, setChosenSite] = useState<string>("");
  const [selectedReports, setSelectedReports] = useState<any>({});
  const [selectedReport, setSelectedReport] = useState<any>(null);

  const isInitialized = useStoreState((s: any) => s.isInitialized);
  const { createReport, updateReport, removeReport } = useStoreActions((actions: any) => ({
    createReport: actions.scheduledReports.createReport,
    updateReport: actions.scheduledReports.updateReport,
    removeReport: actions.scheduledReports.removeReport
  }))

  const { siteId: selectedSiteId = '' } = selections;
  const { canCreatePowerReportSchedules, canDeletePowerReportSchedules } = allSites[selectedSiteId]?.permissions || {};


  const fetchReports = async () => {
    if (!chosenSite) {
      return;
    }
    const reports = await getReport({ id: chosenSite, type: types.scheduledReportTypes[scheduledReportType] });
    setSelectedReports(reports);
  };



  useEffect(() => {
    if (selectedSiteId) {
      setChosenSite(selectedSiteId);

    }
  }, [selectedSiteId]);

  // get reports on site selection
  useEffect(() => {
    fetchReports();
  }, [chosenSite, scheduledReportType]);

  //refresh the list on add report
  useEffect(() => {
    setSelectedReports(scheduledReports);
  }, [scheduledReports]);



  if (!isInitialized) { return <Loading />; }

  const { isReportListEmpty, computedReports } = useMemo(() => {
    const isReportListEmpty = _.isEmpty(selectedReports)
    const computedReports = _.orderBy(selectedReports, [(item: any) => item?.name?.toUpperCase()], ["asc"])
    return {
      isReportListEmpty,
      computedReports
    }
  }, [selectedReports])

  const onFilterSelect = async (id: string | null, queryType: any) => {

    if (!id || queryType === "customer") {
      setChosenSite("");
      return;
    }

    if (queryType === "site") {
      setChosenSite(id);
    }

  };

  const onSelectReport = (report: any) => {

    setSelectedReport(report)

  };

  const handleAddNewClick = () => {
    setSelectedReport({ isNew: true });
  }


  const closeNewEditReportDialog = () => {
    setSelectedReport(null)
  }

  const updateSelectedReports = (savedReport: any, action: string) => {

    if (action === 'add') {
      createReport(savedReport).then((res: any) => {
        if (!savedReport.isImmediate) {
          setSelectedReports({ ...selectedReports, [res.id]: { ...savedReport, ...res } });
        }
      });
    } else {
      const { reportId, ...data } = savedReport
      updateReport({ reportId, data }).then(() => {
        setSelectedReports({ ...selectedReports, [selectedReport.id]: { ...selectedReport, ...savedReport } });
      });
    }

  }

  const handleDeleteClick = (report: any) => {
    removeReport({ reportId: report.id })
  };

  const getTimeUnitText = (timeUnit: string, subTimeUnit: string, specificDay: string) => {
    if (timeUnit === 'now') {
      return t`Immediate`
    }

    if (subTimeUnit === 'End') {
      return t`End of every month`
    }

    if (specificDay) {
      return `${specificDay} of every month`
    }

    return `${subTimeUnit ?? ''} every ${timeUnit}`
  }

  return (
    <>
      <div className={classes.view}>
        <ServiceNavigationBar {...props} />
        <div className={classes.contentArea}>
          <Header
            path={[t`Power Distribution`]}
            onSiteSelect={(id: any) => onFilterSelect(id, "site")}
            onCustomerSelect={(id: any) => onFilterSelect(id, "customer")}
            customGeneralNames={{ site: t`Select Site` }}
            hideSystemSelection={true}
            hideUnitSelection={true}
            screenTitle="scheduler"
          />
          {!chosenSite ? <FilterRequire type={t`site`} /> :
            <>
              <div className={classes.buttonContainer}>
                <CoolButton
                  disabled={!canCreatePowerReportSchedules}
                  onClick={handleAddNewClick} >
                  {t`Add New`}
                </CoolButton>
              </div>
              <Paper elevation={0} className={classes.paperTableContainer}>
                <TableContainer>
                  <Table stickyHeader className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`REPORT NAME`}</TableCell>

                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`RECIPIENTS`}</TableCell>

                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`FREQUENCY`}</TableCell>

                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`DELETE`}</TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!isReportListEmpty ?
                        computedReports.map((report: any) => {
                          return (
                            <TableRow
                              classes={{ root: classes.overWritePadding }}
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={`reports-${report?.id}`}
                              onDoubleClick={() => {
                                onSelectReport(report);
                              }}>
                              <TableCell align={"left"} size="small">
                                <Typography variant="body2" className={classes.userNameCell}>
                                  {report.name}
                                </Typography>
                              </TableCell>

                              <Tooltip
                                title={report.users.length ? _.map(report.users, (user) => user.userName).join(", ") : "No Recipients"}
                                key={report.id}
                                enterDelay={500}>
                                <TableCell align={"left"} size="small">
                                  <Typography variant="body2" className={classes.userNameCell}>
                                    {report.users.length}
                                  </Typography>
                                </TableCell>
                              </Tooltip>

                              <TableCell align={"left"} size="small">
                                <Typography variant="body2" className={classes.userNameCell}>
                                  {getTimeUnitText(report.timeUnit, report.subTimeUnit, report.specificDay)}
                                </Typography>
                              </TableCell>

                              <TableCell align={"left"} size="small">
                                <Delete
                                  disabled={!canDeletePowerReportSchedules}
                                  type={t`Report Schedule`}
                                  object={report}
                                  detach={handleDeleteClick}
                                  buttonClass={classes.deleteIcon}
                                />
                              </TableCell>

                            </TableRow>
                          );

                        }) : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </>
          }
        </div>
      </div>

      {selectedReport ? (
        <AddEditDialog
          selectedReport={selectedReport}
          enableImmediateReport={enableImmediateReport || true} //todo: remove!
          //  classes={classes}
          scheduledReportType={scheduledReportType}
          chosenSite={chosenSite}
          onClose={closeNewEditReportDialog}
          onSubmit={updateSelectedReports}
        />
      ) : null}
    </>
  );
};

export default TenantList;
