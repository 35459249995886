import { Grid, Typography } from "@material-ui/core/";
import { AccessTime } from "@material-ui/icons/";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import Tooltip from "../../../../components/Tooltip/LightTooltip";
import { Switch } from "../../../../cool_widgets/Switch";
import { AlertControl, Disconnect as DisconnectIcon } from "../../../../icons";
import { useStoreActions, useStoreState } from "../../../../models/RootStore";
import {
  AutoMode,
  AutoVRMode,
  ColdMode,
  DryIcon,
  HotMode,
  ModeFanIcon,
  Timer,
  TimerActive
} from "../../../../svgComponents";
import useStyle from "./UnitItem.style";
import RedAlertBold from "@icons/RedAlertBold";

const modesIcons: any = {
  0: <ColdMode style={{ transform: "scale(1.389)", fontSize: "17.5px", margin: "0 5px" }} />,
  1: <HotMode style={{ transform: "scale(1.2343)", fontSize: "17.5px", margin: "0 5px" }} />,
  2: <AutoMode style={{ transform: "scale(.76)", fontSize: "17.5px", margin: 0 }} />,
  3: <DryIcon style={{ transform: "scale(0.59)", fontSize: "17.5px", margin: "0 5px", marginLeft: "-7px" }} />,
  5: <ModeFanIcon style={{ transform: "scale(0.6945)", fontSize: "17.5px", margin: "0 5px", marginLeft: "-9px" }} />,
  7: <AutoVRMode style={{ transform: "scale(.76)", fontSize: "17.5px", margin: 0 }} />,
};

const defaultKeys = { modeKey: "activeOperationMode", powerKey: "activeOperationStatus", ambientTempKey: "ambientTemperature", setpointKeyPerMode: { 0: "activeSetpoint", 1: "activeSetpoint", 2: "activeSetpoint", 3: "activeSetpoint", 5: "activeSetpoint" } };
const keysBySubType: any = {
  0: defaultKeys,
  1: { modeKey: "heaterMode", powerKey: "heaterTankONOFF", ambientTempKey: "heaterRoomTemp", setpointKeyPerMode: { 0: "heaterCoolingSP", 1: "heaterHeatingSP", 2: "", 3: "", 5: "" } },
  2: defaultKeys,
  3: { modeKey: "activeOperationMode", powerKey: "activeOperationStatus", ambientTempKey: "ambientTemperature", setpointKeyPerMode: { 21: "activeSetpoint", 22: "activeSetpoint", 23: "activeSetpoint", 24: "activeSetpoint" } }
};
const waterHeaterModesToNormalMap: any = { 0: 2, 1: 1, 2: 0 };

const switchClick = (event: any) => {
  event.stopPropagation();
  event.preventDefault();
};

const ControlItem = (props: any) => {
  const classes = useStyle();
  const { unitId, siteId, selectItem, groupId, selectedItem, addMessage } = props;

  const { updateWaterHeaterDHWTankSwitch, updateUnitLocally } = useStoreActions((state) => state.units);
  const types = useStoreState((state) => state.types);
  const operationStatusesMirror = useStoreState((state) => state.operationStatusesMirror);
  const devices = useStoreState((state) => state.devices.allDevices);
  const { temperatureScaleMirror } = useStoreState((state) => state);
  const { temperatureScale = 0 } = useStoreState((state) => state.users.me);
  const unitPowerControl = useStoreActions((state) => state.units.changePowerState);
  const unit: any = useStoreState((state) => state.units.allUnits[unitId]);
  const { showClearFilterAlerts } = useStoreState((state) => state.users.userPreferences);


  if (!unit) {
    return null;
  }

  const { operationStatuses, temperatureScale: tempScaleTypes, operationModesExtended } = types;
  const { on: powerOnVal, off: powerOffVal } = operationStatusesMirror;
  const tempUnit = tempScaleTypes[temperatureScale as number] === "celsius" ? "°C" : "°F";
  const isCelsius = +temperatureScaleMirror.celsius === +temperatureScale;

  const {
    message,
    filter,
    name,
    isConnected = true,
    device,
    schedules = [],
    enableOnoff,
    enableOnState,
    isHalfCDegreeEnabled,
    capabilityFlags = {},
    subType = 0,
    setbackActive = false,
    permissions = {},
    realActiveOperationMode
  } = unit;

  const { canControlOperationStatus } = permissions;

  const {
    enableOperationStatusSelection = false, enableOperationModeSelection = false,
    enableSetpointControl = false, enableAmbientTemperature = false } = capabilityFlags;

  const { modeKey, powerKey, ambientTempKey, setpointKeyPerMode } = keysBySubType[subType] || defaultKeys;
  const mappedMode = unit[modeKey];
  const mode = subType === 1 ? waterHeaterModesToNormalMap[mappedMode] : mappedMode;
  const isVA = operationModesExtended[mode] === `Virtual Auto`;
  const setpoint = isVA ? unit[setpointKeyPerMode[realActiveOperationMode]] : unit[setpointKeyPerMode[mode]];
  const ambientTemperature = unit[ambientTempKey];
  const power = unit[powerKey];
  const isItemOn = +power === +powerOnVal;

  const toggleUnitPower = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    const value: number = operationStatuses[power] === "on" ? +powerOffVal : +powerOnVal;

    if (subType === 1) { //water heater unit
      updateWaterHeaterDHWTankSwitch({ id: unitId, data: { operationStatus: value } })
        .then(() => updateUnitLocally({ id: unitId, unit: { heaterTankONOFF: value } }))
        .catch((err: any) => addMessage({ message: err.message }));
      return;
    }

    unitPowerControl({ unitId, state: value })
      .catch((error: any) => addMessage({ message: error.message }));

  };

  const deviceIsConnected = devices[device] && devices[device]?.isConnected;
  const selected = !selectedItem.isGroup && selectedItem.unitId === unitId && selectedItem.groupId === groupId;
  const hasError = message && `${message}`.replace(/\s/g, "").toUpperCase() !== "OK";

  if (!isConnected || !deviceIsConnected) {
    return (
      <div className={clsx(classes.unitStyle, { [classes.selected]: selected }, classes.erroredDiv)}>
        <div className={classes.locSec}>
          <Typography noWrap className={clsx(classes.nameStyle, { [classes.boldText]: selected })}>{name}</Typography>
        </div>
        <div className={classes.disconnectedIconContainer}>
          <DisconnectIcon />
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(classes.unitStyle, { [classes.selected]: selected }, hasError && classes.erroredDiv)}
      onClick={() => selectItem(siteId, { isGroup: false, unitId, groupId, type: "unit" })}
    >
      <div className={clsx(classes.locSec, { [classes.errorColor]: hasError })}>
        <Typography noWrap className={clsx(classes.nameStyle, { [classes.boldText]: selected })}>{name}</Typography>
      </div>
      <div className={classes.unitRowRightSide}>
        <div className={classes.tempSec}>

          {enableOperationModeSelection &&
            <div className={clsx(classes.iconDiv, { [classes.notVisibile]: !isItemOn || hasError })}>
              {modesIcons[mode] || ""}
            </div>}
          <div className={classes.tempInfo}>

            {(enableSetpointControl || isVA) &&
              <Typography className={clsx(classes.tempText, { [classes.boldText]: selected })}>
                {isItemOn ? (_.isNumber(setpoint) ? `${isHalfCDegreeEnabled && isCelsius ? Math.floor(setpoint * 2) / 2 : Math.round(setpoint)}°` : "") : t`OFF`}
              </Typography>}
            {enableAmbientTemperature &&
              <Typography className={classes.roomTemp}>
                <span>{t`Now`}</span>
                <span>: {`${Math.round(ambientTemperature)}${tempUnit}`}</span>
              </Typography>}
          </div>
          <div className={clsx(classes.iconDiv, { [classes.notVisibile]: !setbackActive })} style={{ marginLeft: "7px" }}>SB</div>
        </div>

        <Grid container className={classes.powerAlertsSchedulesContainer}>
          <div className={classes.notesSec}>
            <div className={clsx(classes.noteIcon, { [classes.notVisibile]: hasError || !(schedules.length > 0) })}> <AccessTime /> </div>
            {hasError && <div className={classes.errorContainer}>{message} <RedAlertBold style={{ marginLeft: "9px" }} /></div>}
            {unit?.hasActiveTimer && <div className={classes.noteIcon}>
              {(unit?.hasActiveTimer && isItemOn) ? <TimerActive /> : <Timer />}
            </div>
            }
            <div className={clsx(classes.noteIcon)}>{(!hasError && filter) && showClearFilterAlerts && <AlertControl color={hasError ? "#f05146" : "#f7b500"} className={classes.alertIcon} />}</div>
          </div>
          {enableOperationStatusSelection &&
            <Tooltip title={enableOnoff || (power === 2 && enableOnState) ? t`Unit is restricted , Please check with the administrator` : ""}>
              <Switch
                id={"unitPowerSwtich"}
                checked={isItemOn}
                disableRipple={true}
                onClick={(e: any) => switchClick(e)}
                switchChange={(e: any) => canControlOperationStatus && toggleUnitPower(e)}
                value="checkedA"
                disabled={!canControlOperationStatus || enableOnoff || (power === 2 && enableOnState)}
              />
            </Tooltip>}
        </Grid>
      </div>
    </div>
  );
};

export default ControlItem;
