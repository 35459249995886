import React from 'react';
import { Grid } from '@material-ui/core';
import { AuditsFilter, IAuditsFilter } from '../AuditsFilter';
import { IActor } from '@hooks/useAudits';
import { t } from 'ttag';
import AuditActions from '../AuditActions';
import useStyle from './AuditsFilterGroup.style';
import AuditSource, { getSourceNames } from '../AuditSource';

/**
 * Represents the props interface for a component.
 * @interface IAuditsFilterGroupProps
 */
interface IAuditsFilterGroupProps {
    /**
    * Filter object for actors.
    * @type {IAuditsFilter}
    */
    actorsFilter: IAuditsFilter;
    /**
    * Filter object for actions.
    * @type {IAuditsFilter}
    */
    actionsFilter: IAuditsFilter;
    /**
    * Filter object for sources.
    * @type {IAuditsFilter}
    */
    sourcesFilter: IAuditsFilter;
    /**
    * Function to handle filter change.
    * @type {(filters: IAuditsFilter, type: string) => Promise<void>}
    */
    handleFilterChange: (filters: IAuditsFilter, type: string) => Promise<void>;
    /**
    * Record of full audit actors.
    * @type {Record<string, IActor>}
    */
    auditActors: Record<string, IActor>;
}

const AuditsFilterGroup: React.FC<IAuditsFilterGroupProps> = ({ actorsFilter, actionsFilter, sourcesFilter, handleFilterChange, auditActors }) => {
    const classes = useStyle();
    return (
        <Grid item xs={2} className={classes.filterContainer}>
            <Grid item className={classes.filterGridItem}>
                <AuditsFilter
                    title={t`Users`}
                    className={classes.filter}
                    filters={actorsFilter}
                    setFilter={(filters) => handleFilterChange(filters, 'actor')}
                    getFilterName={(actorId: string) => actorId ? auditActors?.[actorId]?.username || '-' : '-'}
                />
            </Grid>
            <Grid item className={classes.filterGridItem}>
                <AuditsFilter
                    title={t`Actions`}
                    className={classes.filter}
                    filters={actionsFilter}
                    setFilter={(filters) => handleFilterChange(filters, 'action')}
                    getFilterName={(action) => AuditActions[action] || action}
                />
            </Grid>
            <Grid item className={classes.filterGridItem}>
                <AuditsFilter
                    title={t`Sources`}
                    className={classes.filter}
                    filters={sourcesFilter}
                    setFilter={(filters) => handleFilterChange(filters, 'source')}
                    getFilterName={getSourceNames}
                    getFilterJSX={(source) => <AuditSource source={source} />}
                />
            </Grid>
        </Grid>
    );
};

export default AuditsFilterGroup;
