import {
  Dialog,
  DialogTitle,
  Typography
} from "@material-ui/core/";
import { Check, Reply } from "@material-ui/icons";
import { ToggleButton } from "@material-ui/lab";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { isIOS } from "react-device-detect";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { AlertBell } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import assetsService from "../../services/assetsService";
import useStyles from "./AlertInfo.style";

const AlertInfo: React.FC<any> = (props: any) => {
  const [brand, setBrand] = useState<string>("");
  const getSystem = useStoreState((state) => state.systems.getSystem);
  const setAcknowledgedEvent = useStoreActions((actions) => actions.alerts.setAcknowledgedEvent);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const { alert, setAcknowledged } = props,
    classes = useStyles(),
    [isEmailDialogOpen, setEmailDialogOpen] = React.useState(false),
    [emails, setEmails] = React.useState<string[]>([]),
    [btnIsActive, activateBtn] = React.useState<boolean>(false),
    [error, setError] = useState(null),
    [resolvedAlertDescription, setResolvedAlertDescription] = React.useState<any>(""),
    sendAlertEmail = useStoreActions((actions) => actions.alerts.sendAlertEmail),
    types = useStoreState((state) => state.types),
    { eventTypes, trapPriorityTypes } = types,

    handleEmailDialog = () => {
      setEmails([]);
      setEmailDialogOpen(!isEmailDialogOpen);
    },
    handleEmails = (emails: string[]) => {
      setEmails(emails);
    },
    sendAlertByEmail = () => {
      sendAlertEmail({ emails, id: alert.id })
        .then(() => setEmailDialogOpen(false))
        .catch((error: any) => console.log("error", error));
    },
    validateEmail = (email: any) => {
      return isEmail(email);
    },
    addLabeledEmail = (email: string, index: number, removeEmail: (index: number) => void) => {
      return (
        <div data-tag className={classes.emailPart} key={index}>
          {email}
          <span data-tag-handle onClick={() => removeEmail(index)}>
            ×
          </span>
        </div>
      );
    };

  const { permissions } = alert || {};
  const { canAcknowledgeUnAcknowledge, canClose, canShareByEmail, canUpdate } = permissions || {};

  useEffect(() => {
    if (!brand) {
      const system = getSystem(alert.systemId);
      const brand = system?.brandNum || "";

      setBrand(brand);

    }
    setResolvedAlertDescription("");
    activateBtn(false);
    if (!alert || alert.userText === undefined) { return; }
    setResolvedAlertDescription(alert.userText);
  }, [alert]);

  useEffect(() => {
    if (
      !alert ||
      (alert && alert.status !== "Closed") ||
      (alert && alert.userText === resolvedAlertDescription) ||
      !resolvedAlertDescription
    ) {
      return;
    }

    activateBtn(true);
  }, [resolvedAlertDescription]);
  const isAckAlert = alert?.status === "Acknowledged";
  const isOpenAlert = alert?.status === "Open";
  const isLowLineQualityError = eventTypes.lowLineQualityError === alert.type;

  return (
    <>
      <div className={clsx(classes.infoDialogStyle, { [classes.iosDialogHeight]: isIOS })}>
        <div style={{ overflow: "auto" }}>
          <div id="title" style={{
            display: "flex", alignItems: "center", minHeight: "60px",
            borderBottom: "solid 1px #e5e2e5",
            padding: "0 20px"
          }}>
            <AlertBell style={{ width: 25, height: 25 }} />
            <Typography style={{
              marginLeft: "15px",
              fontFamily: "Roboto",
              fontSize: 20,
              fontWeight: 600,
              color: "#15111f"
            }}>{t`Alert`}{` ${alert.shortId}`}</Typography>
          </div>
          <div className={classes.row} style={{ minHeight: 60 }}>
            <Typography className={classes.alertDetails}>{t`Alert Details`}</Typography>
            {(isOpenAlert || isAckAlert) &&
              <ToggleButton
                disabled={!canAcknowledgeUnAcknowledge}
                value="ack"
                selected={isAckAlert}
                classes={{ root: classes.ackButton, label: classes.ackButtonLable }}
                onChange={() => {
                  const data = alert.status === "Acknowledged" ? false : true;
                  // if (toAck) {
                  //   setToAck(true);
                  //   return;
                  // }
                  setAcknowledgedEvent({ id: alert.id, data: { value: data } })
                    .then(() => {
                      setAcknowledged({ id: alert.id, data });
                    })
                    .catch((err: any) => addMessage({ message: err.message }));
                }}
              >
                <Check className={classes.ackButtonIcon} />
                <Typography className={classes.ackButtonText}>{t`Acknowledge`}</Typography>
              </ToggleButton>
            }
          </div>
          <div className={classes.row}>
            <Typography className={classes.title}>{t`Unit Name`}</Typography>
            <Typography className={clsx(classes.data, classes.cutLongNames)}>{alert.unitName}</Typography>
          </div>
          <div className={classes.row}>
            <Typography className={classes.title}>{t`Brand`}</Typography>
            <img
              width={100}
              height={25}
              src={assetsService.getBrandLogo(`${brand}-grey.svg`)}
              onError={(event: any) =>
                (event.target.src = assetsService.getBrandLogo("empty.png"))
              }
              alt={brand}
            // className={brandLogo}
            />
          </div>
          <div className={classes.row}>
            <Typography className={classes.title}>{t`Open Date`}</Typography>
            <Typography className={classes.data}>{alert.time}</Typography>
          </div>
          <div className={classes.row}>
            <Typography className={classes.title}>{t`Close Date`}</Typography>
            <Typography className={classes.data}> {alert.status === "Closed" ? alert.clearTime || "" : ""}</Typography>
          </div>
          <div className={classes.row}>
            <Typography className={classes.title}>{t`Error Code`}</Typography>
            <Typography className={classes.data}>{alert.errorCode}</Typography>
          </div>
          {isLowLineQualityError && <div className={classes.row}>
            <Typography className={classes.title}>{t`Device / Line`}</Typography>
            <Typography className={classes.data}>{`${alert.deviceName} / L${alert.lineName}`}</Typography>
          </div>}
          {alert?.priority && <div className={classes.row}>
            <Typography className={classes.title}>{t`Priority`}</Typography>
            <Typography className={classes.data}>{trapPriorityTypes[alert?.priority]}</Typography>
          </div>}
          <div style={{ padding: 20, borderBottom: "1px solid #efeff2" }}>
            <Typography className={classes.title}>{t`Description`}</Typography>
            <Typography
              className={classes.description}
              dangerouslySetInnerHTML={{
                __html:
                  alert.styledDescription ||
                  alert.description ||
                  alert.errorDescription ||
                  "",
              }}
            />
          </div>
        </div>
        <Button
          disabled={!canShareByEmail}
          variant="contained"
          className={classes.shareButton}
          style={{ width: "90%", marginLeft: "5%", marginTop: "20px" }}
          startIcon={<Reply style={{ transform: "rotateY(180deg)", marginTop: "-3px" }} />}
          onClick={() => setEmailDialogOpen(true)}
        >
          {t`Share Alert`}
        </Button>
      </div>
      <Dialog
        open={isEmailDialogOpen}
        aria-labelledby="email-alert-dialog-title"
        aria-describedby="email-alert-dialog-description"
        maxWidth="sm"
        classes={{ paper: classes.emailDialogStyle }}
      >
        <div className={classes.dialogTitle}>
          <DialogTitle id="simple-dialog-title">{t`Share alert`}</DialogTitle>
        </div>
        <div className={classes.emailDialogContent}>
          <Typography>Recipients</Typography>
          <ReactMultiEmail
            placeholder="Email Address/es"
            emails={emails}
            onChange={handleEmails}
            validateEmail={validateEmail}
            getLabel={addLabeledEmail}
          />
          <div className={classes.emailDialogActions}>
            <Button
              white
              width={110}
              marginRight
              onClick={handleEmailDialog}
            >{t`Cancel`}</Button>
            <Button
              type="submit"
              width={110}
              disabled={_.isEmpty(emails)}
              onClick={sendAlertByEmail}
            >{t`Share`}</Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
export default AlertInfo;
