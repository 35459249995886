import { Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton, TextField, Button as MUIButton, Typography, Slide, Tooltip } from "@material-ui/core/";
import { ArrowBack, Close, Refresh } from "@material-ui/icons";
import { Services as SdkServices } from "coolremote-sdk";
import { addDays, sub } from "date-fns";
import _, { camelCase, every, findIndex, includes, isEmpty, orderBy } from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { t } from "ttag";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { MenuSearch as Search } from "../../svgComponents";
import AlertInfo from "./AlertInfo";
import useStyles from "./Alerts.style";
import AlertsTable from "./AlertsTable";
import { saveAs } from "file-saver";
import Button from "../../cool_widgets/Button";
import CoolDateRangePicker from "../../components/CoolDateRangePicker/CoolDateRangePicker";
import SvgArrow from "../../icons/Arrow";
import clsx from "clsx";
import { AiOutlineCalendar } from "react-icons/ai";
import { TransitionProps } from "@material-ui/core/transitions";
import { Alert } from "@icons";


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const alertLogsDateRangesPresets = [
  {
    id: 'today',
    name: t`Today`,
    value: {
      startDate: new Date(moment().startOf('day').toISOString()),
      endDate: new Date(moment().endOf('day').toISOString())
    }
  },
  {
    id: 'lastWeek',
    name: t`Last week`,
    value: {
      startDate: new Date(moment().subtract(7, 'days').startOf('day').toISOString()),
      endDate: new Date(moment().endOf('day').toISOString())
    }
  },
  {
    id: 'twoWeeks',
    name: t`Last 2 weeks`,
    value: {
      startDate: new Date(moment().subtract(14, 'days').startOf('day').toISOString()),
      endDate: new Date(moment().endOf('day').toISOString())
    }
  },
  {
    id: 'month',
    name: t`Last month`,
    value: {
      startDate: new Date(moment().subtract(1, 'months').add(1, 'days').startOf('day').toISOString()),
      endDate: new Date(moment().endOf('day').toISOString())
    }
  },
  {
    id: 'allOpen',
    name: t`All open alerts`,
    value: null,
    primary: true
  },
];

const DateRangePickerComponent = () => {

  const classes = useStyles();
  const selections: any = useStoreState((s) => s.selections.selections);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [range, setRange] = useState<any>(null);
  const [pickerKey, setKey] = useState<any>(Math.ceil(Math.random() * 1000));
  const [selected, setSelected] = useState<string>(!selections.dateRange ? 'allOpen' : "");

  const handleNewDateRange = (range: any) => {
    if (range) {
      updateSelections({ type: "time", data: range });
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      return;
    }
    setRange(selections.dateRange);
    setSelected(!selections.dateRange ? 'allOpen' : "");
  }, [isOpen])

  return (
    <>
      <MUIButton
        style={{ width: "20%" }}
        disableRipple
        className={clsx(classes.selectUnitButton)}
        classes={{
          label: classes.selectUnitButton__text,
          endIcon: classes.Arrow
        }}
        size="large"
        onClick={() => setIsOpen(true)}
        endIcon={<SvgArrow />}
      >
        <AiOutlineCalendar style={{ fontSize: '18px', marginRight: '5px' }} />
        <Typography className={classes.datePickerButton}>
          {selections?.dateRange ? `${moment(selections.dateRange.startDate).format("ll")} - ${moment(selections.dateRange.endDate).format("ll")}` : t`All Open Alerts`}
        </Typography>
      </MUIButton>

      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsOpen(false)}
      >
        <div className={classes.datePickerDialogContainer}>
          <div className={classes.datePickerDialogHeader}>
            <Typography className={classes.datePickerTitle}>{t`Date Picker`}</Typography>
            <Typography className={classes.datePickerSubtitle}>{t`Select Date Range`}</Typography>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div className={classes.datePickerDialogPresetCont} >
              {
                alertLogsDateRangesPresets.map((obj: any) => {
                  return (
                    <MUIButton
                      variant="contained"
                      key={`filterBtn-${obj.id}`}
                      className={clsx(classes.datePickerDialogPresetBtn, obj.id === selected && classes.datePickerDialogPresetBtnActive)}
                      onClick={() => {
                        setRange(obj.value);
                        setSelected(obj.id);
                        setKey((prev: any) => prev + 1);

                        if (!obj.value) {
                          updateSelections({ type: "time", data: null });
                          setIsOpen(false);
                          return;
                        }
                      }}
                    >
                      {obj.name}
                    </MUIButton>
                  )
                })
              }
            </div>
            <div>
              <CoolDateRangePicker
                key={`${pickerKey}`}
                handleSubmit={handleNewDateRange}
                initialRange={range}
                hideIfNull={true}
                minDate={new Date("1900-01-01")}
                onlyPastAllowed={false}
                noRangeLimit={false}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}

const Alerts: React.FC<any> = (props: any) => {

  const selectedAlertObj = useStoreState((state) => state.selectedAlert);
  const { dateFormat, timeFormat } = useStoreState((state) => state.users);
  const [selectedAlert, setSelectedAlert] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();
  const types = useStoreState((state) => state.types);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const getAlertsByFilters = useStoreActions((action) => action.alerts.getAlertsByFilters);
  const getNotificationAlerts = useStoreActions((action) => action.alerts.getNotificationAlerts);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const allAlerts = useStoreState((state) => state.alerts.allAlerts);
  const newerAlertsExist = useStoreState((state) => state.alerts.newerAlertsExist);
  const allSites = useStoreState((state) => state.sites.allSites);
  const userPreferences = useStoreState((s) => s.users.userPreferences);
  const updateUserPreferences = useStoreActions((a) => a.users.updateUserPreferences);
  const updateAlertStore = useStoreActions((a) => a.alerts.updateAlertStore);
  const getAlertsWithParams = useStoreActions((a) => a.alerts.getAlertsWithParams);
  const { eventStatusTypes, trapPriorityTypes } = types;
  const sitesFlags = useStoreState((s) => s.sites.sitesFlags);
  const match: any = useRouteMatch();
  const history = useHistory();

  const [rows, setRows] = useState<any>([]);
  const [page, setPage] = useState<any>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(userPreferences?.serviceAlertsRowsPerPage || 50);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState<any>(userPreferences?.alertsFilters || { code: [], status: [], type: [], description: [], priority: [], category: [] });
  const [firstload, setFirstLoad] = useState<string>("processing");
  const [openTroubleshoot, setOpenTroubleshoot] = useState<boolean>(false);
  const setUnitUpdateStatus = useStoreActions((action) => action.setUnitUpdateStatus);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const selections = useStoreState((s) => s.selections.selections);
  const [resetFilters, setResetFilters] = useState<boolean>(false);
  const [filteredAlerts, setFilteredAlerts] = useState<any>([]);
  const [errorCodeArray, setErrorCodesArray] = useState<any>([]);
  const [descriptionArray, setDescriptionArray] = useState<any>([]);
  const [priorityArray, setPriorityArray] = useState<any>([]);
  const [alertTypeArray, setAlertTypeArray] = useState<any>([]);
  const [statusesArray, setStatusesArray] = useState<any>([]);
  const [categoryArray, setCategoryArray] = useState<any>([]);
  const [file, setFile] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [refresh, setRefresh] = useState<number>(0);
  const [notificationAlertsId, setNotificationAlertsId] = useState<any>(null);
  const isFilterSelected = !every(selectedFilters, isEmpty);

  const { unitTypes } = types;
  setUnitUpdateStatus({ status: "" });

  useEffect(() => {
    if (!resetFilters) {
      setResetFilters(true);
      setFirstLoad("init");
    }
  }, [resetFilters]);

  useEffect(() => {
    if (selectedAlertObj && selectedAlertObj.id) {
      const {
        timestamp,
        unitId,
        systemId,
        siteId,
        isSensor,
        isPowerMeter
      } = selectedAlertObj;
      const evDate = new Date(timestamp);
      const endDate = moment(evDate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD") ? addDays(evDate, 1) : evDate;
      const startDate = sub(evDate, { hours: 24 });
      updateSelections({ type: "time", data: { startDate, endDate } });
      if (!isSensor && !isPowerMeter) {
        if (unitId) {
          updateSelections({ type: "unit", data: unitId });
        }
        else if (systemId) {
          updateSelections({ type: "system", data: systemId });
        }
        else if (siteId) {
          updateSelections({ type: "site", data: siteId });
        }
      } else {
        if (siteId) {
          updateSelections({ type: "site", data: siteId });
        }
      }

      return;
    }
    if (!selections?.dateRange?.endDate || !selections?.dateRange?.startDate) {
      updateSelections({
        type: "time",
        data: null
      });
    }

  }, [allAlerts, selectedAlertObj]);

  useEffect(() => {

    if (match?.params?.notificaitonShortId) {
      clearFilters();
      getNotificationAlerts({ notificationShortId: match?.params?.notificaitonShortId })
        .then((resp: any) => {
          setNotificationAlertsId(match?.params?.notificaitonShortId);
          setRows(Object.values(resp));
          setFirstLoad("done");
        })
        .catch((err: any) => addMessage({ message: err.message }))
        .finally(() => setIsDisabled(false));
      return;
    }

    let startTime = 0;
    let endTime = 0;

    if (selections.dateRange) {
      startTime = Date.UTC(selections.dateRange?.startDate.getFullYear(), selections.dateRange?.startDate.getMonth(), selections.dateRange?.startDate.getDate()) - 32400000;
      endTime = Date.UTC(selections.dateRange?.endDate.getFullYear(), selections.dateRange?.endDate.getMonth(), selections.dateRange?.endDate.getDate(), 23, 59, 59) + 32400000;
    }

    (async () => {
      setIsDisabled(true);
      setRows([]);
      firstload === "init" && setFirstLoad("processing");
      const alertsAPIresult = !selections.dateRange ? await getAlertsByFilters({ status: [types.eventStatusTypes["open"], types.eventStatusTypes["acknowledged"]], openAlertsOnly: false }) : await getAlertsByFilters({ startTime, endTime, withAllOpenAlerts: false });
      const sortedAlerts = orderBy(alertsAPIresult, ["eventTime"], ["desc"]);
      firstload !== "done" && setFirstLoad("done");
      const errorCodesArray: any[] = [];
      const alertTypeArray: any[] = [];
      const descriptionArray: any[] = [];
      const priorityArray: any[] = [];
      const statusesArray: any[] = [];
      const categoryArray: any[] = [];
      setErrorCodesArray(errorCodesArray);
      setStatusesArray(statusesArray);
      setAlertTypeArray(alertTypeArray);
      setDescriptionArray(descriptionArray);
      setPriorityArray(priorityArray);
      setCategoryArray(categoryArray);
      setRows(sortedAlerts);
      setOpen(true);
      setIsDisabled(false);
    })();
  }, [selections.dateRange, refresh, timeFormat, dateFormat, match.params.notificaitonShortId]);

  useEffect(() => {
    setPage(0);
    if (!rows.length) {
      setFilteredAlerts([]);
      return;
    }

    const filteredAlerts = getFilteredAlerts(rows);
    setFilteredAlerts(filteredAlerts);
  }, [selections, selectedFilters, rows, searchTerm, notificationAlertsId]);

  useEffect(() => {
    if (JSON.stringify(userPreferences?.alertsFilters) !== JSON.stringify(selectedFilters)) {
      updateUserPreferences({ alertsFilters: selectedFilters })
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (!isEmpty(selectedAlertObj) && !isEmpty(filteredAlerts)) {

      const alertIndex = findIndex(filteredAlerts, { id: selectedAlertObj.id });
      const pageNum = alertIndex === -1 ? 1 : Math.ceil((alertIndex + 1) / rowsPerPage);
      setSelectedAlert(filteredAlerts[alertIndex]);
      setPage(pageNum - 1);
    }
  }, [filteredAlerts, rowsPerPage]);

  const clearFilters = () => {
    setSelectedFilters({
      code: [],
      status: [],
      type: [],
      description: [],
      priority: [],
      category: [],
    });
  };

  const closeTroubleshootFile = () => {
    setOpenTroubleshoot(false);
    setFile(null);
  };
  const clearAllFilters = () => {
    setSelectedFilters({ code: [], status: [], type: [], description: [], priority: [], category: [] });
  };
  const selectUnit = (unitId: string) => {
    updateSelections({ type: "unit", data: unitId });
  },
    onRowSelect = (alert: any) => {
      setSelectedAlert(alert);
      setOpen(true);
    },
    setPageNum = (num: number) => {
      setPage(num);
    },
    setNumPerPage = (num: number) => {
      setRowsPerPage(num);
      updateUserPreferences({ serviceAlertsRowsPerPage: num });
    },
    updateFilters = (newFilters: any) => {
      setSelectedFilters({ ...selectedFilters, ...newFilters });
      setPage(0);
    },
    refreshAlerts = () => {
      setRefresh(refresh + 1);
      getAlertsWithParams({ status: [types.eventStatusTypes["open"], types.eventStatusTypes["acknowledged"]]});
    },
    addResolvedDescription = (resolvedDescription: string, id: string) => {
      let selectedAlert = null;
      const changedAlerts = filteredAlerts.map((alert: any) => {
        if (alert.id === id) {
          selectedAlert = { ...alert, userText: resolvedDescription };
          return selectedAlert;
        }
        return alert;
      });

      setFilteredAlerts(getFilteredAlerts(changedAlerts));
      setSelectedAlert(selectedAlert);
    },
    updateAlertLocally = (alertObj: any, data: any) => {
      let selectedAlert = null;
      let selectedAlertIndex = -1;
      const changedAckAlerts = filteredAlerts.map((alert: any, i: number) => {
        if (alert.id === alertObj.id) {
          selectedAlert = { ...alert, ...data };
          if (selectedAlert?.status !== "Open" && !selections.dateRange) {
            selectedAlertIndex = i;
            selectedAlert = null
          }
          return selectedAlert;
        }
        return alert;
      });

      data.status && updateAlertStore({ id: alertObj.id, data: { status: eventStatusTypes[camelCase(data.status)] } });
      const newFilteredAlerts = getFilteredAlerts(changedAckAlerts?.filter((_: any, i: number) => i !== selectedAlertIndex))
      setFilteredAlerts(newFilteredAlerts);
      setSelectedAlert(selectedAlert || newFilteredAlerts?.[0]);
    },
    convertDataToCSV = () => {
      let csvContent = "\uFEFF"; // UTF-16 BOM (Byte Order Mark)

      const headers = [
        "DATE & TIME",
        "CLOSE DATE & TIME",
        "UNIT NAME",
        "SITE NAME",
        "SYSTEM NAME",
        "ALERT ID",
        "DESCRIPTION",
        "ALERT STATUS",
        "ERROR CODE",
        "ERROR DESCRIPTION",
        "ALERT TYPE",
        "PRIORITY"
      ];

      csvContent += headers.join(",") + "\n";

      for (const row of filteredAlerts) {
        const alertId = (row.shortId || "").replace(/(\r\n|\n|\r|,)/gm, " ");
        const description = (row.description || "").replace(/(\r\n|\n|\r|,)/gm, " ");
        const unitName = (row.unitName || "").replace(/(\r\n|\n|\r|,)/gm, " ");
        const siteName = (row.siteName || "").replace(/(\r\n|\n|\r|,)/gm, " ");
        const systemName = (row.systemName || "").replace(/(\r\n|\n|\r|,)/gm, " ");
        const errorDescription = (row.errorDescription || "").replace(/(\r\n|\n|\r|,)/gm, " ");

        const customRow = [
          row.time,
          row.status !== "Open" ? row.clearTime || "" : "",
          unitName,
          siteName,
          systemName,
          alertId,
          description,
          row.status,
          row.errorCode,
          errorDescription,
          row.alertType,
          trapPriorityTypes[row?.priority] || "-"
        ];
        csvContent += customRow.map(value => value && `"${value?.replace(/"/g, '""')}"`).join(",") + "\n";
      }

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-16" });
      saveAs(blob, "Alerts Log.csv");
    };

  const showAllAlerts = () => {
    setNotificationAlertsId(null);
    history.push("/alerts");
  };
  // Apply filters and selections to alerts
  const getFilteredAlerts = (alerts: any[]) => {
    function applyFilters(alerts: any[]) {
      return _(alerts)
        .filter((alert) => {
          return selectedFilters.code.length
            ? selectedFilters.code.includes(alert.errorCode || "")
            : true;
        })
        .filter((alert) => {
          return selectedFilters.type.length
            ? selectedFilters.type.includes(alert.alertType)
            : true;
        })
        .filter((alert) => {
          return selectedFilters.status.length
            ? selectedFilters.status.includes(alert.status)
            : true;
        })
        .filter((alert) => {
          return selectedFilters.description.length
            ? selectedFilters.description.includes(alert.description)
            : true;
        })
        .filter((alert) => {
          return selectedFilters.priority.length
            ? selectedFilters.priority.includes(trapPriorityTypes[alert.priority])
            : true;
        })
        .filter((alert) => {
          return selectedFilters.category.length
            ? selectedFilters.category.includes(alert.severity.name)
            : true;
        })
        .filter((alert) => {
          return searchTerm?.length
            ? (
              alert?.description?.toUpperCase()?.includes(searchTerm?.toUpperCase())
              || alert?.shortId?.toUpperCase()?.includes(searchTerm?.toUpperCase())
              || alert?.errorCode?.toUpperCase()?.includes(searchTerm?.toUpperCase())
            )
            : true;
        })
        .value();
    }
    function applySelections(alerts: any[]) {
      return _(alerts)
        .filter((alert: any) =>
          selections.unitId ? alert.alertItemContainerIds.unitId
            ? alert.alertItemContainerIds.unitId === selections.unitId || allUnits[alert.alertItemContainerIds.unitId]?.serviceUnits?.includes(selections.unitId)
            : (alert.alertItemContainerIds.unitIds.length > 1 && alert.alertItemContainerIds.unitIds.includes(selections.unitId))
            : true
        )
        .filter((alert: any) =>
          selections.siteId
            ? alert.alertItemContainerIds.siteId === selections.siteId
            : true
        )
        .filter((alert: any) =>
          selections.systemId && !selections.unitId ? alert.alertItemContainerIds.systemId
            ? alert.alertItemContainerIds.systemId === selections.systemId
            : (alert.alertItemContainerIds.systemIds.length > 1 && alert.alertItemContainerIds.systemIds.includes(selections.systemId))
            : true
        )
        .filter((alert: any) =>
          selections.systemId && includes(selections.systemId, "_")
            ? allUnits[alert.alertItemContainerIds.unitId].line.toString() === selections.systemId.split("_")[0] &&
            allUnits[alert.alertItemContainerIds.unitId].device === selections.systemId.split("_")[1]
            : true
        )
        .filter((alert: any) =>
          selections.customerId
            ? alert.alertItemContainerIds.customerId === selections.customerId
            : true
        )
        .orderBy(["timestamp"], ["desc"])
        .value();
    }

    function onlyUnique(value: any, index: any, self: any) {
      return self.indexOf(value) === index;
    }

    const updateFiltersVals = (rows: any) => {
      const errorCodeArr: any = [];
      const statusArr: any = [];
      const alertTypesArr: any = [];
      const priorityArr: any = [];
      const descriptionArr: any = [];
      const categoryArr: any = [];
      let blankAdded: any = false;
      for (let rowIndex in rows) {
        const { status, errorCode, alertType, description, priority, severity } = rows[rowIndex];
        if (!errorCode && blankAdded === false) {
          errorCodeArr.push("");
          blankAdded = true;
        }
        if (!!errorCode) { errorCodeArr.push(errorCode); }
        if (!!status) { statusArr.push(status); }
        if (!!alertType) { alertTypesArr.push(alertType); }
        if (!!priority) { priorityArr.push(trapPriorityTypes[priority]); }
        if (!!description) { descriptionArr.push(description); }
        if (!!severity) { categoryArr.push(severity.name); }
      }

      setErrorCodesArray(errorCodeArr.filter(onlyUnique));
      setStatusesArray(statusArr.filter(onlyUnique));
      setAlertTypeArray(alertTypesArr.filter(onlyUnique));
      setPriorityArray(priorityArr.filter(onlyUnique));
      setDescriptionArray(descriptionArr.filter(onlyUnique));
      setCategoryArray(categoryArr.filter(onlyUnique));
    };
    const alertsBasedTopSelections = applySelections(alerts)
    const filteredRows = applyFilters(alertsBasedTopSelections);
    updateFiltersVals(alertsBasedTopSelections);
    return filteredRows;
  };

  const generateReport = (templateId: string, unitId: string) => {
    SdkServices.generateTroubleshootReport(templateId, unitId)
      .then((report: any) => {
        setFile(report);
      })
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const getMoreInfo = (alert: any) => {
    if (!alert?.unitIds?.length || alert.isSensor || alert.isPowerMeter || alert.disableDoubleClick) {
      return;
    }

    let selectedUnit = alert.unitIds[0];

    if (alert?.unitIds?.length > 0) {
      const unit = allUnits[alert.unitIds[0]] || {};
      if (!unit) {
        return;
      }
      const { site = "", type } = unit;
      const { isServiceSite } = sitesFlags[site];
      const isAnomaly = alert?.alertType === "Anomaly";
      if (isAnomaly) {
        const unitCounts = alert.unitIds.reduce((data: any, unitId: any) => {
          const unit = allUnits[unitId];
          if (unit.type === unitTypes["service"]) {
            data.indoorCount++;
          }
          if (unit.type === unitTypes["outdoor"]) {
            data.outdoorCount++;
            if (!data.firstODU) {
              data.firstODU = unit.id;
            }
          }
          return data;
        }, { indoorCount: 0, outdoorCount: 0, firstODU: null });

        const isMixed = unitCounts.indoorCount > 0 && unitCounts.outdoorCount > 0;

        if (isMixed) {
          selectedUnit = unitCounts?.firstODU || alert.unitIds[0];
        }
      }

    }

    localStorage.setItem("time", alert.timestamp);
    localStorage.setItem("alertCheck", JSON.stringify(true));
    localStorage.setItem("unitId", selectedUnit);
    alert.trap && localStorage.setItem("trap", alert.trap);
    alert.ruleId ? localStorage.setItem("ruleId", alert.ruleId) : localStorage.removeItem("ruleId");
    // history.push(`/unit-stats/${selectedUnit}`);
    window.open(`${window.location.origin}/unit-stats/${selectedUnit}`, '_blank');

  };


  const searchComponent = (
    <TextField
      placeholder={t`Search...`}
      value={searchTerm}
      onChange={(event: any) => setSearchTerm(event.target.value)}
      InputProps={{
        disableUnderline: true, classes: { root: classes.inputRoot },
        endAdornment:
          !searchTerm ? (<Search />) : (
            <IconButton
              onClick={() => setSearchTerm("")}
              className={classes.closeIconStyle}
            >
              <Close />
            </IconButton>
          )
      }}
    />
  );

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          showDateRangePicker={!notificationAlertsId}
          hideUnitSelection={!!notificationAlertsId}
          hideSystemSelection={!!notificationAlertsId}
          hideSiteSelection={!!notificationAlertsId}
          hideCustomerSelection={!!notificationAlertsId}
          allowUnassignedControls={true}
          searchComponent={searchComponent}
          textAllCustomers={true}
          applySiteTypeFiltering
          screenTitle="alerts"
          customDateRangePickerComponent={<DateRangePickerComponent />}
        />
        <Grid className={classes.contentHeaderContainer}>
          {newerAlertsExist && <div className={classes.newAlertsContainer}>
            <Alert className={classes.alertIcon} />
            <div className={classes.alertsMessagesContainer}>
              <Typography className={classes.alertMessage}>{t`New alerts available. Click Refresh button to update page.`}</Typography>
              {isFilterSelected && <Typography className={classes.alertMessage}>{t`Some filters are applied on the page and may hide the new alerts`}</Typography>}
            </div>
          </div>}
          <div className={classes.buttonsContainer}>
            {
              !!notificationAlertsId &&
              <Button
                disableRipple
                variant="outlined"
                startIcon={
                  <ArrowBack />
                }
                onMouseUp={showAllAlerts}
              >
                {t`Show all alerts`}
              </Button>
            }
            {
              !notificationAlertsId &&
              <Button
                disableRipple
                variant="contained"
                className={classes.shareButton}
                startIcon={
                  <Icon style={{ transform: "rotateY(180deg)", marginTop: "-3px" }}>reply</Icon>
                }
                onMouseUp={convertDataToCSV}
              >
                {t`Export Report`}
              </Button>
            }
          </div>
        </Grid>
        <Grid container className={classes.contentContainer}        >
          <Grid item xs={9} style={{ height: "100%", paddingRight: "20px" }}>
            <Card className={classes.content}>
              <AlertsTable
                rows={filteredAlerts}
                refreshAlerts={refreshAlerts}
                onRowSelect={onRowSelect}
                setPage={setPageNum}
                page={page}
                setRowsPerPage={setNumPerPage}
                rowsPerPage={rowsPerPage}
                isDisabled={isDisabled}
                selectedAlert={selectedAlert && selectedAlert.id}
                setSelectedAlert={setSelectedAlert}
                filterValues={{
                  code: errorCodeArray,
                  status: statusesArray,
                  type: alertTypeArray,
                  description: descriptionArray,
                  priority: priorityArray,
                  category: categoryArray
                }}
                appliedFilters={selectedFilters}
                getSelectedFilters={updateFilters}
                style={{ height: "100%" }}
                clearAllFilters={clearAllFilters}
                getMoreInfo={getMoreInfo}
              />
            </Card>
          </Grid>

          <Grid item xs={3} className={classes.alertInfoContainer}>
            <Card className={classes.content}>
              {selectedAlert && <AlertInfo
                open={!!(open && rows.length)}
                alert={selectedAlert}
                selectUnit={selectUnit}
                refreshAlerts={refreshAlerts}
                addResolvedDescription={addResolvedDescription}
                updateAlertLocally={updateAlertLocally}
                setOpenTroubleshoot={setOpenTroubleshoot}
                generateReport={generateReport}
                getMoreInfo={getMoreInfo}
                allSites={allSites}
              />}
            </Card>
          </Grid>
        </Grid>
      </div>

      {
        openTroubleshoot &&
        <Dialog onClose={closeTroubleshootFile} aria-labelledby="customized-dialog-title" open={openTroubleshoot}>
          <DialogTitle id="customized-dialog-title">
            {t`Troubleshoot guidance`}
          </DialogTitle>
          <DialogContent dividers>
            {!!file ? <div dangerouslySetInnerHTML={{ __html: file }} /> : <span />}
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={closeTroubleshootFile}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
};
export default Alerts;
