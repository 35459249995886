import { CircularProgress, Dialog, Divider, IconButton, makeStyles, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { t } from "ttag";
import { TopBar } from "../../components";
import Switch from "../../components/SmallSwitch/Switch";
import Button from "../../cool_widgets/Button";
import DatesList from "../../cool_widgets/DatesList/DatesList";
import DaysList from "../../cool_widgets/DaysList/DaysList";
import { Close } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { minsToTime } from "../../services/timeService";
import { AddIcon, ArrowBack, Delete, Edit } from "../../svgComponents";
import AddEditSchedule from "./AddEditSchedulePopup/AddEditSchedulePopup";
import styles from "./scheduleList.style";

const ScheduleList: React.FC<any> = (props: any) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch<{ unitId?: string, groupId?: string; systemId?: string; sensorId?: string }>();
  const { params } = match;
  const { unitId, groupId, systemId, sensorId } = params;
  const isGroup = !!groupId;
  const isSystem = !!systemId;

  const [currentItem, setCurrentItem] = useState<any>({});
  const [itemSchedules, setItemSchedules] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(true);
  const [selectedScheduleId, openAddEditSchedulePopup] = useState<string>("");
  const [toDeleteItem, setToDeleteItem] = useState<any>(null);
  const [disabledGroupSchedules, setDisabledGroupSchedules] = useState<any>([]);
  const [disabledSystemSchedule, setDisabledSystemSchedule] = useState<any>([]);

  const types = useStoreState((state) => state.types);
  const temperatureSymbol = useStoreState((state) => state.users.getTemperatureScaleDisplay());
  const timeFormat = useStoreState((state) => state.users.timeFormat);
  const getUnit = useStoreState((actions) => actions.units.getUnit);
  const _storeUpdateUnit = useStoreActions((actions) => actions.units._storeUpdateUnit);
  const getGroup = useStoreActions((actions) => actions.groups.getGroup);
  const { updateSchedule, getObjectSchedules } = useStoreActions((actions) => actions.schedules);
  const getSystem = useStoreState((state) => state.systems.getLocalSystem);
  const getLocalGroup = useStoreState((state) => state.groups.getLocalGroup);
  const getSensor = useStoreState((state) => state.sensors.getSensor);
  const getSensorSchedules = useStoreActions((action) => action.sensors.getSensorSchedules);

  const item = systemId ? (getSystem(systemId)) : (unitId ? getUnit(unitId) : (sensorId ? (getSensor(sensorId)) : getLocalGroup(groupId)));
  const { permissions = {}, capabilityFlags = {}, system = "", gropu = "" } = item || {};
  const { canCreateSchedules } = permissions;

  const { enableSetpointControl = false } = capabilityFlags;

  const { weekDays, scheduleCategories } = types;

  const addMessage = useStoreActions((actions) => actions.errorMessage.addMessage);
  const deleteSchedule = useStoreActions((actions) => actions.schedules.deleteSchedule);
  const me = useStoreState((state) => state.users.me);
  const { temperatureScale = 1 } = me;
  const { temperatureScaleMirror } = useStoreState((state) => state);
  const isCelsius = +temperatureScaleMirror.celsius === +temperatureScale;
  const isHalfCDegreeEnabled = item?.isHalfCDegreeEnabled && isCelsius;

  const goBack = () => {
    history.push(!!unitId || !!groupId ? "/control" : `/site-management?selectedSite=${currentItem?.site}`);
  };

  useEffect(() => {
    if (!unitId && !groupId && !systemId && !sensorId) {
      history.push("/");
      return;
    }

    if(sensorId) {
      setCurrentItem(item);
      getSensorSchedules(sensorId)
      .then((schedules: any) => setItemSchedules(schedules))
      .catch((error: any) => {
        addMessage({ message: error.message });
      }).finally(() => setLoading(false));
      return;
    }

    if (isGroup) {
      getGroup(groupId || "")
        .then((group: any) => {
          getObjectSchedules({ objectType: "group", id: groupId })
            .then((schedules: any) => {
              setCurrentItem(group);
              setItemSchedules(schedules);
            })
            .catch((error: any) => {
              addMessage({ message: error.message });
            }).finally(() => setLoading(false));
        })
        .catch(() => history.push("/"));
      return;
    }
    if (isSystem && systemId) {
      const system = getSystem(systemId);
      setCurrentItem(system);

      getObjectSchedules({ objectType: "system", id: systemId })
        .then((schedules: any) => {
          setItemSchedules(schedules);
        })
        .catch((error: any) => {
          addMessage({ message: error.message });
        }).finally(() => setLoading(false));
      return;
    }

    const unit = getUnit(unitId);
    if (!unit) {
      history.push("/");
      return;
    }

    getObjectSchedules({ objectType: "unit", id: unitId, type: "0" })
      .then((schedules: any) => {
        setCurrentItem(unit);
        setItemSchedules(schedules);
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
      }).finally(() => setLoading(false));

    system && getObjectSchedules({ objectType: "system", id: unit.system })
      .then((res: any) => {
        setDisabledSystemSchedule(res);
      });
    let groupSchedules: any = {};
    if (unit?.groups) {
      (async () => {
        for (const groupId of item?.groups) {
          await getObjectSchedules({ objectType: "group", id: groupId })
            .then((schedules: any) => {
              groupSchedules = { ...groupSchedules, ...schedules };
            });
          setDisabledGroupSchedules(groupSchedules);
        }
        setDisabledGroupSchedules(groupSchedules);
      })();
    }
  }, []);

  const deleteSelectedSchedule = (scheduleId: string) => {
    deleteSchedule(scheduleId)
      .then(() => {
        if (!isGroup && !isSystem) {
          const { schedules, id } = currentItem;
          const filteredSchedules = schedules.filter((id: string) => id !== scheduleId);
          _storeUpdateUnit({ id, data: { schedules: filteredSchedules } });
        }
        delete itemSchedules[scheduleId];
        setItemSchedules({ ...itemSchedules });
        setToDeleteItem(null);
      })
      .catch((err: any) =>
        addMessage({
          message: err.message
        })
      );
  };

  const changeScheduleActiveState = (e: any, id: string) => {
    const isDisabled = !e.target.checked;
    updateSchedule({ id, data: { isDisabled } }).then(() => {
      itemSchedules[id].isDisabled = isDisabled;
      setItemSchedules({ ...itemSchedules });
    });
  };

  const updateLocalSchedules = (schedule: any) => {
    const { id } = schedule;
    itemSchedules[id] = schedule;
    setItemSchedules({ ...itemSchedules });
  };

  const handleDeleteClose = () => {
    setToDeleteItem(null);
  };

  const Schedule = ({ schedule, notEditable = false, systemSchedule = false, groupSchedule = false }: any) => {

    const { powerOnTime, powerOffTime, setpoint, id, days = [], dates = [],
      isDisabled, name: scheduleName, scheduleCategory, permissions } = schedule;
    const { canUpdate, canDelete } = permissions || {}
    const startHour = powerOnTime || powerOnTime === 0 ? minsToTime(powerOnTime, timeFormat) : "";
    const endHour = powerOffTime || powerOffTime === 0 ? minsToTime(powerOffTime, timeFormat) : "";

    return (
      <div
        className={clsx(classes.scheduleContainer, { [classes.notEditable]: notEditable })}
        key={`schedule-${id}`}
      >
        <div className={classes.scheduleHeader}>
          <Typography className={classes.longNamestyle}>
            {scheduleName + (groupSchedule ? t` - Group` : systemSchedule ? t` -  System` : "")}

          </Typography>
          <div className={classes.scheduleControl}>
            <Edit onClick={() => notEditable || !canUpdate ? {} : openAddEditSchedulePopup(id)} />
            <Delete onClick={() => notEditable || !canDelete ? {} : setToDeleteItem(schedule)} />
            <Switch
              disabled={notEditable || !canUpdate}
              checked={!isDisabled}
              disableRipple={true}
              onChange={(e: any) =>
                changeScheduleActiveState(e, id)
              }
              value={true}
            />
          </div>
        </div>
        <div className={classes.scheduleBody}>
          <div className={classes.rowContainer}>
            <div className={classes.bodyRow}>
              <div className={classes.timeSection}>
                <TextField
                  variant={"outlined"}
                  name={"Start Time"}
                  label={t`Start Time`}
                  placeholder={t`None`}
                  value={powerOnTime || powerOnTime === 0
                    ? startHour
                    : t`None`}
                  className={classes.inputClass}
                  disabled={true}
                />
                <TextField
                  variant={"outlined"}
                  name={"End Time"}
                  label={t`End Time`}
                  placeholder={t`None`}
                  value={powerOffTime || powerOffTime === 0
                    ? endHour
                    : t`None`}
                  className={classes.inputClass}
                  disabled={true}
                />
              </div>
            </div>

            {((isGroup || isSystem) ? !!setpoint : (enableSetpointControl && !!setpoint)) && (
              <div className={classes.setpointContainer}>
                <div className={classes.setPointSection}>
                  <Typography className={classes.setPointStyle}>
                    {isHalfCDegreeEnabled ? Math.floor(setpoint * 2) / 2 : Math.round(setpoint)}
                  </Typography>
                  <Typography className={classes.tempSymbolStyle}>
                    {temperatureSymbol}
                  </Typography>
                </div>
              </div>
            )}
          </div>
          <div className={classes.bodyRow}>
            {scheduleCategory === scheduleCategories.weekly &&
              <DaysList
                days={Object.keys(weekDays)}
                activeDays={days}
                action={() => { }}
                disablePointer
                white={false}
              />}
            {scheduleCategory === scheduleCategories.calendar &&
              <DatesList
                dates={dates}
                white
              />}
          </div>
          <Divider className={classes.dividerStyle} />
        </div>
      </div>
    );

  };
  return <>
    <TopBar
      leftIconComponent={<ArrowBack />}
      leftAction={goBack}
      rightIconComponent={<AddIcon style={{ transform: "scale(1.2)" }} />}
      rightAction={() => openAddEditSchedulePopup("new")}
      hideRightComponent={!canCreateSchedules}
      title={t`Schedules`}
      screenTitle="control"
    />
    <div className={classes.screenContainer}>
      <div className={classes.gridStyle}>
        <div className={classes.schedulesContainer}>
          {isLoading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}><CircularProgress /></div> :
            (Object.values(itemSchedules).length === 0 && disabledGroupSchedules.length === 0 && disabledSystemSchedule.length === 0) ? (
              <Typography
                className={classes.noSchedulesStyle}
              >{t`There are no schedules to show`}</Typography>
            ) : (
              <>
                {Object.values(itemSchedules).map((schedule: any) => <Schedule schedule={schedule} key={`schedule-${schedule.id}`} />)}
                {Object.values(disabledGroupSchedules).map((schedule: any) => <Schedule schedule={schedule} key={`schedule-${schedule.id}`} notEditable groupSchedule />)}
                {Object.values(disabledSystemSchedule).map((schedule: any) => <Schedule schedule={schedule} key={`schedule-${schedule.id}`} notEditable systemSchedule />)}
              </>
            )}
        </div>
      </div>
    </div>
    {(selectedScheduleId === "new" || (selectedScheduleId && itemSchedules[selectedScheduleId])) &&
      <AddEditSchedule
        onClose={() => openAddEditSchedulePopup("")}
        schedule={itemSchedules[selectedScheduleId]}
        scheduleId={selectedScheduleId}
        itemType={isGroup ? "group" : isSystem ? "system" : "unit"}
        itemId={isGroup ? groupId : isSystem ? systemId : unitId}
        updateLocalSchedules={updateLocalSchedules}
        item={currentItem}
        updateStoreUnit={_storeUpdateUnit}
        isHalfCDegreeEnabled={isHalfCDegreeEnabled}
      />}

    {toDeleteItem && (
      <Dialog
        open={toDeleteItem}
        onClose={(event: any) => {
          event.stopPropagation();
          handleDeleteClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.dialog }}
      >
        <div className={classes.titleContent}>
          <Typography className={classes.headerTitle}>{t`Delete Schedule`}</Typography>
          <IconButton disableRipple onClick={() => setToDeleteItem(null)} className={classes.overWriteIcon}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <div className={classes.dialogContent}>
          <Typography className={classes.DialogContentText}>
            {t`Are you sure you want to delete ` + toDeleteItem?.name + ` schedule?`}
          </Typography>
        </div>
        <div className={classes.deleteDialogActions}>
          <Button
            onClick={() => deleteSelectedSchedule(toDeleteItem.id)}
            className={classes.yesBtn}
            white width={130}
            marginRight
          >
            {t`Yes`}
          </Button>

          <Button
            autoFocus={true}
            onClick={() => handleDeleteClose()}
            width={130}
          >
            {t`No`}
          </Button>
        </div>
      </Dialog>
    )}
  </>;
};

export default ScheduleList;
