import {
  Dialog,
  DialogContentText,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { PlayArrow } from "@material-ui/icons";
import clsx from "clsx";
import { filter, forEach, orderBy, isNil, find, cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import { AddEditScript, ApplyToDialog } from "../../components/AddEditScript";
import Delete from "../../components/Delete/Delete";
import ImportDialog from "../../components/ImportProceduresDialog";
import ExportDialog from "../../components/ExportProceduresDialog";

import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../cool_widgets/Button";
import { Download as SVGDownload, Export } from "../../icons";
import { EditIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./CommissioningList.style";
import { MenuSearch as Search } from "../../svgComponents";
import { Close, FilterList } from "@material-ui/icons";
import { IProcedure, IBaseProcedure, IUnitOption, ISystem, EntityOption, scriptSystemUnitTypes } from '../../hooks/useScripts/types';

const readFileAsText = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsText(file);
  });
};

const ScriptsList: React.FC = (props: any) => {
  const classes = useStyles();
  const history = useHistory();

  const isInitialized = useStoreState((s) => s.isInitialized);
  const selections = useStoreState((state) => state.selections.selections);
  const addMessage = useStoreActions((action) => action.errorMessage.addMessage);
  const types = useStoreState((state) => state.types);
  const getCustomer = useStoreState((state) => state.customers.getCustomer);
  const allSites = useStoreState((s) => s.sites.allSites);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const allSystems = useStoreState((state) => state.systems.allSystems);
  const { unitTypes, procedureStepTypes, stepsRunningModes } = types;

  const {
    getSiteScripts,
    createScript,
    updateScript,
    deleteScript,
    runProcedure,
    createMultiScripts
  } = useStoreActions((action) => action.scripts);

  const [openAddScript, setOpenAddScript] = useState<boolean>(false);
  const [scripts, setScripts] = useState<any>({});
  const [editScript, setEditScript] = useState<any>(null);
  const [applyToId, setApplyToId] = useState<string>("");
  const [showGoToPopup, handleShowGoToPopup] = useState<boolean>(false);
  const [systemUnitsOptions, setSystemUnitsOptions] = useState<any>([]);
  const [siteEntitesMap, setSiteEntitiesMap] = useState<any>({});
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredScripts, setFilteredScripts] = useState<any>([]);
  const [isImportDialogOpen, setImportDialogOpen] = useState<boolean>(false);
  const [isExportDialogOpen, setExportDialogOpen] = useState<boolean>(false);
  const [proceduresToImport, setProceduresToImport] = useState<IProcedure[]>([]);

  const { customerId, siteId } = selections;
  const { permissions = {} } = siteId ? allSites[siteId] : {};
  const { canCreateProcedures, canRunProcedures, canDeleteProcedures, canUpdateProcedures } = permissions || {};

  useEffect(() => {
    if (!siteId) {
      return;
    }

    getSiteScripts({ siteId, type: 1 })
      .then((res: any) => setScripts(res))
      .catch((err: any) => addMessage({ message: err.message }));
  }, [siteId]);

  useEffect(() => {
    setFilteredScripts(getFilteredScripts());
  }, [scripts, searchValue]);

  const getFilteredScripts = () => {
    const filteredScripts = !searchValue ? scripts : filter(scripts, (script) =>
      script.name.toLowerCase().includes(searchValue.toLowerCase())
    );

    return orderBy(filteredScripts, [(script: any) => script.name?.toLowerCase()], ["asc"]);
  }

  useEffect(() => {
    if (!siteId) {
      return;
    }

    const siteEntites: any = {};
    const systemUnitOptionsPerType: Record<string, Record<string, ISystem>> = {
      [scriptSystemUnitTypes.service]: {},
      [scriptSystemUnitTypes.outdoor]: {},
      [scriptSystemUnitTypes.mixed]: {}
    };

    Object.values(allSystems).forEach((system: any) => {
      const { name, id, brandNum: brand, site, entityType = 'system' } = system;
      if (site !== siteId) {
        return;
      }

      // store site entities
      siteEntites[id] = { id, name, brand, entityType};


      // add each system option to all unit types
      forEach(scriptSystemUnitTypes, (type) => {
        systemUnitOptionsPerType[type][id] = { id, name, brand, units: [], entityType };
      });
    });

    // add the fake system option to all unit types
    forEach(scriptSystemUnitTypes, (type) => {
      systemUnitOptionsPerType[type]['fake-system'] = { id: 'fake-system', name: 'Unassigned units', units: [], entityType: 'system' };
    });

    Object.values(allUnits).forEach((unit: any) => {
      const { type, site, name, id, brand, system, isVisible, entityType = 'unit' } = unit;
      if (site !== siteId || (type !== unitTypes.service && type !== unitTypes.outdoor)) {
        return;
      }

      // store site entities
      siteEntites[id] = { id, name, brand, system, type, entityType };

      const unitOption: IUnitOption = { id, name, brand, system, type, entityType };

      // if the unit is not assigned to a system or the system does not exist, add it to the unassigned units
      const systemId = !system || !allSystems[system] ? 'fake-system' : system;
      systemUnitOptionsPerType[scriptSystemUnitTypes.mixed][systemId].units.push(unitOption);

      if (type === unitTypes.service) {
        systemUnitOptionsPerType[scriptSystemUnitTypes.service][systemId].units.push(unitOption);
      }

      if (type === unitTypes.outdoor) {
        systemUnitOptionsPerType[scriptSystemUnitTypes.outdoor][systemId].units.push(unitOption);
      }
    });

    const options = Object.values(scriptSystemUnitTypes).reduce((acc, value) => ({
      ...acc,
      [value]: Object.values(systemUnitOptionsPerType[value])
    }), {});

    setSystemUnitsOptions(options);
    setSiteEntitiesMap(siteEntites);
  }, [siteId]);

  const addNewScript = (data: any, siteId: string) => {
    return createScript({ site: siteId, ...data })
      .then((resp: any) => setScripts({ ...scripts, [resp.id]: { ...resp } }))
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const addNewMultiScripts = async (procedures: IBaseProcedure[]) => {
    if (!siteId) {
      return;
    }

    try {
      const newScripts = await createMultiScripts({ site: siteId, procedures });
      setScripts({ ...scripts, ...newScripts });
    } catch (err) {
      addMessage({ message: (err as Error).message })
    }
  };

  const updateExistingScript = (data: any, scriptId: string) => {
    return updateScript({ id: scriptId, data })
      .then((resp: any) => setScripts({ ...scripts, [scriptId]: { ...scripts[scriptId], ...resp } }))
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const deleteCurrentScript = (id: string) => {
    return deleteScript(id)
      .then(() => {
        delete scripts[id];
        setScripts({ ...scripts });
      })
      .catch((err: any) => addMessage({ message: err.message }));

  };
  const closeDialog = () => {
    setOpenAddScript(false);
    setEditScript(null);
  };

  const getBrandName = (brandNum: any) => {
    const brand = find(types.hvacBrands, { value: brandNum });
    return !isNil(brand) && brand.name ? brand.name : "";
  };

  const runNewProcedureInstance = (id: string, units: any, systems: any) => {
    runProcedure({ id, data: { units, systems } })
      .then(() => {
        setApplyToId("");
        handleShowGoToPopup(true);
      })
      .catch((err: any) => addMessage({ message: err.message }));

  };

  const handleRunProcedure = (script: any) => {
    if (script.stepsRunningMode !== stepsRunningModes.specificUnits) {
      setApplyToId(script.id);
      return;
    }

    // check if the rule has units/systems assigneds within its steps, if not show warning message.
    if(checkScriptMissingRequiredEntities(script)) {
      addMessage({ message: t`This procedure require you define the specific units to run the procedure on. Edit the rule to complete the information` });
    } else {
      runNewProcedureInstance(script.id, [], []);
    }
  }

  const checkScriptMissingRequiredEntities = (script: IProcedure) => {
    const { steps = [] } = script;

    return steps.some(step => {
      if (
        step.type === procedureStepTypes.wait ||
        (step.type === procedureStepTypes.condition && step.condition === "procedureStatus")
      ) {
        return false;
      }

      return !step.units?.length && !step.systems?.length;
    });
  };

  if (!isInitialized) { return <Loading />; }

  const onGo = () => {
    history.push("/commissioning-logs");
  };

  const onStay = () => {
    handleShowGoToPopup(false);
  };

  const LeavePagePopUp = () => {
    return (
      <Dialog
        open={true}
        onClose={onStay}
        aria-labelledby="responsive-dialog-title"
        classes={{ paper: classes.dialogStyle }}
      >
        <DialogContentText
          className={clsx(classes.contentStyle, {
            [classes.textNotCentered]: false
          })}
        >{t`Go to Test Procedures & Logs page`}</DialogContentText>
        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <Button white marginRight onClick={onStay} variant="contained">
            {t`Stay on current page`}
          </Button>
          <Button onClick={onGo} variant="contained">
            {t`Ok`}
          </Button>
        </div>
      </Dialog>
    );
  };

  const SearchComponent = (
    <TextField
      placeholder={t`Search...`}
      value={searchValue}
      onChange={(event: any) => setSearchValue(event.target.value)}
      InputProps={{
        disableUnderline: true,
        classes: { root: classes.inputRoot },
        endAdornment:
          !searchValue ? (<Search />) : (
            <IconButton
              onClick={() => setSearchValue('')}
              className={classes.closeIconStyle}
            >
              <Close />
            </IconButton>
          )
      }}
    />
  );

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    if (!selectedFile) {
      return;
    }

    try {
      const fileText = await readFileAsText(selectedFile);
      const items: IProcedure[] = JSON.parse(fileText);

      if (!Array.isArray(items)) {
        addMessage({ message: t`Wrong data format.` })
        return;
      }

      if (items.length === 0) {
        addMessage({ message: t`File is empty.` })
        return;
      }

      if (items.length === 1) {
        addNewMultiScripts(items);
        return;
      }

      setProceduresToImport(items)
      setImportDialogOpen(true);
    } catch (err) {
      addMessage({ message: (err as Error).message })
    }
  };

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          customGeneralNames={{ site: t`Select Site` }}
          hideSystemSelection
          hideUnitSelection
          screenTitle="commissioningProdecuders"
          searchComponent={SearchComponent}
        />
        {!siteId ? <FilterRequire type={t`site`} /> :
          <>
            <div className={classes.headerButtons}>
              {/* please avoid deleting the commented code, it was only hidden to not be inclueded in current sprint*/}
              {/*<div className={classes.ImportExportContainer}>
                <LightTooltip title={t`Export Procedure`}>
                  <IconButton disableRipple onClick={() => setExportDialogOpen(true)} className={clsx(classes.iconBtnStyle, classes.exportIcon)}>
                    <Export />
                  </IconButton>
                </LightTooltip>
                <LightTooltip title={t`Import Procedure`}>
                  <IconButton disabled={!canCreateProcedures} disableRipple className={clsx(classes.iconBtnStyle, classes.importIcon)}>
                    <label htmlFor="file"><SVGDownload /></label>
                    <input disabled={!canCreateProcedures} value={""} type="file" hidden name="file" id="file" accept=".json" onChange={handleFileChange} />
                  </IconButton>
                </LightTooltip>
              </div>*/}
              <Button
                disabled={!canCreateProcedures}
                width={235}
                onClick={() => setOpenAddScript(true)}
              >
                {t`Add New Procedure Script`}
              </Button>
            </div>
            <Paper elevation={0} className={classes.paperTableContainer}>
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`Name`}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`Brand`}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`Description`}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`run`}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`EDIT`}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`REMOVE`}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredScripts.map((script: any) => {
                      const { canDelete, canUpdate, canRun } = script?.permissions || {};
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={script.id}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                            align="left"
                          >
                            {script.name}
                          </TableCell>
                          <TableCell
                            classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                            align="left"

                          >
                            {getBrandName(script.userSelections?.brand) || "All"}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                            align="left"
                          >
                            {script.description}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                            align="left"
                          >
                            <LightTooltip title={t`run`}>
                              <IconButton disabled={!canRunProcedures || !canRun} disableRipple onClick={() => handleRunProcedure(script)} className={classes.iconBtnStyle}>
                                <PlayArrow className={classes.iconColor} />
                              </IconButton>
                            </LightTooltip>
                          </TableCell>
                          <TableCell classes={{ root: classes.overWritePadding }} align="left">
                            <LightTooltip title={t`Edit commissioning script`}>
                              <IconButton
                                disabled={!canUpdate}
                                disableRipple
                                onClick={() => setEditScript(script)}
                                className={classes.iconBtnStyle}
                              >
                                <EditIcon />
                              </IconButton>
                            </LightTooltip>
                          </TableCell>
                          <TableCell classes={{ root: classes.overWritePadding }} align="left">
                            <Delete
                              disabled={!canDeleteProcedures || !canDelete}
                              type={t`commissioning script`}
                              object={script}
                              detach={() => deleteCurrentScript(script.id)}
                            ></Delete>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>}
        {isImportDialogOpen &&
          <ImportDialog
            getBrandName={getBrandName}
            procedures={proceduresToImport}
            onClose={() => setImportDialogOpen(false)}
            addNewMultiScripts={addNewMultiScripts}
          />
        }
        {isExportDialogOpen &&
          <ExportDialog
            getBrandName={getBrandName}
            procedures={filteredScripts}
            onClose={() => setExportDialogOpen(false)}
          />
        }
        {(openAddScript || editScript) &&
          <AddEditScript
            close={closeDialog}
            createScript={addNewScript}
            editScript={editScript}
            updateScript={updateExistingScript}
            siteId={siteId || ""}
            canEdit={canUpdateProcedures}
            systemUnitsOptions={systemUnitsOptions}
            siteEntitesMap={siteEntitesMap}
          />
        }
        {
          applyToId && <ApplyToDialog
            close={() => setApplyToId("")}
            onSave={runNewProcedureInstance}
            brand={scripts[applyToId]?.userSelections?.brand}
            siteId={siteId}
            customerId={customerId}
            procedureId={applyToId}
            steps={scripts[applyToId]?.steps}
          />
        }
        {showGoToPopup && <LeavePagePopUp />}
      </div>
    </div>
  );
};

export default ScriptsList;
