import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import DisableWarning from "../../components/DisableWarning/DisableWarning";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import Button from "../../cool_widgets/Button";
import { CoolSwitch } from "../../cool_widgets/CoolSwitch";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import AddEditChangeoverRule from "./AddEditChangeoverRule";
import useStyle from "./AutomaticChangeover.style";

const AutomaticChangeover: any = (props: any) => {
  const classes = useStyle();

  const [allChangeovers, setAllChangeovers] = useState<any>({});
  const [disabledSystems, setDisabledSystems] = useState<any>([]);
  const [openAddEditRule, setOpenAddEditRule] = useState<boolean>(false);
  const [objectToDisable, setObjToDisable] = useState<any>(null);
  const [selectedRule, setSelectedRule] = useState<any>({});
  const { getSiteChangeovers, updateChangeover } = useStoreActions((action) => action.operationAutomate);
  const deleteChangeover = useStoreActions((action) => action.operationAutomate.deleteChangeover);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const { dateFormat } = useStoreState((state) => state.users);
  const allSites = useStoreState((s) => s.sites.allSites);
  const { customerId, siteId } = useStoreState((s) => s.selections.selections);
  const getSystem = useStoreState((s) => s.systems.getSystem);
  const getUnit = useStoreState((s) => s.units.getUnit);
  const allSystems = useStoreState((state) => state.systems.allSystems);
  const { canCreateChangeovers } = allSites?.[siteId || ""]?.permissions || {};

  useEffect(() => {
    if (!siteId) {
      return;
    }
    getSiteChangeovers(siteId)
      .then((res: any) => {
        setAllChangeovers(res);
      });
  }, [siteId]);

  useEffect(() => {
    const systemsWithChangeover = Object.values(allSystems).filter((system: any) => !!system.changeover).map((system) => system.id);
    setDisabledSystems(systemsWithChangeover);
  }, [allSystems]);

  const handleChangeoverDelete = (payload: any) => {
    deleteChangeover(payload.id)
      .then(() => {
        const systemIds = allChangeovers[payload?.id]?.systems || [];
        const { [payload.id]: toBeDeleted, ...restOfChangeovers } = allChangeovers;
        setDisabledSystems(disabledSystems.filter((id: string) => !systemIds.includes(id)));
        setAllChangeovers(restOfChangeovers);
      })
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const updateChangeOverRule = (id: string, data: any) => {
    updateChangeover({ id, updatedData: data })
      .then(() => {
        setAllChangeovers({ ...allChangeovers, [id]: { ...allChangeovers[id], ...data } });
        if (!!objectToDisable) {
          setObjToDisable(null);
        }
      });
  };

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          path={["Automatic Changeover"]}
          hideSystemSelection
          hideUnitSelection
          customGeneralNames={{ site: t`Select Site` }}
          screenTitle="changeover"
        />
        {!siteId ?
          <FilterRequire type={t`site`} />
          : <>
            <div className={classes.buttonContainer}>
              <Button disabled={!canCreateChangeovers} onClick={() => setOpenAddEditRule(true)}>{t`Add New Rule`}</Button>
            </div>
            <Paper elevation={0} className={classes.paperTableContainer}>
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`NAME`}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`SITE`}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`LAST EXECUTED`}</TableCell>
                      <TableCell
                        classes={{ root: clsx(classes.tableHeadCell, classes.textAlign) }}
                        align="left"
                      >{t`STATUS`}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        className={classes.iconsColumnWidth}
                        align="left"
                      >{t`REMOVE`}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.orderBy((Object.values(allChangeovers)), [(rule: any) => rule.name?.toUpperCase()],
                      ["asc"]).map((rule: any, index) => {
                        const { name, site, lastExecuted, enableChangeover, id, masterUnit = "", systems = [] } = rule;
                        const { canDelete, canUpdate } = rule?.permissions || {};
                        let siteId: string = getUnit(masterUnit)?.site || "";
                        if (!siteId) {
                          for (let index in systems) {
                            const system = getSystem(systems[index]);
                            if (system) {
                              siteId = system?.site || "";
                              break;
                            }
                          }
                        }
                        const { canUpdateAutoChangeOverRule = true, canDeleteAutoChangeOverRule = true, hvacAdvancedOperations, predictiveMaintenance } = allSites[siteId] || {};
                        if (!!siteId && !hvacAdvancedOperations && !predictiveMaintenance) {
                          return null;
                        }
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={index}
                            classes={{ root: classes.overWritePadding }}
                            onDoubleClick={() => {
                              setOpenAddEditRule(true);
                              setSelectedRule({ ...rule, canUpdateAutoChangeOverRule: canUpdate });
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              classes={{ root: classes.overWritePadding }}
                              align="left"
                            >
                              {name}
                            </TableCell>
                            <TableCell
                              classes={{ root: classes.overWritePadding }}
                              align="left"
                            >
                              {allSites[site]?.name}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              classes={{ root: classes.overWritePadding }}
                              align="left"
                            >
                              {lastExecuted ? moment(lastExecuted).format(dateFormat) : ""}
                            </TableCell>
                            <TableCell
                              classes={{ root: clsx(classes.overWritePadding, classes.textAlign) }}
                              align="left"
                            >
                              <CoolSwitch disabled={!canUpdateAutoChangeOverRule || !canUpdate} checked={enableChangeover} switchChange={(e: any) => {
                                const checked = e?.target?.checked;
                                if (!checked) {
                                  setObjToDisable(rule);
                                  return;
                                }
                                updateChangeOverRule(id, { enableChangeover: checked });
                              }} />
                            </TableCell>
                            <TableCell classes={{ root: classes.overWritePadding }} align="left">
                              <Delete
                                disabled={!canDeleteAutoChangeOverRule || !canDelete}
                                type={t`Rule`}
                                object={rule}
                                detach={handleChangeoverDelete}
                                buttonClass={classes.deleteIcon}
                              ></Delete>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>}
      </div>
      {openAddEditRule &&
        <AddEditChangeoverRule
          open={openAddEditRule}
          onClose={() => { setOpenAddEditRule(false); setSelectedRule({}); }}
          rule={selectedRule}
          setAllChangeovers={setAllChangeovers}
          allChangeovers={allChangeovers}
          disabledSystems={disabledSystems}
          setDisabledSystems={setDisabledSystems}
          siteId={siteId}
        />
      }
      {!!objectToDisable &&
        <DisableWarning
          type={t`Auto Changeover`}
          handleClose={() => setObjToDisable(null)}
          object={objectToDisable}
          approve={(id: any) => updateChangeOverRule(id, { enableChangeover: false })}
        />
      }
    </div >

  );
};

export default AutomaticChangeover;
