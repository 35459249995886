import { Grid, IconButton, Typography, useTheme } from "@material-ui/core/";
import { AccessTime } from "@material-ui/icons/";
import clsx from "clsx";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { PowerMeterItem, UnitItem } from "../";
import { Switch } from "../../../../cool_widgets/Switch";
import { ArrowDownO } from "../../../../icons";
import { useStoreActions, useStoreState } from "../../../../models/RootStore";
import { SensorItem } from "../SensorItem";
import useStyle from "./GroupItem.style";
import { GreenSwitch } from "../../../../cool_widgets/GreenSwitch";
import { UnitItemNew } from "../UnitItemNew";
import { BiError } from 'react-icons/bi';
import { MdBatteryUnknown } from 'react-icons/md';
import { t } from "ttag";

const ControlItem = (props: any) => {
  const { canControlSiteOperationStatus = true, openCollapse = true, addMessage, disableDragging, handleDragging, item, siteId, selectItem, hasSchedules, selectedItem, changeSitePower, onDragEnd, isCelsius } = props;
  const {
    id: itemId,
    isSensorGroup,
    name,
    units = [],
    sensors = [],
    schedules = [],
    powerMeters = [],
    itemsOrder = [],
    permissions = {}
  } = item;
  const { canControlOperationStatus } = permissions;
  const theme: any = useTheme();

  const [togglePowerButton, setTogglePowerButton] = useState<boolean>(false);
  const [targetPowerVal, setTargetPowerVal] = useState<any>(null);
  const [open, setOpen] = useState<boolean>((selectedItem?.firstTimeLoading && selectedItem.groupId === itemId) || false);
  const [groupStatus, setGroupStatus] = useState<any>({
    power: false,
    setPoint: null,
    mode: null
  });
  let spinnerTimer: number = 0;

  const types = useStoreState((state) => state);
  const groupPowerControl = useStoreActions((state) => state.groups.changePowerState);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const setUnitsActiveOperationStatus = useStoreActions((state) => state.units.setUnitsActiveOperationStatus);
  const devices = useStoreState((state) => state.devices.allDevices);
  const allSensors = useStoreState((state) => state.sensors.allSensors);
  const allSensorGroups = useStoreState((state) => state.sensors.allSensorGroups);
  const getUnitsBy = useStoreState((state) => state.units.getUnitsBy);

  const {
    operationStatusesMirror: { on: powerOnVal }
  } = types;
  const [disablePowerBtn, setDisablePowerBtn] = useState<boolean>(false);

  const classes = useStyle();

  const sensorGroupObj = isSensorGroup && itemId && allSensorGroups[itemId];

  const sensorErrorValue = !!sensorGroupObj ?
    sensorGroupObj?.sensors.reduce((val: any, sensorId: any) => {
      if (val) {
        return val;
      }

      const sensorObj = allSensors[sensorId];
      if (!!sensorObj && +sensorObj?.type === 23) {
        return sensorObj?.readingValue;

      }
    }, null) :
    null;

  const sensorBatteryValue = !!sensorGroupObj ?
    sensorGroupObj?.sensors.reduce((val: any, sensorId: any) => {
      if (val) {
        return val;
      }

      const sensorObj = allSensors[sensorId];
      if (!!sensorObj && +sensorObj?.type === 22) {
        return sensorObj?.readingValue;
      }

      return null;
    }, null) :
    null;


  useEffect(() => {
    if (!openCollapse) {
      setOpen(true);
      return;
    }
  }, [openCollapse]);

  useEffect(() => {

    let oneAtleastIsConnected: boolean = false;
    for (let x in units) {
      const id = units[x];
      const unit: any = allUnits[id];

      if (!unit || !unit?.isConnected || !devices[unit?.device || ""]?.isConnected) {
        continue;
      }

      oneAtleastIsConnected = true;
    }

    if (!oneAtleastIsConnected) {
      for (let x in sensors) {
        const id = sensors[x];
        const sensor: any = allSensors[id];

        if (!sensor || !devices[sensor?.device || ""]?.isConnected) {
          continue;
        }
        if (sensor?.type === 129) {
          oneAtleastIsConnected = true;
        }
      }
    }
    setDisablePowerBtn(!oneAtleastIsConnected);
  }, [item, powerOnVal]);

  useEffect(() => {
    if (!togglePowerButton) {
      return;
    }
    if (
      groupStatus.power === targetPowerVal
    ) {
      setTogglePowerButton(false);
      clearTimeout(spinnerTimer);
    }
  }, [
    togglePowerButton,
    targetPowerVal,
    spinnerTimer,
    groupStatus
  ]);

  const switchClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const changeGroupPowerState = (event: any, powerOn: boolean) => {
    event.preventDefault();
    event.stopPropagation();
    const state = powerOn ? 1 : 2;

    setTargetPowerVal(powerOn);
    setTogglePowerButton(true);
    spinnerTimer = window.setTimeout(() => {
      setTogglePowerButton(false);
    }, 5000);

    const siteUnits = getUnitsBy("site", siteId, { type: "control" });
    const connectedUnits = units.filter((id: any) => {
      const unitObj = allUnits[id];
      if (!unitObj) {
        return false;
      }
      const isUnitConnected = unitObj?.isConnected;
      const isDeviceConnected = devices[unitObj?.device]?.isConnected;
      return isUnitConnected && isDeviceConnected;
    });

    itemId === "allUnits" ? (siteUnits.length === units?.length) ? changeSitePower({ state, siteId }) : setUnitsActiveOperationStatus({ units: connectedUnits, operationStatus: state }) : groupPowerControl({ groupId: itemId, state })
      .catch((error: any) => {
        addMessage({ message: error.message });
      });
  };

  const onUnitsCount = units.filter((id: any) => {
    const unitObj = allUnits[id];
    if (!unitObj) {
      return false;
    }

    const isUnitOn = unitObj?.activeOperationStatus === +powerOnVal;
    const isUnitConnected = unitObj?.isConnected;
    const isDeviceConnected = devices[unitObj?.device]?.isConnected;
    return isUnitOn && isUnitConnected && isDeviceConnected;
  }).length
    + sensors.filter((id: any) => {
      const sensor = allSensors[id];
      return sensor?.type === 129 && sensor?.readingValue === 1 && devices[sensor?.device]?.isConnected;
    }).length;

  const isSelected = selectedItem.isGroup && selectedItem.groupId === itemId;

  return (
    <Fragment>
      <div
        {...handleDragging}
        className={clsx(classes.groupStyle, { [classes.selected]: isSelected })}
        onClick={() => itemId === "sensors" ? {} : selectItem(siteId, { isGroup: true, groupId: itemId, unitId: "", type: "" })}
      >
        <IconButton onClick={(event: any) => { event.stopPropagation(); setOpen(!open); }} className={classes.iconHolder}>
          <ArrowDownO style={{ transform: open ? "unset" : "rotate(270deg)" }}
            fill={theme.palette.background.controlArrowDown || null} />
        </IconButton>
        <div className={classes.innerContainer}>
          <div className={classes.locSec}>
            <Typography noWrap className={clsx(classes.nameStyle, { [classes.boldText]: selectedItem.isGroup && selectedItem.groupId === itemId })}>{name}</Typography>
          </div>
          {(itemId !== "powerMeters" && !isSensorGroup) &&
            <Grid container className={clsx(classes.powerAlertsSchedulesContainer, { [classes.hidden]: itemId === "sensors" || units.length === 0 })}>
              <div className={classes.notesSec}>
                {schedules.length > 0 && <AccessTime className={classes.noteIcon} />}
              </div>
              <Typography className={classes.unitsCount}>{onUnitsCount}/{units.length}</Typography>
              <GreenSwitch
                checked={onUnitsCount > 0}
                disableRipple={true}
                onClick={(e: any) => (itemId === "allUnits" ? canControlSiteOperationStatus : canControlOperationStatus) && switchClick(e)}
                switchChange={(e: any) => (itemId === "allUnits" ? canControlSiteOperationStatus : canControlOperationStatus) && changeGroupPowerState(e, !(onUnitsCount > 0))}
                value="checkedA"
                disabled={disablePowerBtn || (itemId === "allUnits" ? !canControlSiteOperationStatus : !canControlOperationStatus)}
              />
            </Grid>}

            {!!sensorGroupObj &&
            <Grid className={clsx(classes.sensorGroupInfoSection)}>
              {sensorErrorValue !== null &&
                <div className={classes.sensorGroupInfoLine}>
                  <BiError style={{ fontSize: '18px' }} />
                  <Typography>
                    {t`Error:` + ` ${sensorErrorValue}`}
                  </Typography>
                </div>
              }
              {sensorBatteryValue !== null &&
                <div className={classes.sensorGroupInfoLine}>
                  <MdBatteryUnknown style={{ fontSize: '18px' }} />
                  <Typography>
                    {t`Battery:` + ` ${sensorBatteryValue}`}
                  </Typography>
                </div>
              }
            </Grid>}

        </div>
      </div>
      <DragDropContext onDragEnd={(result: any) => onDragEnd(result)(itemId)}>
        <Droppable droppableId="list">
          {(provided: any) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {open &&
                itemsOrder.map((item: any, index: number) => {
                  const [id, show, type = "unit"] = item;
                  if (!show) {
                    return null;
                  }
                  if (allUnits[id]) {
                    return (
                      <Draggable isDragDisabled={disableDragging} key={`group-${itemId}-unit-${id}`} draggableId={id} index={index}>
                        {(provided: any) => (
                          <div ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            <UnitItemNew addMessage={addMessage} key={`u-group-${itemId}-unitNew-${id}`} selectedItem={selectedItem} unitId={id} siteId={siteId} selectItem={selectItem} groupId={itemId} hasSchedules={true} />
                          </div>)}
                      </Draggable>
                    );
                  }
                  if (allSensors[id] && allSensors[id].isVisible) {
                    return (
                      <Draggable isDragDisabled={disableDragging} key={`group-${itemId}-sensor-${id}`} draggableId={id} index={index}>
                        {(provided: any) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <SensorItem selectedItem={selectedItem} sensorId={id} siteId={siteId} selectItem={selectItem} groupId={itemId} isCelsius={isCelsius} />
                          </div>)}
                      </Draggable>
                    );
                  }
                  return null;
                })
              }
              {open && !_.isEmpty(powerMeters) && powerMeters.map((powerMeter: any) =>
                !powerMeter[1] ? null : <PowerMeterItem key={`group-${itemId}-powerMeter-${powerMeter[0]}`} id={powerMeter[0]} selectedItem={selectedItem} siteId={siteId} selectItem={selectItem} groupId={itemId} />
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Fragment>
  );

};

export default ControlItem;
