import { CircularProgress, Grid, IconButton, InputLabel, TextField, Tooltip, Typography } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowRight from "@material-ui/icons/ArrowForward";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import { System as sdkSystem } from "coolremote-sdk";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useReducer, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { t } from "ttag";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import ErrorBox from "../../components/WarningBox/ErrorBox";
import Button from "../../cool_widgets/Button";
import { IconSwitchOff, IconSwitchOn } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { MenuSearch as Search } from "../../svgComponents";
import ParamsTable from "./ParamsTable";
import PropsFilter from "./PropsFilter";
import useStyles from "./SystemDiagnostics.style";
const AUTOUPDATE_INTERVAL = 1000 * 15; // one minute / 4

interface IProps {
  children: React.ReactElement;
  open: boolean;
  value: any;
}
function ValueLabelComponent(props: IProps) {
  const classes = useStyles();
  const { children, open, value } = props;

  return (
    <Tooltip
      arrow
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
      classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
    >
      {children}
    </Tooltip>
  );
}

const paramsReducer = (state: any, action: any) => {
  switch (action.type) {
    case "all":
      return {
        indoors: action.indoors,
        outdoors: action.outdoors,
        bsBoxes: action.bsBoxes,
        indoorsObj: action.indoorsObj,
        outdoorsObj: action.outdoorsObj,
        bsBoxesObj: action.bsBoxesObj
      };

    case "selectUnSelect": {
      const { code, value, flag, paramsType, updateAPI, systemId } = action;
      const paramsObj = {...state[paramsType + "Obj"]};
      let paramsOrder = [...state[paramsType]];
      let count = 0;

      if (code === "all" && flag === "showInList") {
        Object.values(paramsObj).forEach((param: any) => {
          param.showInList = value;
          if (value && count < 9) {
            param.showInTable = true;
            count++;
          } else {
            param.showInTable = false;
          }
        });

        paramsOrder = paramsOrder.map((param: any) => {
          const code = param[0] + "";
          if (!value) {
            return [code, false, false];
          }
          const paramObj = paramsObj[code];
          return [code, paramObj ? paramObj.showInTable : false, true];
        });

        updateAPI({ type: paramsType, systemId, paramsOrder });
        return { ...state, [paramsType + "Obj"]: paramsObj, [paramsType]: paramsOrder };
      } else if (flag === "showInList" && code) {

        if (!value) {
          paramsObj[code].showInList = false;
          paramsObj[code].showInTable = false;
          for (let param of paramsOrder) {
            if (param[0] === code + "") {
              param[1] = false;
              param[2] = false;
              break;
            }
          }
        } else {

          Object.values(paramsObj).forEach(({ showInTable }: any) => {
            if (showInTable) {
              count++;
            }
          });

          paramsObj[code].showInList = true;
          if (count > 8) {
            paramsObj[code].showInTable = false;
          } else {
            paramsObj[code].showInTable = true;
          }

          for (let param of paramsOrder) {
            if (param[0] === code + "") {
              param[1] = count > 8 ? false : true;
              param[2] = true;
              break;
            }
          }
        }

        updateAPI({ type: paramsType, systemId, paramsOrder });
        return { ...state, [paramsType + "Obj"]: paramsObj, [paramsType]: paramsOrder };

      } else if (flag === "showInTable" && code) {
        if (!value) {
          paramsObj[code].showInTable = false;
          for (let param of paramsOrder) {
            if (param[0] === code + "") {
              param[1] = false;
              break;
            }
          }
        } else {
          //check if already 12 params are selected if yes do nothing
          Object.values(paramsObj).forEach(({ showInTable }: any) => {
            if (showInTable) {
              count++;
            }
          });

          if (count < 12) {
            paramsObj[code].showInTable = true;
            for (let param of paramsOrder) {
              if (param[0] === code + "") {
                param[1] = true;
                break;
              }
            }
          } else {
            return state;
          }
        }

        updateAPI({ type: paramsType, systemId, paramsOrder });
        return { ...state, [paramsType + "Obj"]: paramsObj, [paramsType]: paramsOrder };
      }
      return state;
    }

    case "reorder":
      {
        const { paramsType, updateAPI, systemId, paramsOrder = [] } = action;
        updateAPI({ type: paramsType, systemId, paramsOrder });
        return { ...state, [paramsType]: paramsOrder };
      }
    default:
      return state;
  }
};

const SystemDiagnostics: React.FC = (props: any) => {
  const classes = useStyles();
  const match = useRouteMatch<{ systemId: string }>();
  const history = useHistory();
  const defaultSystemId = match.params.systemId;
  const user = useStoreState((s) => s.users.me);
  const isInitialized = useStoreState((s) => s.isInitialized);
  const selections = useStoreState((s) => s.selections.selections);
  const allSystems = useStoreState((s) => s.systems.allSystems);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const allDevices = useStoreState((s) => s.devices.allDevices);
  const allSites = useStoreState((state) => state.sites.allSites);
  const types = useStoreState((state) => state.types);
  const setUnitUpdateStatus = useStoreActions((action) => action.setUnitUpdateStatus);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const serviceParams = useStoreState((s) => s.serviceParams);
  const serviceParamTypes = useStoreState((s) => s.serviceParamTypes);
  const displayFlagsMap = useStoreState((state) => state.users.displayFlagsMap);
  const { customerId } = selections;
  const displayFlags = displayFlagsMap[customerId || ""] || {};
  const getSite = useStoreState((s) => s.sites.getSite);
  const { dateFormat, timeFormat } = useStoreState((state) => state.users);
  const getUserStorePreferences = useStoreActions((a) => a.users.getUserStorePreferences);
  const updateUserSystemPreferences = useStoreActions((a) => a.users.updateUserSystemPreferences);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const setSelections = useStoreActions((s) => s.selections.setSelections);
  const sitesFlags = useStoreState((s) => s.sites.sitesFlags);
  const { siteId } = selections;

  const [isDisabled, setIsDisabled] = useState(true);

  const [time, setTime] = useState<any>({
    start: moment(new Date(new Date().setHours(0, 0, 0))).valueOf(),
    end: moment.now()
  });
  const [selectedTime, setSelectedTime] = useState<any>(null);
  const [indoorData, setIndoorData] = useState<any>({});
  const [outdoorData, setOutdoorData] = useState<any>({});
  const [bsData, setBsData] = useState<any>({});
  const [isLast, setIsLast] = useState<boolean>(false);
  const [allObj, setAllObj] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [autoupdate, setAutoupdate] = useState<boolean>(false);
  const [resume, handleResume] = useState<boolean>(false);
  const [activateAutoupdate, handleActivateAutoupdate] = useState<boolean>(false);
  const [deactivateAutoupdate, handleDeactivateAutoupdate] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const [callTimer, setCallTimer] = useState<any>(null);

  const [systemParams, setSystemParams] = useReducer(paramsReducer, { indoors: [], outdoors: [], bsBoxes: [], indoorsObj: {}, outdoorsObj: {}, bsBoxesObj: {} });

  const { temperatureScale: userTempScale = 1, measurementUnits: userPressureScale = 2 } = user;
  const timezone = getSite(selections.siteId)?.timezone || moment.tz.guess();
  const selectedSystemId = selections?.systemId;
  const system: any = selectedSystemId && allSystems[selectedSystemId];
  const device = selectedSystemId && system?.device && allDevices[system.device];
  const { unitTypes } = types;
  const deviceRegDate = device?.registrationDate || new Date("1-1-1900");

  const timeLimit = sitesFlags[siteId || ""]?.numOfHourLimitSysDiag || 0;
  const pastTimeAllowed = new Date().getTime() - (timeLimit * 60 * 60 * 1000);

  useEffect(() => {
    const currDate = new Date();
    let date = selections.dateRange?.endDate || currDate;
    const allowedPastTime = currDate.getTime() - (timeLimit * 60 * 60 * 1000);
    date = !!timeLimit && (date.getTime() < allowedPastTime) ? new Date(allowedPastTime) : date;
    updateSelections({ type: "time", data: { endDate: date, startDate: date } });
  }, [siteId]);

  useEffect(() => {
    if (!defaultSystemId) {
      setIsDisabled(false);
      return;
    }

    if (allSystems[defaultSystemId]) {
      fetchData(selections.dateRange, defaultSystemId);
    }
  }, [userTempScale, userPressureScale]);

  const onDateChange = (range: any) => {
    fetchData(range, selectedSystemId);
  };

  useEffect(() => {
    if (selections?.dateRange?.endDate && new Date(selections?.dateRange?.endDate) < new Date(deviceRegDate)) {
      setSelections({
        dateRange: {
          startDate: new Date(new Date().setHours(0, 0, 0)),
          endDate: new Date()
        }
      });
    }
  }, [deviceRegDate]);

  useEffect(() => {
    if (defaultSystemId && defaultSystemId !== selections.systemId) {
      updateSelections({ type: "system", data: defaultSystemId });
      return;
    }

    if (!defaultSystemId && selections.systemId) {
      history.push(`/system-diagnostics/${selections.systemId}`);
      return;
    }
  }, [defaultSystemId]);

  useEffect(() => {
    history.push(`/system-diagnostics/${selections.systemId ? selections.systemId : ""}`);
  }, [selections.systemId]);

  const getParamsOrder = (userParamsOrder: any, typeParamsKeys: any) => {
    let newParamsOrder: any = [];
    const paramsObject: any = {};
    let count = 0;

    if (typeParamsKeys.length === 0) {
      return { newParamsOrder: [], paramsObject: {} };
    }

    if (!userParamsOrder) {
      const showInList = true;
      let showInTable = true;
      typeParamsKeys.forEach((key: string) => {
        const serviceParam = serviceParams[key];
        if (!serviceParam) {
          return;
        }
        const { showInGraph = true, showInSysDiag = true, isCustomParam = false, code } = serviceParam;
        if (!showInSysDiag) {
          return;
        }
        const measurementUnits: string = serviceParam
          ? serviceParam.data_unit_of_measurement
          : "";

        const name: string = serviceParam ? serviceParam.title : "";

        let enumData = null;
        if (serviceParam?.enum) {
          const typeKey = serviceParam?.enum;
          enumData = serviceParamTypes[typeKey];
        }

        if (showInTable && count === 9) {
          showInTable = false;
        }
        count < 9 && count++;

        newParamsOrder.push([key, showInTable, showInList]);
        paramsObject[key] = {
          code,
          name,
          // value: paramsMap[code].value,
          showInGraph,
          measurementUnits,
          enumData,
          showInList,
          showInTable,
          techInfo: serviceParam?.techInfo || null,
          isCustomParam
        };
      });

    } else {
      typeParamsKeys.forEach((key: string) => {
        const serviceParam = serviceParams[key];
        if (!serviceParam) {
          return;
        }
        const { showInGraph = true, showInSysDiag = true, isCustomParam = false, code } = serviceParam;
        if (!showInSysDiag) {
          return;
        }
        const measurementUnits: string = serviceParam
          ? serviceParam.data_unit_of_measurement
          : "";

        const name: string = serviceParam ? serviceParam.title : "";

        let enumData = null;
        if (serviceParam?.enum) {
          const typeKey = serviceParam?.enum;
          enumData = serviceParamTypes[typeKey];
        }

        // newParamsOrder.push([key, showInTable, showInList])
        paramsObject[key] = {
          code,
          name,
          // value: paramsMap[code].value,
          showInGraph,
          measurementUnits,
          enumData,
          showInList: false,
          showInTable: false,
          techInfo: serviceParam?.techInfo || null,
          isCustomParam
        };

      });
      const filteredUserParams: any = [];
      const uniqueCodes: any = {};

      userParamsOrder.forEach((item: any) => {
        const [code, showInTable, showInList] = item;
        const serviceParam = serviceParams[code];
        if (!serviceParam) {
          return;
        }
        const { showInGraph = true, showInSysDiag = true, isCustomParam = false } = serviceParam;
        if (!showInSysDiag) {
          return;
        }

        if (paramsObject[code]) {
          paramsObject[code].showInTable = showInTable;
          paramsObject[code].showInList = showInList;
        }

        uniqueCodes[code] = true;
        filteredUserParams.push(item);
      });

      const leftParams: any = [];
      Object.values(paramsObject).forEach(({ code, showInTable, showInList }: any) => {
        if (uniqueCodes[code]) {
          return;
        }
        leftParams.push([code, showInTable, showInList]);
      });

      newParamsOrder = [...filteredUserParams, ...leftParams];
    }

    return { newParamsOrder, paramsObject };
  };

  const fetchData = (range: any, selectedSystemId: any, noLoader: boolean = false) => {
    if (!selectedSystemId) return;
    !noLoader && setIsDisabled(true);

    const site = allSystems[selectedSystemId]?.site || "";
    const timeLimit = sitesFlags[site]?.numOfHourLimitSysDiag || 0;
    const currDate = new Date();
    let date = range?.endDate || currDate;
    const allowedPastTime = currDate.getTime() - (timeLimit * 60 * 60 * 1000);
    date = !!timeLimit && (date.getTime() < allowedPastTime) ? new Date(allowedPastTime) : date;
    const startDate = !!timeLimit && (new Date(date.setHours(0, 0, 0, 0)).getTime()) < allowedPastTime ? new Date(allowedPastTime) : "";

    const isToday = moment(moment(date).valueOf()).isSame(moment().valueOf(), "day");
    const currentHourMins = moment().tz(timezone).valueOf();
    const start = startDate ? moment.tz(startDate, timezone).valueOf() : moment.tz(date, timezone).startOf("day").valueOf();
    const end = !isToday ? moment.tz(date, timezone).endOf("day").valueOf() : currentHourMins; //Date.UTC(selection s.dateRange?.endDate.getFullYear(), selections.dateRange?.endDate.getMonth(), selections.dateRange?.endDate.getDate(), isToday ? +currentHourMinsArray[0] : 23, isToday ? +currentHourMinsArray[1] : 59) - utcOffset;

    let showDeactivateMsg = false;
    if (!isToday && autoupdate) {
      setAutoupdate(false);
      setCounter(0);
      showDeactivateMsg = true;
    }

    sdkSystem.getSystemDiagData(selectedSystemId, start, end)
      .then((resp: any) => {
        const { indoors = {}, outdoors = {}, bsBoxes = {} } = resp;
        let indoorParams: any = {};
        let outdoorParams: any = {};
        let bsBoxParams: any = {};

        Object.values(indoors).forEach((unit: any, index: number) => {
          const { ranges } = unit;
          indoorParams = { ...indoorParams, ...ranges };
        });

        Object.values(outdoors).forEach((unit: any) => {
          const { ranges } = unit;
          outdoorParams = { ...outdoorParams, ...ranges };
        });

        Object.values(bsBoxes).forEach((unit: any, index: number) => {
          const { ranges } = unit;
          bsBoxParams = { ...bsBoxParams, ...ranges };
        });

        const userPref: any = getUserStorePreferences();
        const userPrefObj: any = userPref?.serviceRecentSystemParamsO;

        let systemUserPref = userPrefObj && userPrefObj[selectedSystemId] || {};
        const { indoors: userIndoor, outdoors: userOut, bsBoxes: userBs } = systemUserPref;

        const { newParamsOrder: indoorsOrder, paramsObject: indoorParamsObj } = getParamsOrder(userIndoor, Object.keys(indoorParams));
        const { newParamsOrder: outdoorsOrder, paramsObject: outdoorParamsObj } = getParamsOrder(userOut, Object.keys(outdoorParams));
        const { newParamsOrder: bsBoxesOrder, paramsObject: bsBoxParamsObj } = getParamsOrder(userBs, Object.keys(bsBoxParams));

        setSystemParams({
          type: "all",
          indoors: indoorsOrder,
          outdoors: outdoorsOrder,
          bsBoxes: bsBoxesOrder,
          indoorsObj: indoorParamsObj,
          outdoorsObj: outdoorParamsObj,
          bsBoxesObj: bsBoxParamsObj
        });

        setIndoorData(indoors);
        setOutdoorData(outdoors);
        setBsData(bsBoxes);

        setSelectedTime(end);
        setTime({ start, end });
      })
      .catch((err: any) => addMessage({ message: err.message }))
      .finally(() => {
        setIsDisabled(false);
        if (showDeactivateMsg) {
          handleDeactivateAutoupdate(true);
        }
        if (autoupdate) {
          if (counter === 29) {
            setAutoupdate(false);
            handleResume(true);
            setCounter(0);
          } else {
            setCounter(counter + 1);
          }
        }
      });
  };

  useEffect(() => {

    if (!allSites || !allSystems || !allUnits || allObj.length) {
      return;
    }

    const sites = Object.values(allSites).map((site: any) => {
      return {
        name: site.name,
        label: site.name,
        type: "site",
        id: site.id
      };
    });

    const systems = Object.values(allSystems).map((system: any) => {
      const siteName = allSites[system.site]?.name;
      return {
        name: system.name,
        label: `${siteName} - ${system.name}`,
        type: "system",
        id: system.id
      };
    });
    const units: any = [];
    Object.values(allUnits).forEach((unit: any) => {
      if (unit.type === unitTypes.indoor) {
        return;
      }
      const siteName = allSites[unit.site]?.name;
      const systemName = allSystems[unit.system]?.name || t`Unassigned`;
      units.push({
        name: unit.name,
        label: `${siteName} - ${systemName} - ${unit.name}`,
        type: "unit",
        id: unit.id
      });
    });

    setAllObj([...sites, ...systems, ...units]);

  }, [allUnits]);

  setUnitUpdateStatus({ status: "" });

  const valuetext = (value: number) => {
    const timeToShow = moment(value).tz(timezone).format(timeFormat);
    return `${timeToShow}`;
  },
    valueDateText = (value: number) => {
      const timeToShow = moment(value).tz(timezone).format(`${dateFormat} ${timeFormat}`);
      return `${timeToShow}`;
    },
    valueLabelFormat = (value: number) => {
      const timeToShow = moment(value).tz(timezone).format(timeFormat);
      return `${timeToShow}`;
    },
    changeSelectedTime = (event: React.ChangeEvent<{}>, value: any) => {
      const isToday = moment(moment(selections?.dateRange?.endDate).valueOf()).isSame(moment().valueOf(), "day");
      if (autoupdate) {
        handleDeactivateAutoupdate(true);
        setAutoupdate(false);
        setCounter(0);
      } else if (value === time.end && isToday) {
        handleActivateAutoupdate(true);
      }
      setSelectedTime(value as number);
    },
    setPrevMinute = async () => {
      if (autoupdate) {
        handleDeactivateAutoupdate(true);
        setAutoupdate(false);
        setCounter(0);
      }

      setSelectedTime(
        moment(selectedTime)
          .add(-1, "minute")
          .valueOf()
      );
    },
    setNextMinute = async () => {
      const isToday = moment(moment(selections?.dateRange?.endDate).valueOf()).isSame(moment().valueOf(), "day");
      if (moment(selectedTime)
        .add(1, "minute")
        .valueOf() === time.end && !autoupdate && isToday) {
        handleActivateAutoupdate(true);
      }

      setSelectedTime(
        moment(selectedTime)
          .add(1, "minute")
          .valueOf()
      );
    };

  const setLastUpdate = (e: any = "") => {
    e && e.preventDefault();
    const activeupdate = autoupdate;
    setAutoupdate(!autoupdate);
    const isToday = moment(moment(selections?.dateRange?.endDate).valueOf()).isSame(moment().valueOf(), "day");
    let d1 = new Date();
    d1.setMinutes(d1.getMinutes() - 3);

    if (!isToday && !activeupdate) {
      const dateRange = {
        endDate: d1,
        startDate: new Date(d1.setHours(0, 0, 0))
      };
      fetchData(dateRange, selectedSystemId, true);
      updateSelections({ type: "time", data: dateRange });
      return;
    }
    const currentMins = moment(d1.getTime()).format("hh:mm");
    const selectedMins = moment(selectedTime).format("hh:mm");

    if (isToday && !activeupdate && currentMins !== selectedMins) {
      const dateRange = {
        endDate: new Date(),
        startDate: new Date(d1.setHours(0, 0, 0))
      };
      fetchData(dateRange, selectedSystemId, true);
      updateSelections({ type: "time", data: dateRange });
    }
  };

  const onRefresh = () => {
    if (!selectedSystemId) {
      return;
    }
    if (moment(selectedTime).format("hh:mm") !== moment(new Date().getTime()).format("hh:mm")) {
      const dateRange = {
        endDate: new Date(),
        startDate: new Date(new Date().setHours(0, 0, 0))
      };
      fetchData(dateRange, selectedSystemId, true);
      updateSelections({ type: "time", data: dateRange });
    }
  };

  const startAutoUpdate = () => {
    if (autoupdate && !callTimer) {
      setCallTimer(
        setTimeout(() => {
          onRefresh();
          clearTimeout(callTimer);
          setCallTimer(null);
        }, AUTOUPDATE_INTERVAL)
      );
    }
  };

  useEffect(() => {
    if (autoupdate) {
      startAutoUpdate();
    } else {
      clearTimeout(callTimer);
      setCallTimer(null);
    }
  }, [autoupdate, callTimer]);

  const searchComponent = (
    <Autocomplete
      id="combo-box-demo"
      options={allObj}
      forcePopupIcon={false}
      getOptionLabel={(option) => option.label || ""}
      groupBy={(option) => option.type}
      value={""}
      inputValue={searchTerm}
      classes={{
        clearIndicator: classes.searchIcon,
        popupIndicator: classes.searchIcon,
        popper: classes.autoCompletePoper,
        paper: classes.autoCompletePaper,
        listbox: classes.autoCompleteItem,
        noOptions: classes.autoCompleteItem,
        groupLabel: classes.autoCompleteGroup,
        groupUl: classes.autoCompleteGroupUl

      }}
      onInputChange={(e: any, val: any) => {
        setSearchTerm(val);
      }}
      renderInput={(params) => <TextField {...params} placeholder={t`Search...`} InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot }, ...params.InputProps, endAdornment: <Search /> }} />}
      onChange={(e: any, val: any) => {
        setSearchTerm("");
        if (!val) {
          return;
        }
        updateSelections({ type: val.type, data: val.id });
      }}
    />
  );

  if (!isInitialized) {
    return <Loading />;
  }

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          onSystemSelect={(id: any) => {
            fetchData(selections.dateRange, id);
            history.push(`/system-diagnostics/${id}`);
          }}
          onSiteSelect={() => history.push(`/system-diagnostics/`)}
          hideUnitSelection
          showOneDatePicker
          disableOneDatePicker={!displayFlags.enableSystemDiagnosticsDatepicker}
          customGeneralNames={{ system: t`Select System` }}
          searchComponent={searchComponent}
          minDate={!!timeLimit ? pastTimeAllowed > deviceRegDate ? new Date(pastTimeAllowed) : new Date(deviceRegDate) : new Date(deviceRegDate)}
          screenTitle="systemDiag"
          passSelectedDate={onDateChange}
        />
        {!selectedSystemId ?
          <FilterRequire type={t`system`} />
          :
          system && (!_.isEmpty(outdoorData) || !_.isEmpty(bsData) || !_.isEmpty(indoorData)) ?
            <>
              <Grid container direction="row" alignItems="stretch" className={classes.headerContainer}>
                <div style={{ flexGrow: 0, maxWidth: "16.666667%", flexBasis: "16.666667%" }}></div>
                {!isDisabled ? <Grid container direction="row" alignItems="stretch" className={classes.sliderContainer}>
                  <IconButton
                    disableRipple
                    style={{ marginRight: "16px" }}
                    onClick={setPrevMinute}
                    className={clsx(classes.iconBtnStyle, { [classes.hidden]: time.start === selectedTime })}
                  >
                    <ArrowBack />
                  </IconButton>
                  <Typography>{valueDateText(time.start)}</Typography>
                  <Grid item className={classes.slider}>
                    <Slider
                      onChangeCommitted={changeSelectedTime}
                      defaultValue={selectedTime}
                      getAriaValueText={valuetext}
                      aria-labelledby="discrete-slider-always"
                      min={time.start}
                      max={time.end}
                      value={selectedTime}
                      valueLabelDisplay="on"
                      valueLabelFormat={valueLabelFormat}
                      ValueLabelComponent={ValueLabelComponent}
                    />
                  </Grid>
                  <Typography>{valueDateText(time.end)}</Typography>
                  <IconButton
                    disableRipple
                    onClick={setNextMinute}
                    style={{ marginLeft: "1rem" }}
                    className={clsx(classes.iconBtnStyle, { [classes.hidden]: moment(selectedTime).format("hh:mm") === moment(time.end).format("hh:mm") })}
                  >
                    <ArrowRight />
                  </IconButton>

                </Grid> : <></>}
                {!isDisabled &&
                  <InputLabel className={classes.autoLabel}>
                    {t`Auto Update`}
                    <IconButton disableRipple onClick={setLastUpdate} className={classes.switchIconBtn}>
                      {autoupdate ? <IconSwitchOn className={classes.switchIcon} /> : <IconSwitchOff className={classes.switchIcon} />}
                    </IconButton>
                  </InputLabel>}
              </Grid>
              {isDisabled ? (
                <div className={classes.progress}>
                  <div className={classes.progressContainer}>
                    <div className={clsx(classes.processingAnimationContainer)}>
                      <CircularProgress />
                    </div>
                    <Typography variant="h5">{t`Loading Data`}</Typography>
                  </div>
                </div>
              ) :
                (
                  <Grid container justifyContent="flex-start" alignItems="stretch" className={classes.wrapper}>
                    <Grid item xs={2} className={classes.filterContainer}>
                      {!_.isEmpty(outdoorData) &&
                        <Grid item className={clsx(classes.filterOutdoorGridItem, !_.isEmpty(bsData) ? "" : classes.filterContainerBs)}>
                          <PropsFilter
                            systemId={selectedSystemId}
                            title={t`Outdoor Parameters`}
                            type={"outdoors"}
                            orderedParams={systemParams.outdoors}
                            paramsObj={systemParams.outdoorsObj}
                            setSystemParams={setSystemParams}
                            updateUserSystemPreferences={updateUserSystemPreferences}
                          />
                        </Grid>
                      }
                      {!_.isEmpty(bsData) &&
                        <Grid item className={classes.filterBsGridItem}>
                          <PropsFilter
                            systemId={selectedSystemId}
                            title={t`BS Parameters`}
                            type={"bsBoxes"}
                            orderedParams={systemParams.bsBoxes}
                            paramsObj={systemParams.bsBoxesObj}
                            setSystemParams={setSystemParams}
                            updateUserSystemPreferences={updateUserSystemPreferences}
                          />
                        </Grid>
                      }
                      {!_.isEmpty(indoorData) &&
                        <Grid item className={clsx(classes.filterGridItem, !_.isEmpty(bsData) ? "" : classes.filterGridItemBs)}>
                          <PropsFilter
                            systemId={selectedSystemId}
                            title={t`Indoor Parameters`}
                            type={"indoors"}
                            orderedParams={systemParams.indoors}
                            paramsObj={systemParams.indoorsObj}
                            setSystemParams={setSystemParams}
                            updateUserSystemPreferences={updateUserSystemPreferences}
                          />
                        </Grid>
                      }
                    </Grid>
                    <Grid item xs={10} className={classes.tableWrapper}>
                      {!_.isEmpty(outdoorData) &&
                        <Grid item className={clsx(classes.tableOutdoorGridItem, !_.isEmpty(bsData) ? classes.tableOutdoorGridItemBs : "")}>
                          <ParamsTable
                            title={"Outdoor Dashboard"}
                            paramsData={outdoorData}
                            selectedTime={selectedTime}
                            isLast={isLast}
                            setIsLast={setIsLast}
                            orderedParams={systemParams.outdoors}
                            paramsObj={systemParams.outdoorsObj}
                          />
                        </Grid>}

                      {!_.isEmpty(bsData) &&
                        <Grid item className={classes.tableBsGridItem}>
                          <ParamsTable
                            title={"BS Box Dashboard"}
                            paramsData={bsData}
                            selectedTime={selectedTime}
                            isLast={isLast}
                            setIsLast={setIsLast}
                            orderedParams={systemParams.bsBoxes}
                            paramsObj={systemParams.bsBoxesObj}
                          />
                        </Grid>
                      }
                      {!_.isEmpty(indoorData) &&
                        <Grid item className={clsx(classes.tableGridItem, !_.isEmpty(bsData) ? "" : classes.filterGridItemBs)}>
                          <ParamsTable
                            title={"Indoor Dashboard"}
                            paramsData={indoorData}
                            selectedTime={selectedTime}
                            isLast={isLast}
                            setIsLast={setIsLast}
                            orderedParams={systemParams.indoors}
                            paramsObj={systemParams.indoorsObj}
                          />
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                )}
            </> :
            <Grid container direction={"column"} className={classes.noContentContainer}>
              <Typography className={classes.noUnitText}>
                {t`No info about system`}
              </Typography>
            </Grid>
        }

      </div>
      {resume && <ErrorBox
        title={t`Confirm`}
        error={t`Auto Update has stopped. Do you want to proceed?`}
        onAccept={() => { handleResume(false); setLastUpdate(); }}
        onClose={() => handleResume(false)}
        acceptTitle={t`Yes`}
        cancelTitle={t`No`}
      />
      }
      {activateAutoupdate && <ErrorBox
        title={t`Confirm`}
        error={t`Do you want to move to Auto Refresh to latest Data mode?`}
        onAccept={() => { handleActivateAutoupdate(false); setLastUpdate(); }}
        onClose={() => handleActivateAutoupdate(false)}
        acceptTitle={t`Yes`}
        cancelTitle={t`No`}
      />
      }
      {deactivateAutoupdate && <ErrorBox
        title={t`Note`}
        error={t`Note you are moving from Auto Refresh to latest Data to viewing specific time. You can change back using the toggle to the right`}
        onAccept={() => handleDeactivateAutoupdate(false)}
        acceptTitle={t`OK`}
      />
      }
    </div>
  );
};

export default SystemDiagnostics;
