import { makeStyles } from "@material-ui/core";

export default makeStyles((theme: any) => ({
  permissionsBox: {
    marginTop: "20px",
    borderTop: `1px solid #D9D9D9`,
    padding: "10px 0",
    display: "flex",
    flex: 1,
    overflow: "auto",
    flexDirection: "column"
  },
  root: {
    height: 110,
    flexGrow: 1,
    maxWidth: "100%"
  },
  itemRow: {
    width: "99%",
    height: "50px",
    backgroundColor: "#FCFCFC",
    display: "flex",
    justifyContent: "space-between",
    margin: "5px 0",
    border: "1px solid #F1F1F1",
    borderRadius: "6px",
    padding: "0 20px",
    alignItems: "center",
    "& p": {
      display: "flex",
      alignItems: "center"
    }
  },
  itemName: {
    maxWidth: "30%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  nameContainer: {
    display: "block !important"
  },
  subName: {
    color: "#7DC1FF"
  },
  treeItemLabel: {
    "&:hover": {
      backgroundColor: "transparent"
    },
    backgroundColor: "transparent !important"
  },
  rowControls: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "30%",
    height: "100%"
  },
  selectRoot: {
    height: "75%",
    "&>div": {
      width: "130px"
    }
  },
  chkboxRoot: {
    color: theme.palette.primary.main
  },
  diabledChkbox: {
    color: "#736973"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    padding: "0 10px"
  },
  headerTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "bold"
  },
  treeItemRoot: {
    backgroundColor: "transparent !important",
    "&:focus": {
      backgroundColor: "transparent"
    },
    "&:hover": {
      backgroundColor: "transparent"
    }
  }
}));
