import {
  Button,
  Card,
  CardActions,
  Dialog,
  Grid,
  Slider,
  TextField,
  Typography
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import clsx from "clsx";
import coolremoteSDK from "coolremote-sdk";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { t } from "ttag";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import { modeIcons } from "../../constants/modes";
import scheduletypes from "../../constants/scheduletypes";
import StyledButton from "../../cool_widgets/Button";
import { CoolSwitch } from "../../cool_widgets/CoolSwitch";
import { Plus } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IUnit } from "../../models/Units";
import assetsService from "../../services/assetsService";
import NewApplySettings from "./ApplyToTree";
import ScheduleIUnitItem from "./ScheduleIUnitItem";
import useStyles from "./Settings.style";
import SwitchItem from "./SwitchItem";

function Alert(text: any) {
  return <MuiAlert elevation={6} variant="filled" {...text} />;
}

const Settings: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const isInitialized = useStoreState((s) => s.isInitialized);
  const temperatureScale = useStoreState((state) => state.users.me.temperatureScale);
  const selections = useStoreState((s) => s.selections.selections);
  const [selectedUnit, setSelectedUnit] = useState<IUnit | null>(null);
  const [unitsToSave, setUnitsToSave] = useState<any>([]);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const setEWrcLock = useStoreActions((a) => a.units.setEWrcLock);
  const toggleEWrcLock = useStoreActions((a) => a.units.toggleEWrcLock);
  const allDevices = useStoreState((state) => state.devices.allDevices);
  const allSites = useStoreState((state) => state.sites.allSites);
  const [indoorUnits, setIndoorUnits] = useState<any>([]);
  const types = useStoreState((states) => states.types);
  const getCustomerSchedules = useStoreActions((a) => a.schedules.getCustomerSchedules);
  const deleteSchedule = useStoreActions((actions) => actions.schedules.deleteSchedule);
  const updateSchedule = useStoreActions((actions) => actions.schedules.updateSchedule);
  const createScheduleAPI = useStoreActions((actions) => actions.schedules.createObjectSchedules);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const { unitTypes } = types;

  const [useTemperatureScale, setUseTemperatureScale] = useState(temperatureScale);
  const [helperMessgae, setHelperMessgae] = useState<string>("");
  const [supportedValues, setSupportedValues] = useState<any>({});
  const [disableAllSettings, setDisableAllSettings] = useState<boolean>(false);
  const [tempLimitFlags, setTempLimitFlags] = useState<any>({
    enableCoolMode: false,
    enableHeatMode: false,
    enableAutoMode: false
  });
  const [tStatFlags, setTStatFlags] = useState<any>({
    enableOnoff: false,
    enableMode: false,
    enableSetpoint: false
  });
  const [createNewSchedule, setCreateNewSchedule] = useState<any>(false);
  const [selectedSchedule, setSelectedSchedule] = useState<any>(null);
  const [allCustomerSchedules, setSllCustomerSchedules] = useState<any>({});
  const [isEWrcDisabled, setIsEWrcDisabled] = useState<boolean>(false);
  const { startLoader, finishLoader } = useStoreActions((action) => action.loader);
  const updateUnitsAndSchedules = useStoreActions((action) => action.units.updateUnitsAndSchedules);
  const [open, setOpen] = React.useState(false);
  const updateStoreUnit = useStoreActions(
    (a) => a.units._storeUpdateUnit
  );
  const { subType = 0, capabilityFlags = {}, permissions = {} } = selectedUnit || {};
  const { canUpdate, canUpdateSchedules, canCreateSchedules, canDeleteSchedules } = permissions;

  const memoSideMenu = useCallback(() => sideMenu(), [selections, unitsToSave, disableAllSettings, subType]);
  const {
    enableWrcSettingsOnOffLock = false, enableWrcSettingsSetpointChangeLock = false,
    enableWrcSettingsModeLock = false, enableWrcSettingsLimitCoolingSetpoint = false,
    enableWrcSettingsLimitHeatingSetpoint = false, enableWrcSettingsRestrictionsLock = false
  } = capabilityFlags;

  useEffect(() => {
    setUnitsToSave([]);
  }, [selections.unitId]);

  const getSchedules = () => {
    getCustomerSchedules({ id: selections.customerId, type: scheduletypes.eDeviceLimits }).then((res: any) => {
      setSllCustomerSchedules(res);
    });
  };

  useEffect(() => {
    if (!selections.customerId) {
      return;
    }
    getSchedules();
  }, [selections.customerId, selectedUnit]);

  useEffect(() => {
    const filterType = 1;

    const indoorUnitsOnly = Object.values(allUnits).filter((unit: any) => {
      const unitSystemId = unit.system;
      const unitDevice = allDevices[unit.device];
      if (_.isUndefined(unitDevice)) { return false; }
      const unitSite = allSites[unitDevice.site];
      if (_.isUndefined(unitSite)) { return false; }
      const unitCustomerId = unitSite.customer;
      if (_.isUndefined(unitCustomerId)) { return false; }

      return (
        unit.type === filterType &&
        (selections.customerId ? unitCustomerId === selections.customerId : true) &&
        (selections.siteId ? unitSite.id === selections.siteId : true) &&
        (selections.systemId ? unitSystemId === selections.systemId : true)
      );
    });

    if (!_.isEqual(indoorUnits, indoorUnitsOnly)) {
      setIndoorUnits(indoorUnitsOnly);
      setUseTemperatureScale(temperatureScale);
    }
  }, [allUnits, selections.siteId, selections.customerId, temperatureScale]);

  useEffect(() => {
    if (selections.unitId) {
      coolremoteSDK.Unit.getUnitById(selections.unitId)
        .then((res: any) => {

          if (res?.type === unitTypes.indoor) {
            setSelectedUnit(res);
          }
          else {
            if (res?.controlUnit?.length) {
              return coolremoteSDK.Unit.getUnitById(res?.controlUnit)
            }
          }

        })
        .then((controlUnitRes: any) => {
          if (controlUnitRes) {
            setSelectedUnit(controlUnitRes);
          }
        })
        .catch((err: any) => addMessage({ message: err.message }));
    }
  }, [selections.unitId]);

  useEffect(() => {
    const values: any = {};
    const unit = _.cloneDeep(selectedUnit);

    if (unit) {
      values.temperatureLimits = (unit.eWrcTemperatureLimits ? unit.eWrcTemperatureLimits : unit.temperatureLimits) || {};
      setTempLimitFlags({
        enableCoolMode: unit.eWrcEnableCoolMode || false,
        enableHeatMode: unit.eWrcEnableHeatMode || false,
        enableAutoMode: unit.eWrcEnableAutoMode || false
      });
      setTStatFlags({
        enableOnoff: unit.eWrcEnableOnoff,
        enableMode: unit.eWrcEnableMode,
        enableSetpoint: unit.eWrcEnableSetpoint
      });
      setTempLimitFlags({
        enableCoolMode: unit.eWrcEnableCoolMode || false,
        enableHeatMode: unit.eWrcEnableHeatMode || false,
        enableAutoMode: unit.eWrcEnableAutoMode || false
      });
      setIsEWrcDisabled(unit.eWrcDisable || false);
    }

    setSupportedValues((prev: any) => ({ ...prev, ...values }));
  }, [selectedUnit]);

  // Update tStatLock when it changes
  useEffect(() => {
    if (selectedUnit) {
      setTStatFlags({
        enableOnoff: selectedUnit.eWrcEnableOnoff,
        enableMode: selectedUnit.eWrcEnableMode,
        enableSetpoint: selectedUnit.eWrcEnableSetpoint
      });
    }

  }, [
    selectedUnit && selectedUnit.eWrcEnableOnoff, selectedUnit && selectedUnit.eWrcEnableMode,
    selectedUnit && selectedUnit.eWrcEnableSetpoint
  ]);

  // Update tempLimits flags when it changes
  useEffect(() => {
    if (selectedUnit) {
      setTempLimitFlags({
        enableCoolMode: selectedUnit.eWrcEnableCoolMode,
        enableHeatMode: selectedUnit.eWrcEnableHeatMode,
        enableAutoMode: selectedUnit.eWrcEnableAutoMode
      });
    }

  }, [selectedUnit && selectedUnit.eWrcEnableCoolMode, selectedUnit && selectedUnit.eWrcEnableHeatMode, selectedUnit && selectedUnit.eWrcEnableAutoMode]);

  useEffect(() => {
    if (!selections.unitId) {
      return;
    }

    const unit = _.find(indoorUnits, (unit) => unit.id === selections.unitId);
    if (!unit) {
      setDisableAllSettings(false);
      setHelperMessgae("");
      return;
    }
    const unitDevice = allDevices[unit.device];
    if (unitDevice && unitDevice.protocolVersion < 4) {
      setDisableAllSettings(true);
      setHelperMessgae(t`You need to upgrade your device firmware to use this feature`);

    } else if (unitDevice && unitDevice.protocolVersion >= 4) {
      if (unitDevice && !unitDevice.isConnected) {
        setDisableAllSettings(true);
        setHelperMessgae(t`Device is disconnected`);
      } else {
        setDisableAllSettings(false);
        setHelperMessgae("");
      }

    }
  }, [selections.unitId, indoorUnits, allDevices]);

  const valueLabelFormat = (value: number) => {
    const valueToShow = value + (useTemperatureScale === 1 ? " °C" : " °F");
    return `${valueToShow}`;
  };

  if (!isInitialized) { return <Loading />; }

  const handleSetCooling = (event: any, newValue: number | number[]) => {
    const locValues: any = { ...supportedValues };
    locValues.temperatureLimits[0] = newValue;
    setSupportedValues(locValues);
  };
  const handleSetHeat = (event: any, newValue: number | number[]) => {
    const locValues: any = { ...supportedValues };
    locValues.temperatureLimits[1] = newValue;
    setSupportedValues(locValues);
  };

  const handleSetTempLimitRange = (flagName: string) => {
    const nextFlagState = _.clone(tempLimitFlags);
    nextFlagState[flagName] = !nextFlagState[flagName];
    setTempLimitFlags(nextFlagState);
  };

  const handleAgree = (units: any) => {
    setUnitsToSave(units);
  };
  const handleSave = (unitId: any, multi: boolean = false) => {
    const data: any = {
      units: !multi ? [unitId] : unitsToSave,
      eWrcTemperatureLimits: supportedValues.temperatureLimits,
      eWrcEnableOnoff: tStatFlags.enableOnoff || false,
      eWrcEnableMode: tStatFlags.enableMode || false,
      eWrcEnableSetpoint: tStatFlags.enableSetpoint || false,
      eWrcEnableCoolMode: tempLimitFlags.enableCoolMode || false,
      eWrcEnableHeatMode: tempLimitFlags.enableHeatMode || false,
      site: selections.siteId
    };

    startLoader();
    if (multi) {
      data.sourceUnit = unitId;
      updateUnitsAndSchedules({ updatedData: data })
        .finally(() => {
          finishLoader();
        });
      for (let i = 0; i < unitsToSave.length; i++) {
        updateStoreUnit({
          id: unitsToSave[i],
          data
        });
      }

      return;
    }

    delete data.site;
    delete data.units;
    setEWrcLock({ id: unitId, data }).then(
      setOpen(true));
    updateStoreUnit({
      id: unitId,
      data
    });
    finishLoader();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    const values: any = {};
    const unit = allUnits[selectedUnit?.id || ""];
    if (unit) {
      values.temperatureLimits = unit.eWrcTemperatureLimits ? unit.eWrcTemperatureLimits : unit.temperatureLimits;

      setTStatFlags({
        enableOnoff: unit.eWrcEnableOnoff,
        enableMode: unit.eWrcEnableMode,
        enableSetpoint: unit.eWrcEnableSetpoint
      });

      setTempLimitFlags({
        enableCoolMode: unit.eWrcEnableCoolMode || false,
        enableHeatMode: unit.eWrcEnableHeatMode || false,
        enableAutoMode: unit.eWrcEnableAutoMode || false
      });
      setSupportedValues(values);
    }

  };

  const noUnitSelect = () => {
    return <FilterRequire type={t`unit`} />;
  };
  const sideMenu = () => {
    return <NewApplySettings
      save={handleAgree}
      units={unitsToSave}
      noPopup={true}
      oneCustomer={{ id: selections.customerId }}
      oneSite={selections.siteId}
      allTenants={[]}
      tenantId="9999"
      noDisable={true}
      useControlUnits={true}
      disabled={disableAllSettings}
      wrc
      subType={subType}
      withSearch={true}
    />;
  };

  const convertToWrcParams = (data: any) => {
    if (enableWrcSettingsOnOffLock) {
      data.eWrcEnableOnoff = data.enableOnoff;
    }
    if (enableWrcSettingsModeLock) {
      data.eWrcEnableMode = data.enableMode;
    }
    if (enableWrcSettingsSetpointChangeLock) {
      data.eWrcEnableSetpoint = data.enableSetpoint;

    }
    if (enableWrcSettingsLimitCoolingSetpoint) {
      data.eWrcEnableCoolMode = data.enableCoolMode;
      data.eWrcTemperatureLimits = data.temperatureLimits;
    }

    if (enableWrcSettingsLimitHeatingSetpoint) {
      data.eWrcEnableHeatMode = data.enableHeatMode;
      data.eWrcTemperatureLimits = data.temperatureLimits;
    }

    delete data.temperatureLimits;
    delete data.enableOnoff;
    delete data.enableMode;
    delete data.enableSetpoint;
    delete data.enableCoolMode;
    delete data.enableHeatMode;
    delete data.enableAutoMode;
  };

  const saveSchedule = async (data: any) => {
    data.type = scheduletypes.eDeviceLimits;
    convertToWrcParams(data);
    await createScheduleAPI({
      data,
      objId: selectedUnit ? selectedUnit.id : "",
      objectType: "unit"
    });
    setSelectedSchedule(null);
    getSchedules();
  };

  const onUpdateSchedule = async (data: any) => {
    // data.type = scheduletypes.eDeviceLimits;
    convertToWrcParams(data);
    await updateSchedule({ id: selectedSchedule.id, data });
    setSelectedSchedule(null);
    getSchedules();
  };

  const onUpdateDisableState = async (data: any) => {

    data.type = scheduletypes.unitLimits;
    await updateSchedule({ id: data.id, data: { isDisabled: data.isDisabled } });
  };

  const onDelete = async (idObj: any) => {
    await deleteSchedule(idObj.id);
    getSchedules();
  };

  const onToggleAllLocks = () => {
    toggleEWrcLock({
      units: [selections.unitId],
      isEWrcDisable: !isEWrcDisabled
    });

    setIsEWrcDisabled(!isEWrcDisabled);
  };

  const schedules = () => {
    const unitSchedules = _.reduce(allCustomerSchedules, (arr: any[], item: any) => {
      if (item.unit === selections.unitId) {
        arr.push(item);
      }

      return arr;
    }, []);

    return (
      <Grid item xs={12} className={classes.scheduleWrapper}>

        <Dialog
          onClose={_.noop}
          aria-labelledby="simple-dialog-title"
          open={createNewSchedule}
          maxWidth="lg"
        >
          <ScheduleIUnitItem
            capabilityFlags={capabilityFlags}
            selectedSchedule={selectedSchedule}
            onSave={selectedSchedule ? onUpdateSchedule : saveSchedule}
            onCancle={() => {
              setCreateNewSchedule(!createNewSchedule);
              setSelectedSchedule(null);
            }}
            canEdit={true}
            canEditScheule={canUpdateSchedules}
            canDelete={canDeleteSchedules}
            idDeviceLimit={true}
            wrcLimits={true}
            title={!selectedSchedule ? "Add Schedule " : "Edit Schedule"}
            useTemperatureScale={useTemperatureScale}
          />
        </Dialog>

        <Card
          className={clsx(classes.modeContent, classes.modeContentUnitSettings, classes.scheduleContainer)}
        >
          <Grid item xs={12} className={classes.newScheduleBtn} >
            <Typography className={classes.topTitle}>{t`Schedules`}</Typography>
            <Button
              disableRipple
              disableElevation
              disabled={disableAllSettings || isEWrcDisabled || !canCreateSchedules}
              onClick={() => setCreateNewSchedule(!createNewSchedule)}
              variant="contained"
              className={classes.cancelActionBtn}
              startIcon={<Plus className={classes.plusIcon} />}
            >{t`Add Schedule`}

            </Button>
          </Grid>
          <Grid
            item xs={12}
            className={classes.schedules}
          >
            {_.map(unitSchedules, (schedule) => {

              return (
                <ScheduleIUnitItem
                  capabilityFlags={capabilityFlags}
                  selectedSchedule={schedule}
                  onSave={updateSchedule}
                  onCancle={() => setCreateNewSchedule(!createNewSchedule)}
                  canEdit={false}
                  canEditScheule={canUpdateSchedules}
                  canDelete={canDeleteSchedules}
                  key={schedule.id}
                  onDelete={onDelete}
                  onEditAction={() => {
                    setCreateNewSchedule(!createNewSchedule);
                    setSelectedSchedule(schedule);
                  }}
                  onDisableUpdate={onUpdateDisableState}
                  idDeviceLimit={true}
                  wrcLimits={true}
                  useTemperatureScale={useTemperatureScale}
                  disableAllSettings={disableAllSettings}
                />
              );
            })}
          </Grid>
        </Card>
      </Grid>
    );
  };

  const disabledView = () => {
    return (
      <div
        className={classes.disabledView}
      >
        <Typography className={classes.title}>
          {t`Restrictions Are Disabled`}</Typography>
      </div>

    );

  };

  const updateTstatFlags = (key: string, value: boolean) => setTStatFlags({ ...tStatFlags, [key]: value });

  const unitWrcLocks = () => {
    return (<div className={classes.tStatContainer}>
      {enableWrcSettingsOnOffLock &&
        <SwitchItem
          disabled={disableAllSettings || !canUpdate}
          onChange={(value: boolean) => updateTstatFlags("enableOnoff", !value)}
          value={!tStatFlags.enableOnoff}
          title={t`Restrict On/OFF`}
        />}
      {enableWrcSettingsModeLock &&
        <SwitchItem
          disabled={disableAllSettings || !canUpdate}
          onChange={(value: boolean) => updateTstatFlags("enableMode", !value)}
          value={!tStatFlags.enableMode}
          title={t`Restrict Mode setting`}
        />}
      {enableWrcSettingsSetpointChangeLock &&
        <SwitchItem
          disabled={disableAllSettings || !canUpdate}
          onChange={(value: boolean) => updateTstatFlags("enableSetpoint", !value)}
          value={!tStatFlags.enableSetpoint}
          title={t`Restrict Setpoint Change`}
        />}
    </div>
    );
  };

  const unitWrcTempLimits = () => {
    return (
      <div
        className={classes.modesContainer}
      >
        {enableWrcSettingsLimitCoolingSetpoint &&
          <Card
            className={clsx(classes.modeContainer, classes.wrcModeContainer)}
          >
            <div className={classes.modeHeader}>
              <div className={classes.modeTitle}>
                <img className={classes.modeImg}
                  alt=""
                  src={assetsService.getImagesPath(`${modeIcons[0]}.svg`)}
                ></img>
                <Typography className={classes.title}>
                  {t`WRC Limit cooling setpoint range`}</Typography>
              </div>
              <div className={classes.modeSlider}>
                <CoolSwitch
                  disabled={disableAllSettings || !canUpdate}
                  checked={tempLimitFlags.enableCoolMode}
                  onChange={() =>
                    handleSetTempLimitRange("enableCoolMode")
                  }
                />
              </div>
            </div>
            {tempLimitFlags.enableCoolMode &&
              <div className={classes.sliderAndLabelContainer}>
                <Typography className={classes.leftLabelForamt}>{valueLabelFormat(supportedValues?.temperatureLimits[0][0])}</Typography>
                <Slider
                  onChange={handleSetCooling}
                  className={classes.coolSlider}
                  aria-labelledby="discrete-slider-always"
                  min={useTemperatureScale === 1 ? 0 : 32}
                  max={useTemperatureScale === 1 ? 50 : 122}
                  value={supportedValues.temperatureLimits[0]}
                  valueLabelDisplay="off"
                  disabled={disableAllSettings || !tempLimitFlags.enableCoolMode || !canUpdate}
                />
                <Typography className={classes.rightLabelForamt}>{valueLabelFormat(supportedValues?.temperatureLimits[0][1])}</Typography>
              </div>
            }

          </Card>}
        {enableWrcSettingsLimitHeatingSetpoint &&
          <Card
            className={clsx(classes.modeContainer, classes.wrcModeContainer)}
          >
            <div className={classes.modeHeader}>
              <div className={classes.modeTitle}>
                <img
                  alt=""
                  src={assetsService.getImagesPath(`${modeIcons[1]}.svg`)}
                  className={classes.modeImg}
                >
                </img>
                <Typography className={classes.title}>
                  {t`WRC Limit heating setpoint range`}</Typography>
              </div>
              <div className={classes.modeSlider}>
                <CoolSwitch
                  disabled={disableAllSettings || !canUpdate}
                  checked={tempLimitFlags.enableHeatMode}
                  onChange={() =>
                    handleSetTempLimitRange("enableHeatMode")
                  }
                />
              </div>
            </div>
            {tempLimitFlags.enableHeatMode &&
              <div className={classes.sliderAndLabelContainer}>
                <Typography className={classes.leftLabelForamt}>{valueLabelFormat(supportedValues.temperatureLimits[1][0])}</Typography>
                <Slider
                  onChange={handleSetHeat}
                  className={classes.heatSlider}
                  aria-labelledby="discrete-slider-always"
                  min={useTemperatureScale === 1 ? 0 : 32}
                  max={useTemperatureScale === 1 ? 50 : 122}
                  value={supportedValues.temperatureLimits[1]}
                  valueLabelDisplay="off"
                  disabled={disableAllSettings || !tempLimitFlags.enableHeatMode || !canUpdate}
                />
                <Typography className={classes.rightLabelForamt}>{valueLabelFormat(supportedValues.temperatureLimits[1][1])}</Typography>
              </div>

            }
          </Card>
        }
      </div>
    );
  };

  const unitSelected = () => {
    const canSee = true;

    return (
      <Grid
        container
        className={classes.container}
        spacing={3}
      >
        <Grid item xs={9} className={classes.gridLeft} >

          <Card
            className={clsx(classes.modeContent, classes.modeContentUnitSettings)}
          >

            {supportedValues.temperatureLimits && selectedUnit &&
              <>

                <div className={clsx(classes.settingsHeaderContainer)}>
                  <div className={classes.settingsHeaderTitles} >
                    <Typography className={clsx(classes.topTitle, classes.cutLongNames)}>{selectedUnit && selectedUnit.name}</Typography>
                    <Typography className={classes.errorTitle}>{disableAllSettings ? helperMessgae : ""}</Typography>
                  </div>

                  {enableWrcSettingsRestrictionsLock &&
                    <Card className={classes.eWrcDisableContainer}>
                      <CoolSwitch
                        checked={!isEWrcDisabled}
                        onChange={() => {
                          onToggleAllLocks();
                        }}
                        disabled={disableAllSettings || !canUpdate}
                      />
                      <Typography className={classes.switchTitle}>{t`Enable Restrictions`}</Typography>
                    </Card>}
                </div>

                {isEWrcDisabled && disabledView()}
                {!isEWrcDisabled && unitWrcLocks()}
                {!isEWrcDisabled && unitWrcTempLimits()}

              </>
            }
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Successfully saved!
              </Alert>
            </Snackbar>

            {!isEWrcDisabled &&
              <CardActions className={classes.unitActions}>
                <div className={clsx(classes.saveBtn, classes.saveLocksBtn)}>
                  <StyledButton
                    disableElevation
                    disabled={disableAllSettings || !canUpdate}
                    onClick={() => handleSave(selectedUnit?.id)}
                    variant="contained"
                    className={clsx(classes.saveActionBtn, disableAllSettings && classes.disabledSaveBtn)}
                  >{t`SAVE`}
                  </StyledButton>
                </div>
                <div className={classes.saveBtn}>
                  <StyledButton
                    white
                    disableElevation
                    disabled={disableAllSettings || !canUpdate} onClick={() => handleCancel()} variant="contained"
                    className={classes.cancelActionBtn}>{t`CANCEL`}
                  </StyledButton>
                </div>
              </CardActions>
            }
          </Card>
          {canSee && !isEWrcDisabled && supportedValues.temperatureLimits && selectedUnit && schedules()}
        </Grid>
        {canSee &&
          <Grid item xs={3}>
            <Card
              className={classes.sideMenuContent}
            >
              <div className={classes.headerApplyContainer}>
                <Typography variant="h6" className={classes.headerTitle}>
                  {t`Copy Settings To`}
                </Typography>
              </div>
              <Grid className={classes.unitsContainer}>
                {memoSideMenu()}
              </Grid>
              <div className={classes.saveBtn}>
                <Button
                  disableRipple
                  disableElevation
                  onClick={() => handleSave(selectedUnit?.id, true)}
                  disabled={disableAllSettings || !unitsToSave.length}
                  variant="contained"
                  color="primary"
                  className={classes.applyBtn}
                >{t`Apply`}
                </Button>
              </div>
            </Card>
          </Grid>
        }
      </Grid>
    );
  };

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          hideSystemSelection={true} disableSystemUpdate={true}
          path={[t`Restrict Local Controls`]}
          hideOutdoor
          hideBsBox
          hideIndoor
          hideOther
          screenTitle="wrc"
          countControlUnits
          customGeneralNames={{ unit: t`Select Unit` }}
        />
        {selections.unitId && unitSelected()}
        {!selections.unitId && noUnitSelect()}
      </div>
    </div >
  );
};

export default Settings;
