import {
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../cool_widgets/Button";
import { Close, EditUser as SvgUser } from "../../icons/";
import { useStoreState } from "../../models/RootStore";
import { SearchIcon } from "../../svgComponents";
import useStyles from "./AlertGroup2.style";

export interface IAlertGroupType {
  id: number;
  name: string;
  users: string[];
  enable: boolean;
  customer: string;
}

const AlertGroup: React.FC<any> = (props: any) => {

  const {
    close,
    editAlertGroup: alertGroup,
    saveAlert,
    allTraps,
    allSites,
    automationRules,
    siteId,
    userContacts,
    setSelectedAlertGroup,
    setAnchorEl,
    setSettingContactsProps
  } = props;
  const allSitesById = allSites?.reduce((acc: any, cur: any) => ({ ...acc, [cur.id]: cur }), {});
  const isEdit = alertGroup && !alertGroup.new ? true : false;

  const types = useStoreState((s) => s.types);
  const { trapPriorityTypes, alertGroupTypes } = types;

  const [name, setName] = useState(alertGroup ? alertGroup.name : "");
  const [error, setError] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [globalAlertsEnable, setGlobalAlertsEnable] = useState<any>({});
  const [allUserTrapsEnable, setAllUsersTrapsEnable] = useState(alertGroup ? !!alertGroup.allUserTrapsEnable : false);
  const [allLogicTrapsEnable, setAllLogicTrapsEnable] = useState(alertGroup ? !!alertGroup.allLogicTrapsEnable : false);
  const [selectedSitestoCopy, setSelectedSitestoCopy] = useState<any>([]);
  const [selectedTrapPriorityType, setSelectedTrapPriorityType] = useState<number>(1);
  const [notifyBy, setNotifyBy] = useState<string>("1");
  const [specificAlertTypeSelected, setSpecificAlertTypeSelected] = useState<string>("System Error");
  const [specificAlertSearchValue, setSpecificAlertSearchValue] = useState<string>("");
  const [specificAlertTypesMap, setSpecificAlertTypesMap] = useState<any>({});
  const [tempSpecificAlertTypesMap, setTempSpecificAlertTypesMap] = useState<any>({});
  const specificAlertTypes = ["System Error", "Indoor Error", "Anomalies", "Maintenance", "Communication", "Automation Logic"];
  const [showAllAlertsToAdd, setShowAllAlertsToAdd] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    setTempSpecificAlertTypesMap(_.cloneDeep(specificAlertTypesMap));
  }, [alertGroup, specificAlertTypesMap, specificAlertTypeSelected]);
  useEffect(() => {
    const indoorErrors: any = {
      indoorUnitErrorEnable: globalAlertsEnable?.indoorUnitErrorEnable
    };
    const maintenance: any = {
      scheduleFailuresEnable: globalAlertsEnable?.scheduleFailuresEnable,
      cleanFilterEnable: globalAlertsEnable?.cleanFilterEnable,
      lowLineQualityEnable: globalAlertsEnable?.lowLineQualityEnable
    };
    const communication: any = {
      deviceDisconnectedEnable: globalAlertsEnable?.deviceDisconnectedEnable,
      unitDisconnectedEnable: globalAlertsEnable?.unitDisconnectedEnable,
      sensorDisconnectedEnable: globalAlertsEnable?.sensorDisconnectedEnable,
      powerMeterDisconnectedEnable: globalAlertsEnable?.powerMeterDisconnectedEnable
    };
    const systemErrors: any = {
      outdoorUnitErrorEnable: globalAlertsEnable?.outdoorUnitErrorEnable
    };
    const newMap = {
      "System Error": { ...systemErrors },
      // "System Error": globalAlertsEnable,
      "Indoor Error": { ...indoorErrors },
      "Anomalies": allTraps,
      "Maintenance": { ...maintenance },
      "Communication": { ...communication },
      "Automation Logic": automationRules
    };

    setSpecificAlertTypesMap(newMap);
    setTempSpecificAlertTypesMap(_.cloneDeep(newMap));

  }, [globalAlertsEnable]);

  useEffect(() => {
    const globalAlerts = {
      deviceDisconnectedEnable: { isEnabled: !!alertGroup?.deviceDisconnectedEnable, title: t`Device Disconnect` },
      cleanFilterEnable: { isEnabled: !!alertGroup?.cleanFilterEnable, title: t`Clean Filter alerts (No SMS)` },
      indoorUnitErrorEnable: { isEnabled: !!alertGroup?.indoorUnitErrorEnable, title: t`Unit indoor alerts` },
      outdoorUnitErrorEnable: { isEnabled: !!alertGroup?.outdoorUnitErrorEnable, title: t`Unit Outdoor alerts` },
      unitDisconnectedEnable: { isEnabled: !!alertGroup?.unitDisconnectedEnable, title: t`Unit Disconnected` },
      lowLineQualityEnable: { isEnabled: !!alertGroup?.lowLineQualityEnable, title: t`Line Quality alerts` },
      scheduleFailuresEnable: { isEnabled: !!alertGroup?.scheduleFailuresEnable, title: t`Schedule Failures` },
      sensorDisconnectedEnable: { isEnabled: !!alertGroup?.sensorDisconnectedEnable, title: t`Sensor Disconnected` },
      powerMeterDisconnectedEnable: { isEnabled: !!alertGroup?.powerMeterDisconnectedEnable, title: t`Power Meter Disconnected` }
    };

    setSelectedTrapPriorityType(alertGroup?.priority || alertGroup?.priorities?.[0] || 2);
    setNotifyBy((alertGroup?.type || alertGroupTypes.regular) + "");
    setGlobalAlertsEnable(globalAlerts);
  }, []);

  const handleClose = () => {
    setSpecificAlertSearchValue("");
    setAllUsersTrapsEnable(alertGroup ? alertGroup.allUserTrapsEnable : false);
    setName(alertGroup ? alertGroup.name : "");
    close();
  };

  const handleAgree = () => {
    const b = Object.values(specificAlertTypesMap).map((rules: any) => Object.values(rules).findIndex((rule: any) => rule.isEnabled) !== -1).findIndex((x: boolean) => x) !== -1;

    const trapsIds = Object.keys(specificAlertTypesMap["Anomalies"]).filter((trapId: string) => specificAlertTypesMap["Anomalies"][trapId]?.isEnabled);
    const automationRulesIds = Object.keys(specificAlertTypesMap["Automation Logic"]).filter((ruleId: string) => specificAlertTypesMap["Automation Logic"][ruleId]?.isEnabled);

    if (!name) {
      setError(t`Required`);
      return;
    }
    if (+notifyBy === alertGroupTypes.regular && !b && !allLogicTrapsEnable && !allUserTrapsEnable) {
      setErrorMessage("At least one alert, anomaly or automation logic is required");
      return;
    }

    setError("");
    setErrorMessage("");

    const enablesObject: any = Object.entries({
      ...specificAlertTypesMap["System Error"],
      ...specificAlertTypesMap["Indoor Error"],
      ...specificAlertTypesMap["Maintenance"],
      ...specificAlertTypesMap["Communication"]
    }).reduce((acc: any, [key, curVal]: any) => {
      acc[key] = !!curVal?.isEnabled;
      return acc;
    }, {});

    const data = +notifyBy === alertGroupTypes.regular ? {
      name,
      enablesObject,
      traps: trapsIds,
      allUserTrapsEnable,
      logicTraps: automationRulesIds,
      allLogicTrapsEnable
    }
      : { priority: selectedTrapPriorityType };

    isEdit
      ? saveAlert({
        id: alertGroup.id,
        name,
        ...data
      })
      : saveAlert({
        name,
        enable: true,
        site: siteId,
        type: +notifyBy,
        ...data
      });
    close();
  };
  const allAlertsIsSelected = !_.isEmpty(tempSpecificAlertTypesMap[specificAlertTypeSelected]) && Object.values(tempSpecificAlertTypesMap[specificAlertTypeSelected] || {}).every((alert: any) => alert?.isEnabled);
  const noAlertsIsSelected = !_.isEmpty(tempSpecificAlertTypesMap[specificAlertTypeSelected]) && Object.values(tempSpecificAlertTypesMap[specificAlertTypeSelected] || {}).every((alert: any) => !alert?.isEnabled);

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        // maxWidth="md"
        fullWidth
      >
        <div className={classes.dialogHeader}>
          <Typography className={classes.headerTitle}>
            {isEdit ? t`Edit Alert Group` : t`Add New Alert Group`}
          </Typography>
          <IconButton disableRipple onClick={handleClose} className={classes.iconBtnStyle}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <div className={classes.dialogContent}>
          <div className={classes.firstRowContainer}>
            <Grid>
              <TextField
                variant="outlined"
                placeholder="Enter Group Name"
                id={"input-alert- group-name"}
                value={name}
                onChange={(e: any) => {
                  setName(e.target.value);
                }}
                error={!!error}
                helperText={error}
                className={classes.txtField}
              />
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                <Typography style={{ marginRight: 8 }}> Apply to: </Typography>
                <Select
                  style={{ height: 30 }}
                  className={classes.selectRoot}
                  classes={{ selectMenu: classes.selectMenu }}
                  multiple
                  value={selectedSitestoCopy}
                  onChange={(a: any) => { setSelectedSitestoCopy([...a?.target?.value]); }}
                  renderValue={(selected: any) => selected.map((siteId: string) => {
                    const site = allSitesById[siteId];
                    return site.name + " " + ((!site?.hvacAdvancedOperations && !site?.predictiveMaintenance && !site?.basicRemoteDiagnostics) ? t` (No SMS)` : "");
                  })
                    .join(", ")}
                  displayEmpty
                  variant="outlined"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                >
                  {
                    allSites.map((site: any) => <MenuItem
                      key={site.id}
                      value={site.id}
                    >
                      <Checkbox
                        color="default"
                        checked={selectedSitestoCopy.includes(site.id)} />
                      <ListItemText primary={site.name + " " + ((!site?.hvacAdvancedOperations && !site?.predictiveMaintenance && !site?.basicRemoteDiagnostics) ? t` (No SMS)` : "")} />
                    </MenuItem>)
                  }
                </Select>
              </div> */}
            </Grid>
            <div className={classes.contactsTooltip}>
              {!!alertGroup && <LightTooltip placement="top" arrow title={alertGroup.contacts?.map((contact: any, index: number) => {
                return <p key={index}>
                  <span> {userContacts?.[contact?.contact]?.firstName} {userContacts?.[contact?.contact]?.lastName} </span>
                  {contact?.sendSms && <span> SMS </span>}
                  {contact?.sendEmail && <span> Email </span>}
                </p>;
              })}>
                <div className={classes.contactsTooltip}>
                  {alertGroup.contacts?.map((contact: any, index: number) => (index < 2 && alertGroup.contacts?.length > 3 || alertGroup.contacts?.length < 4) && <p key={index} className={classes.contactIcon}>{userContacts?.[contact?.contact]?.firstName?.toUpperCase()?.[0]}</p>)}
                  {alertGroup.contacts?.length > 3 && (<p className={classes.contactIcon}>+{alertGroup.contacts?.length - 2}</p>)}
                </div>
              </LightTooltip>}
              <SvgUser onClick={(event: any) => {
                setSelectedAlertGroup(alertGroup);
                setAnchorEl(event.currentTarget);
                setSettingContactsProps({ open: true, x: event.clientX, y: event.clientY });
              }}
                className={classes.svgUserIcon}
              />
            </div>
          </div>
          <hr />
          <Grid >
            <Typography> Send notifications by </Typography>
            <FormControl component="fieldset">
              <RadioGroup value={notifyBy} onChange={(event: any) => setNotifyBy(event.target.value)}>
                <FormControlLabel
                  value={"2"}
                  // value="priority"
                  control={<Radio color="default" disabled={isEdit} />}
                  label="Alert Priority" />
                <div className={classes.selectContainer} >
                  <Select
                    disabled={+notifyBy === alertGroupTypes.regular}
                    className={classes.selectRoot}
                    classes={{ selectMenu: classes.selectMenu }}
                    value={selectedTrapPriorityType}
                    onChange={(e: any) => setSelectedTrapPriorityType(e?.target?.value)}
                    displayEmpty
                    variant="outlined"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    {Object.entries(trapPriorityTypes).map(([key, value]: any) => <MenuItem key={key} value={key}>{value}</MenuItem>)}
                  </Select>

                  <Typography style={{ marginLeft: 8, color: +notifyBy === alertGroupTypes.regular ? "#bbb" : "#888" }}> and above </Typography>
                </div>
                <FormControlLabel
                  // value="regular"
                  value={"1"}
                  control={<Radio color="default" disabled={isEdit} />}
                  label="Specific Alerts" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid container className={+notifyBy !== alertGroupTypes.regular ? classes.disabledDiv : ""} >
            <Grid item xs={3} >
              <List component="nav" className={classes.alertsTypesList}>
                {specificAlertTypes.map((title, i) => <ListItem
                  selected={specificAlertTypeSelected === title}
                  value={title}
                  className={classes.alertsTitlesList}
                  key={i}
                  onClick={(event: any) => { setSpecificAlertTypeSelected(title); setShowAllAlertsToAdd(false); }}
                >
                  <ListItemText key={i} primary={`${title} (${Object.keys(specificAlertTypesMap[title] || {}).filter((key) => specificAlertTypesMap[title]?.[key]?.isEnabled).length})`} />
                </ListItem>)}
              </List>

            </Grid>
            {!showAllAlertsToAdd ? <Grid item xs={9} className={classes.errorsListContainer}>
              <TextField
                placeholder="Search.."
                variant="outlined"
                fullWidth
                value={specificAlertSearchValue}
                onChange={(a: any) => setSpecificAlertSearchValue(a?.target?.value || "")}
                className={classes.searchInput}
                InputProps={{
                  classes: { input: classes.inputStyle },
                  endAdornment: <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }}
              />
              <Link href="#" className={noAlertsIsSelected ? classes.centerLink : classes.link} onClick={() => setShowAllAlertsToAdd(true)}>
                {noAlertsIsSelected ? (t`Click to add ` + specificAlertTypeSelected) : t`Edit Alerts`}
              </Link>
              <List component="nav" className={classes.errorsList}>
                {Object.entries(specificAlertTypesMap[specificAlertTypeSelected] || {}).map(([key, value = {}]: any) => !value.isEnabled || !(value.title || value.name)?.toLocaleLowerCase().includes(specificAlertSearchValue.toLocaleLowerCase()) ? null : <ListItem
                  className={classes.errorListItem}
                  value={(value.title || value.name)}
                  key={key}
                >
                  <ListItemText key={key} primary={(value.title || value.name)} />
                  <IconButton disableRipple onClick={() => {
                    const newMap = _.cloneDeep(specificAlertTypesMap);
                    newMap[specificAlertTypeSelected][key].isEnabled = false;
                    setSpecificAlertTypesMap(newMap);

                    const newTempMap = _.cloneDeep(tempSpecificAlertTypesMap);
                    newTempMap[specificAlertTypeSelected][key].isEnabled = false;
                    setTempSpecificAlertTypesMap(newTempMap);
                  }}
                    className={classes.iconBtnStyle}
                    style={{ transform: "scale(0.5)" }}>
                    <Close color="#7f7692" />
                  </IconButton>
                </ListItem>)}
              </List>
            </Grid>
              : <Grid item xs={9} className={classes.errorsListContainer}>
                <TextField
                  placeholder="Search.."
                  variant="outlined"
                  fullWidth
                  value={specificAlertSearchValue}
                  onChange={(a: any) => setSpecificAlertSearchValue(a?.target?.value || "")}
                  className={classes.searchInput}
                  InputProps={{
                    classes: { input: classes.inputStyle },
                    endAdornment: <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }}
                />
                <Link
                  href="#"
                  className={classes.link}
                  onClick={() => {
                    const newTemp = Object.keys(tempSpecificAlertTypesMap[specificAlertTypeSelected] || {}).reduce((acc: any, key: string) => {
                      acc[key].isEnabled = !allAlertsIsSelected;
                      return acc;
                    }, _.cloneDeep({ ...tempSpecificAlertTypesMap[specificAlertTypeSelected] || {} }));
                    setTempSpecificAlertTypesMap({ ...tempSpecificAlertTypesMap, [specificAlertTypeSelected]: newTemp });
                  }}>
                  {allAlertsIsSelected ? t`Unselect All` : t`Select All`}
                </Link>
                <List component="nav" className={classes.errorsList}>
                  {Object.entries(tempSpecificAlertTypesMap[specificAlertTypeSelected] || {}).map(([key, value]: any) => !(value.title || value.name)?.toLocaleLowerCase().includes(specificAlertSearchValue.toLocaleLowerCase()) ? null : <ListItem
                    className={classes.errorListItem}
                    value={(value.title || value.name)}
                    key={key}
                  >
                    <Checkbox
                      color="default"
                      checked={value.isEnabled}
                      onChange={(_e, isEnabled) => {
                        const newMap = _.cloneDeep(tempSpecificAlertTypesMap);
                        newMap[specificAlertTypeSelected][key].isEnabled = isEnabled;
                        setTempSpecificAlertTypesMap(newMap);
                      }}
                      inputProps={{ "aria-labelledby": (value.title || value.name) }}
                      classes={{ root: classes.inputCheckbox }}
                    />
                    <ListItemText key={key} secondary={(value.title || value.name)} />
                  </ListItem>)}
                </List>

                <div className={classes.buttonsContainer}>
                  <Button
                    width={80}
                    onClick={() => {
                      setSpecificAlertSearchValue("");
                      setShowAllAlertsToAdd(false);
                    }}
                    white
                    marginRight
                  >
                    Close
                  </Button>
                  <Button
                    width={80}
                    onClick={() => {
                      setSpecificAlertSearchValue("");
                      setSpecificAlertTypesMap(tempSpecificAlertTypesMap);
                      setShowAllAlertsToAdd(false);
                    }}
                  >
                    Add
                  </Button>
                </div>

              </Grid>}
          </Grid>

        </div>
        <div className={classes.actionsHolder}>
          {!!errorMessage && <Typography className={classes.errorMessage}>{errorMessage}</Typography>}
          <Button onClick={handleClose} onMouseUp={handleClose} title={t`Cancel`} width={150} white marginRight>
            {t`Cancel`}
          </Button>
          <Button onClick={handleAgree} onMouseUp={handleAgree} title={t`Save`} width={150}>
            {/* {alertGroup ? t`Update` : t`Add`} */}
            {t`Save`}
          </Button>
        </div>
      </Dialog>
    </div >
  );
};
export default AlertGroup;
