import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core/";
import { FilterList } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { t } from "ttag";
import { Lookup } from "../../components/LookupTableSysDiag";
import Tooltip from "../../components/Tooltip/LightTooltip";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./ParamsTable.style";

const ParamsTable: React.FC<any> = (props: any) => {
    const {
        paramsObj,
        title,
        paramsData,
        selectedTime,
        isLast,
        setIsLast,
        orderedParams
    } = props;
    const classes = useStyles();
    const [clickedHead, setClickedHead] = useState("");
    const user = useStoreState((s) => s.users.me);
    const getUnit = useStoreState((s) => s.units.getUnit);

    const { temperatureScale: userTempScale = 1, measurementUnits: userPressureScale = 2 } = user;
    const [selectedRow, setSelectedRow] = useState<any>("");
    const [units, setUnits] = useState<any>({});
    const [selectedDataIndex, setSelectedDataIndex] = useState<any>({});
    const history = useHistory();
    const updateSelection = useStoreActions((a) => a.selections.updateSelections);
    const [lookupAnchor, setAnchor] = useState(null);
    const updateClickedHead = (event: any, columnHead: string) => {
        setClickedHead(columnHead);
        setAnchor(event.currentTarget);
    };
    const selectUnit = (unitId: string) => {
        updateSelection({ type: "unit", data: unitId });
        history.push(`/unit-stats/${unitId}`);
    };
    const onApply = (unitsMap: any) => {
        setUnits({ ...unitsMap });
        setClickedHead("");
    };
    const get_nearest = (data: any, to_find: any) => {

        let dataIndex = 0;
        for (let index = 0; index < data.length; index++) {
            if (index === 0) {
                continue;
            }

            const currDiff = Math.abs(data[index].timestamp - to_find);
            const prevDiff = Math.abs(data[dataIndex].timestamp - to_find);

            if (currDiff < prevDiff) {
                dataIndex = index;
            }

            if (currDiff > prevDiff) {
                break;
            }

        }

        if (dataIndex === data.length - 1) {
            if (!isLast) {
                setIsLast(true);
            }
        }
        else {
            if (isLast) {
                setIsLast(false);
            }
        }
        return dataIndex;
    };

    useEffect(() => {
        if (!paramsData || _.isEmpty(paramsData)) {
            return;
        }
        const unitsMap = Object.keys(paramsData)?.reduce((acc: any, curr: any) => {
            const unit = getUnit(curr);
            if (!unit) {
                return acc;
            }
            if (!unit.isVisible) {
                return acc;
            }

            let unitName: string = unit.name;

            if (+unit?.type === 3) { // is service unit
                const controlUnitId: any = unit.controlUnit;
                const controlName = getUnit(controlUnitId)?.name || t`Unassigned`;
                unitName = `${controlName} (${unit.address})`;
            }

            acc[curr] = { show: true, name: unitName, id: curr };
            return acc;
        }, {});
        setUnits(unitsMap);
    }, [paramsData]);

    useEffect(() => {
        if (!selectedTime || _.isEmpty(paramsData)) {
            return;
        }

        const date = new Date(selectedTime);
        date.setSeconds(0, 0);
        const selectedTimestamp = date.getTime();
        const selectedUnitsDataIndex: any = {};

        Object.keys(paramsData).forEach((unitId: any) => {
            const { entries = [] } = paramsData[unitId] || {};

            for (let index in entries) {
                const { timestamp } = entries[index]
                const mSelectedTimestamp = moment(selectedTimestamp);
                const mTimeStamp = moment(timestamp);
                const diff = mSelectedTimestamp.diff(mTimeStamp, 'seconds')
                if (diff >= 0 && diff <= 120) {
                    selectedUnitsDataIndex[unitId] = index;
                    break;
                }
            }
        })

        setSelectedDataIndex(selectedUnitsDataIndex);
    }, [selectedTime, paramsData]);

    const getRightUnitMeasurement = (measurementUnit: string) => {

        if (userTempScale === 2 && measurementUnit === "°C") {
            return "°F";
        }
        if (userPressureScale === 2 && measurementUnit === "kg/cm2") {
            return "PSI";
        }
        if (measurementUnit === "MPa" && userPressureScale === 2) {
            return "PSI";
        }
        if (userPressureScale === 1 && measurementUnit === "PSI") {
            return "kg/cm2";
        }
        return measurementUnit;
    };

    const hasFilter = Object.keys(units)?.length !== Object.keys(units)?.filter((id: any) => units[id].show)?.length;
    return (
        <div className={classes.wrapper}>
            <div className={classes.header}>
                <Typography className={classes.title}>{title}</Typography>
            </div>
            {
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="customized table" className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    classes={{ root: classes.unitHeadCell }}
                                    align="left"
                                    onClick={(e: any) => updateClickedHead(e, "units")}
                                >
                                    <div className={classes.headCellUnitContainer}>
                                        <span > {t`Units`}</span>
                                        <FilterList
                                            className={clsx(classes.filterStyle, {
                                                [classes.blueFilter]: hasFilter
                                            })}
                                        />
                                    </div>
                                </TableCell>

                                {orderedParams?.map((item: any, index: any) => {
                                    const [code] = item;
                                    const param = paramsObj[code];
                                    if (!param?.showInTable) {
                                        return null;
                                    }
                                    return (
                                        <Tooltip arrow title={param.techInfo || `${param.name} ${getRightUnitMeasurement(param.measurementUnits || "")}`} placement="top-start" key={`tt-${index}`}>
                                            <TableCell scope="row" align="left"
                                                className={clsx(classes.tableCell, { [classes.maxWidth]: index !== 0 && Object.values(paramsObj).length - 1 === index })}
                                                classes={{ root: classes.tableHeadCell }}
                                                key={param.code}
                                            >
                                                <p className={classes.hiddenTitle}>{param.name}</p>
                                                <p className={classes.headerMultiLine}>{param.name}</p>
                                            </TableCell>
                                        </Tooltip>
                                    );
                                })}

                            </TableRow>
                        </TableHead>
                        {!_.isEmpty(paramsData) ?
                            <TableBody>
                                {
                                    Object.keys(units)?.map((unitId: string, index: number) => {
                                        if (!units[unitId]?.show) {
                                            return null;
                                        }
                                        const selectedIndex = selectedDataIndex[unitId];
                                        const unitData = paramsData[unitId] && paramsData[unitId]["entries"] ? paramsData[unitId]["entries"][selectedIndex] : "-";
                                        const unitName = units[unitId]?.name;

                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={`unit-row-${unitId}`}
                                                style={{ cursor: "pointer" }}
                                                onDoubleClick={() => selectUnit(unitId)}
                                                onClick={() => setSelectedRow(unitId)}
                                                className={unitId === selectedRow ? classes.selectedRowStyle : ""}
                                            >
                                                <TableCell component="th" scope="row" align="left" className={classes.unitCell}>
                                                    {unitName}
                                                </TableCell>

                                                {
                                                    orderedParams?.map((item: any) => {
                                                        const [code] = item;
                                                        const param = paramsObj[code];
                                                        if (!param) {
                                                            return null;
                                                        }
                                                        const { showInTable, enumData } = param;
                                                        const info = unitData && unitData[code] != null ? (enumData ? enumData[unitData[code]] : unitData[code]) : "-";

                                                        if (!showInTable) {
                                                            return null;
                                                        }

                                                        return (
                                                            <TableCell component="th" scope="row" align="left" key={`${unitId}-${code}`} className={classes.tableCell}>
                                                                {info}
                                                            </TableCell>
                                                        );
                                                    })
                                                }
                                            </TableRow>
                                        );
                                    })
                                }

                            </TableBody> : <></>}
                    </Table>
                </TableContainer>
            }
            {
                clickedHead && (
                    <Lookup
                        filtersMap={units}
                        onApply={onApply}
                        lookupAnchor={lookupAnchor}
                        onClose={() => setClickedHead("")}
                        tableHasFilters={hasFilter}
                    />
                )
            }
        </div >
    );
};

export default ParamsTable;
