import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
    dialogPaper: {
        width: "fit-content",
        maxWidth: "95% !important",
        minWidth: "600px",
        minHeight: "485px",
        height: "fit-content",
        maxHeight: "calc(100vh - 140px)",
        borderRadius: "8px",
        boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
        border: "solid 1px #d5d2d5",
        backgroundColor: "#ffffff",
        justifyContent: "space-between",
        overflow: "hidden"
    },
    tableContainer: {
        display: "flex",
        height: "100%"
    },
    table: {
        background: theme.palette.background.paper
    },
    headerTitle: {
        ...theme.dialogs.title
    },
    dialogHeader: {
        paddingLeft: 20,
        paddingRight: 13,
        ...theme.dialogs.header,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 60,
        minHeight: 60
    },
    tableHeadCell: {
        padding: "0 15px",
        height: 45,
        ...theme.tables.headerCell
    },
    overWritePadding: {
        ...theme.tables.tableCell,
        padding: "0 15px",
        height: 45
    },
    overWritePaddingRow: {
        fontSize: "0.8rem",
        padding: "0 15px",
        cursor: "pointer"
    },
    dialogContent: {
        overflow: "hidden",
        display: "flex",
        flexFlow: "column nowrap",
        flex: 1,
        padding: 30,
        justifyContent: "space-between"
    },
    iconBtnStyle: {
        width: 30,
        height: 30,
        borderRadius: 6,
        padding: 0
    },
    actionsHolder: {
        marginTop: 30,
        display: "flex",
        justifyContent: "flex-end"
    },
    arrowDownIcon: {
        top: 15,
        right: 9
    },
    selectStyle: {
        lineHeight: "35px",
        width: "200px",
        height: "35px",
        borderRadius: "4px",
        backgroundColor: "#ffffff",
        "&>div": {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: "32px",
            paddingLeft: "14px"
        }
    },
    hidden: {
        display: "none"
    },
    loaderHolder: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1
    },
    loader: {
        maxWidth: "20px !important",
        maxHeight: "20px !important",
        color: "#AAA2AA"
    }
}));
export default useStyles;
