import { t } from "ttag";
import * as Yup from "yup";

import { useStoreState } from "../../models/RootStore";

export const useValidationSchema = () => {
  const {
    procedureStepTypes,
    stepsRunningModes,
    actionValueSources,
    procedureDeviceCommands
  } = useStoreState((s) => s.types);

  const groupConditionSchema = Yup.object().shape({
    type: Yup.string().required(t`Required`),
    value: Yup.number().when(["operator", "valueSource"], {
      is: (operator, valueSource) => !(+valueSource === actionValueSources.parameter && operator === "="),
      then: Yup.number().required(t`Required`),
      otherwise: Yup.number(),
    }),
    command: Yup.string().when(["type"], {
      is: (type) => +type === procedureStepTypes.command,
      then: Yup.string().required(t`Required`),
      otherwise: Yup.string(),
    }),
    condition: Yup.string().when(["type"], {
      is: (type) => +type === procedureStepTypes.condition,
      then: Yup.string().required(t`Required`),
      otherwise: Yup.string(),
    }),
    operator: Yup.string().when(["type", "valueSource"], {
      is: (type, valueSource) => (
        +type === procedureStepTypes.condition ||
        (+type === procedureStepTypes.command && +valueSource === actionValueSources.parameter)
      ),
      then: Yup.string().required(t`Required`),
      otherwise: Yup.string(),
    }),
    parameter: Yup.string().when(["type", "valueSource"], {
      is: (type, valueSource) => +type === procedureStepTypes.command && +valueSource === actionValueSources.parameter,
      then: Yup.string().required(t`Required`),
      otherwise: Yup.string(),
    }),
    thresholdParameter: Yup.string().when(["operator"], {
      is: (operator) => operator === "threshold",
      then: Yup.string().required(t`Required`),
      otherwise: Yup.string(),
    }),
    thresholdOperator: Yup.string().when(["operator"], {
      is: (operator) => operator === "threshold",
      then: Yup.string().required(t`Required`),
      otherwise: Yup.string(),
    }),
    valueSource: Yup.string().when(["type", "command"], {
      is: (type, command) => +type === procedureStepTypes.command && +command === procedureDeviceCommands.setUnitSetpoint,
      then: Yup.string().required(t`Required`),
      otherwise: Yup.string(),
    })
  });

  const conditionSchema = Yup.object().shape({
    type: Yup.string().required(t`Required`),
    value: Yup.number().when(["operator", "valueSource"], {
      is: (operator, valueSource) => !(+valueSource === actionValueSources.parameter && operator === "="),
      then: Yup.number().required(t`Required`),
      otherwise: Yup.number(),
    }),
    command: Yup.string().when(["type"], {
      is: (type) => +type === procedureStepTypes.command,
      then: Yup.string().required(t`Required`),
      otherwise: Yup.string(),
    }),
    condition: Yup.string().when(["type"], {
      is: (type) => +type === procedureStepTypes.condition,
      then: Yup.string().required(t`Required`),
      otherwise: Yup.string(),
    }),
    operator: Yup.string().when(["type", "valueSource"], {
      is: (type, valueSource) => (
        +type === procedureStepTypes.condition ||
        (+type === procedureStepTypes.command && +valueSource === actionValueSources.parameter)
      ),
      then: Yup.string().required(t`Required`),
      otherwise: Yup.string(),
    }),
    parameter: Yup.string().when(["type", "valueSource"], {
      is: (type, valueSource) => +type === procedureStepTypes.command && +valueSource === actionValueSources.parameter,
      then: Yup.string().required(t`Required`),
      otherwise: Yup.string(),
    }),
    thresholdParameter: Yup.string().when(["operator"], {
      is: (operator) => operator === "threshold",
      then: Yup.string().required(t`Required`),
      otherwise: Yup.string(),
    }),
    thresholdOperator: Yup.string().when(["operator"], {
      is: (operator) => operator === "threshold",
      then: Yup.string().required(t`Required`),
      otherwise: Yup.string(),
    }),
    // @ts-ignore
    selectedEntities: Yup.lazy((value, { parent, context }) => {
      const { type, condition } = parent;
      const { ruleType } = context;
    
      if (
        +type !== procedureStepTypes.wait &&
        +ruleType === stepsRunningModes.specificUnits &&
        condition !== "procedureStatus"
      ) {
        return Yup.array()
          .min(1, t`At least one item is required`)
          .required(t`Required`);
      }
    
      return Yup.array(); // Otherwise, allow an empty array
    }),
    valueSource: Yup.string().when(["type", "command"], {
      is: (type, command) => +type === procedureStepTypes.command && +command === procedureDeviceCommands.setUnitSetpoint,
      then: Yup.string().required(t`Required`),
      otherwise: Yup.string(),
    }),
  });

  const groupSchema = Yup.object().shape({
    isGroup: Yup.boolean(),
    selectedEntities: Yup.array().when(["$ruleType"], {
      is: (ruleType) => +ruleType === stepsRunningModes.specificUnits,
      then: Yup.array()
        .min(1, t`At least one item is required`)
        .required(t`Required`),
      otherwise: Yup.array(),
    }),
    steps: Yup.array().of(groupConditionSchema),
  });

  const schema = Yup.object().shape({
    ruleName: Yup.string().required(t`Required`),
    ruleType: Yup.string().required(t`Required`),
    conditions: Yup.array().of(
      Yup.lazy((value: any): any => {
        if (value.isGroup) {
          return groupSchema.required(t`Condition is required`);
        } else {
          return conditionSchema.required(t`Condition is required`);
        }
      })
    ),
  });

  return { schema };
};
