import { Dialog, Divider, IconButton, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { symbols } from "../../constants/sensorsUnitsSymbols";
import Button from "../../cool_widgets/Button";
import DatesList from "../../cool_widgets/DatesList/DatesList";
import DaysList from "../../cool_widgets/DaysList/DaysList";
import { Switch } from "../../cool_widgets/Switch";
import { Close } from "../../icons";
import { DeleteIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { minsToTime } from "../../services/timeService";
import { AddIcon, ArrowBack, Edit } from "../../svgComponents";
import AddEditSchedule from "../AddEditSensorSchedule/AddEditSchedule";
import Tooltip from "../Tooltip/LightTooltip";
import styles from "./scheduleList.style";
import { sensorMeasurementUnits } from "@models/Sensors";

const ScheduleList: React.FC<any> = (props: any) => {
  const { sensorId, hideSchedulesPanel, setOpenDeleteDialog } = props;
  const types = useStoreState((state) => state.types);

  const timeFormat = useStoreState((state) => state.users.timeFormat);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const getSensorSchedules = useStoreActions((action) => action.sensors.getSensorSchedules);
  const deleteScheduleFromSensorEntity = useStoreActions((action) => action.sensors.deleteScheduleFromSensorEntity);
  const addScheduleToSensorEntity = useStoreActions((action) => action.sensors.addScheduleToSensorEntity);
  const getSensor = useStoreState((state) => state.sensors.getSensor);

  const [openAddEditSchedule, handleAddEditSchedule] = useState<string>("");
  const [schedules, setSchedules] = useState<any>({});
  const { updateSchedule, deleteSchedule } = useStoreActions((actions) => actions.schedules);
  const [sensor, setSensor] = useState<any>(null);
  const [toDeleteItem, setToDeleteItem] = useState<any>(null);

  const { permissions = {} } = sensor || {};
  const { canCreateSchedules } = permissions;

  const { scheduleCategories, weekDays, sensorTypes : fullSensorTypes } = types;

  const Schedule = ({ sensorUnit, enums, sensorType, temperatureSymbol, changeScheduleActiveState, schedule, timeFormat, handleAddEditSchedule, deleteSelectedSchedule, weekDays }: any) => {
    if (!schedule) { return null; }
    const classes = styles();
    const {
      id: scheduleId,
      powerOnTime,
      powerOffTime,
      days = [],
      dates = [],
      name, ///???
      isDisabled,
      value,
      scheduleCategory,
      permissions = {}
    } = schedule;
    const { canUpdate: canUpdateSchedules, canDelete: canDeleteSchedules } = permissions;
    const startHour =
      powerOnTime || powerOnTime === 0 ? minsToTime(powerOnTime, timeFormat) : "";
    const endHour =
      powerOffTime || powerOffTime === 0
        ? minsToTime(powerOffTime, timeFormat)
        : "";

    const isOnOff = fullSensorTypes[sensorType]?.measurementUnits === sensorMeasurementUnits.OpenClose ;

    return (<div
      className={classes.scheduleContainer}
      key={`schedule-${scheduleId}`}
    >
      <div className={classes.scheduleHeader}>
        <div className={classes.scheduleTitle}>
          <Typography className={classes.longNamestyle}>
            {name}
          </Typography>
        </div>
        <div className={classes.scheduleControl}>
          <Tooltip title={t`Edit Schedule`}>
            <IconButton disabled={!canUpdateSchedules} disableRipple onClick={() => handleAddEditSchedule(scheduleId)} className={classes.iconBtnStyle}>
              <Edit className={classes.editIcon} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t`Delete Schedule`}>
            <IconButton
              disabled={!canDeleteSchedules}
              disableRipple
              onClick={() => {
                setOpenDeleteDialog(true);
                setToDeleteItem(schedule);
              }}
              classes={{ root: classes.overWriteIcon }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className={classes.scheduleBody}>
        <div className={classes.rowContainer}>
          <div className={classes.timeRow}>
            <div className={classes.timeSection}>
              <Typography className={classes.timeText}> {t`Start: ` + (powerOnTime || powerOnTime === 0 ? startHour : `None`)}</Typography>
              <Typography className={classes.timeText}> {t`End: ` + (powerOffTime || powerOffTime === 0 ? endHour : `None`)}</Typography>
            </div>
          </div>

         {!isOnOff && <div className={classes.setpointContainer}>
            <div className={classes.setPointSection}>
              <Typography className={classes.setPointStyle}>
                {enums ? enums[value] || value : value}{symbols[sensorUnit || ""] || ""}
              </Typography>
            </div>
          </div>}
        </div>
        <div className={classes.bodyRow}>
          {scheduleCategory === scheduleCategories.weekly &&
            <DaysList
              days={Object.keys(weekDays)}
              activeDays={days}
              action={() => { }}
              disablePointer
            />}
          {scheduleCategory === scheduleCategories.calendar &&
            <DatesList
              dates={dates}
            />}
        </div>
        <div className={classes.bodyRow} style={{ padding: "16px 0" }}>
          <Typography className={classes.scheduleStatusStyle}>
            {isDisabled ? t`Status: Inactive` : t`Status: Active`}
          </Typography>
          <div className={classes.controlSec}>
            <Switch
              disabled={!canUpdateSchedules}
              checked={!isDisabled}
              disableRipple={true}
              onChange={(e: any) =>
                changeScheduleActiveState(e, scheduleId)
              }
              value={true}
            />
          </div>
        </div>
        <Divider className={classes.dividerStyle} />
      </div>
    </div>);
  };

  useEffect(() => {
    setSensor(getSensor(sensorId));
    getSensorSchedules(sensorId)
      .then((schedules: any) => setSchedules(schedules))
      .catch(() => { });
  }, []);

  const deleteSelectedSchedule = (scheduleId: string) => {
    deleteSchedule(scheduleId)
      .then(() => {
        delete schedules[scheduleId];
        setSchedules({ ...schedules });
        deleteScheduleFromSensorEntity({ sensorId: sensor.id, scheduleId });
      })
      .catch((err: any) =>
        addMessage({ message: err.message })
      )
      .finally(() => handleDeleteClose());
  };

  const handleDeleteClose = () => {
    setToDeleteItem(null);
    setOpenDeleteDialog(false);
  };

  const changeScheduleActiveState = (e: any, id: string) => {
    const isDisabled = !e.target.checked;
    updateSchedule({ id, data: { isDisabled } }).then(() => {
      setSchedules({
        ...schedules, [id]: { ...schedules[id], isDisabled }
      });
    })
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const saveScheduleData = (scheduleId: string, updatedData: any) => {
    schedules[scheduleId] = updatedData;
    setSchedules(schedules);
  };

  const addScheduleLocaly = (schedule: any) => {
    addScheduleToSensorEntity({ sensorId: sensor.id, scheduleId: schedule.id });
    setSchedules({ ...schedules, [schedule.id]: schedule });
  };
  const classes = styles();

  const Header = () => <div style={{
    background: "linear-gradient(to left, #421a40, #29132e 100%)",
    boxShadow: "0px 5px 9px 0 rgba(0, 0, 0, 0.2)", display: "flex", height: 50, justifyContent: "space-between", alignItems: "center"
  }}>
    <IconButton disableRipple onClick={hideSchedulesPanel} className={classes.bigIconBtnStyle}>
      <ArrowBack />
    </IconButton>
    <Typography style={{ color: "#fff", fontSize: 26 }}>{t`Schedules`}</Typography>
    <IconButton disabled={!canCreateSchedules} disableRipple onClick={() => handleAddEditSchedule("new")} className={classes.bigIconBtnStyle}>
      <AddIcon className={classes.addIcon} />
    </IconButton>
  </div>;

  return <div className={classes.screenContainer}>
    {!openAddEditSchedule && <>
      <Header />
      <div className={classes.schedulesContainer}>
        {Object.values(schedules).length === 0 ? (
          <Typography
            className={classes.noSchedulesStyle}
          >{t`There are no schedules to show`}</Typography>
        ) : (
          <>
            {Object.values(schedules).map((schedule: any) => <Schedule sensorUnit={sensor.sensorUnit} key={`schedule-${schedule.id}`} sensorType={sensor.type} enums={sensor.enums} changeScheduleActiveState={changeScheduleActiveState} schedule={schedule} timeFormat={timeFormat} handleAddEditSchedule={handleAddEditSchedule} deleteSelectedSchedule={deleteSelectedSchedule} weekDays={weekDays} />)}
          </>)}
      </div>
    </>}
    {openAddEditSchedule && <AddEditSchedule
      editMode={openAddEditSchedule !== "new"}
      sensor={sensor}
      scheduleData={schedules[openAddEditSchedule] || {}}
      hideSchedulesPanel={hideSchedulesPanel}
      closeAddEditSchedule={() => handleAddEditSchedule("")}
      saveScheduleData={saveScheduleData}
      addScheduleLocaly={addScheduleLocaly}
    />
    }
    {toDeleteItem && (
      <Dialog
        open={toDeleteItem}
        onClose={(event: any) => {
          event.stopPropagation();
          handleDeleteClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.dialog }}
      >
        <div className={classes.titleContent}>
          <Typography className={classes.headerTitle}>{t`Delete Schedule`}</Typography>
          <IconButton disableRipple onClick={() => setToDeleteItem(null)} className={classes.overWriteIcon}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <div className={classes.dialogContent}>
          <Typography className={classes.DialogContentText}>
            {t`Are you sure you want to delete ` + toDeleteItem?.name + ` schedule?`}
          </Typography>
        </div>
        <div className={classes.deleteDialogActions}>
          <Button
            onClick={() => deleteSelectedSchedule(toDeleteItem.id)}
            className={classes.yesBtn}
            white width={130}
            marginRight
          >
            {t`Yes`}
          </Button>

          <Button
            autoFocus={true}
            onClick={() => handleDeleteClose()}
            width={130}
          >
            {t`No`}
          </Button>
        </div>
      </Dialog>
    )}
  </div>;
};

export default ScheduleList;
