import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Tooltip, IconButton, Typography } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import useStyles from './PaginatedTable.style';
import { t } from 'ttag';
import LinearProgressBar from '@components/LinearProgressBar';

/**
* Represents a paginated table component.
*/
interface IPaginatedTable {
  /**
  * Current page number.
  */
  page: number;
  /**
  * Number of rows per page.
  */
  rowsPerPage: number;
  /**
  * Total count of items in the table.
  */
  totalCount: number;
  /**
  * Data to be displayed in the table.
  */
  data: object[];
  /**
  * Column definitions for the table.
  */
  columns: { id: string; title: string }[];
  /**
  * Indicates whether newer records exist beyond the current page.
  */
  newerRecordsExist?: boolean;
  /**
  * Indicates whether data is currently being loaded.
  */
  loading?: boolean;
  /**
  * Message to display in a tooltip.
  */
  TooltipMessage?: string;
  /**
  * Function to render a cell in the table.
  * @param row The data object for the row.
  * @param cellId The ID of the cell.
  * @returns A React node representing the cell content.
  */
  renderCell: (row: object, cellId: string) => React.ReactNode;
  /**
  * Handler for page change events.
  * @param event The click event (or null if triggered programmatically).
  * @param page The new page number.
  */
  onPageChange: (page: number) => void;
  /**
  * Optional handler for rows per page change events.
  * @param event The change event.
  */
  onRowsPerPageChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  /**
  * Optional handler for double click events on table rows.
  * @param item The item object corresponding to the clicked row.
  */
  handleDoubleClick?: (item: object) => void;
  /**
  * Optional function to refresh table data.
  */
  refreshData?: () => void;
}

const PaginatedTable: React.FC<IPaginatedTable> = (props: IPaginatedTable) => {
  const {
    page = 0,
    rowsPerPage = 20,
    totalCount = 0,
    data = [],
    newerRecordsExist,
    loading,
    columns,
    TooltipMessage,
    renderCell,
    refreshData,
    onPageChange,
    onRowsPerPageChange,
    handleDoubleClick
  } = props;
  const classes = useStyles();

  const handleRefreshData = () => {
    refreshData?.();
  };

  return (
    <div className={classes.tablePaper}>
      <div className={classes.tablePagesHeader}>
        <div className={classes.flexConitainer}>
          <Tooltip title={t`Reload`}>
            <IconButton onClick={handleRefreshData} className={classes.reloadIconStyle}>
              <Refresh />
            </IconButton>
          </Tooltip>
          {newerRecordsExist && <Typography>{t`Newer Records Exist`}</Typography>}
        </div>
        <TablePagination
          rowsPerPageOptions={[20, 30, 50, 100]}
          component='div'
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={(_e, newPage) => onPageChange(newPage + 1)}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </div>
      <div className={classes.progressBarContainer}>{loading && <LinearProgressBar durationInSeconds={30} />}</div>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label='customized table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <Tooltip key={index} title={TooltipMessage || ''}>
                <TableRow key={index} onDoubleClick={() => handleDoubleClick && handleDoubleClick(row)}>
                  {columns.map((column) => (
                    <TableCell className={classes.tableCell} key={column.id}>
                      {renderCell(row, column.id)}
                    </TableCell>
                  ))}
                </TableRow>
              </Tooltip>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PaginatedTable;
