import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import {
  format,
  getHours,
  getMinutes
} from "date-fns";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import _, { isEmpty } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { t } from "ttag";
import styles from "../../components/Loading/Loader.module.css";
import { useStoreState } from "../../models/RootStore";
import useStyle from "./OneTenant.style";
import LinearProgressBar from "@components/LinearProgressBar";

const WAITING_DELAY = 240 ; //4 minutes in seconds

const autoWidth = (worksheet: any, maxWidth = 0, minimalWidth = 10) => {
  worksheet.columns.forEach((column: any, index: any) => {
    let maxColumnLength = 0;
    column.eachCell({ includeEmpty: true }, (cell: any) => {
      cell.font = { size: 12 };
      cell.alignment = { wrapText: true };
      maxColumnLength = Math.max(
        maxColumnLength,
        minimalWidth,
        cell.value ? cell.value.toString().length : 0
      );
    });
    column.width = (maxWidth ? Math.min(maxColumnLength, maxWidth) : maxColumnLength) + 2;
  });
};

const boldStyle = { bold: true, size: 12 };
const fillStyle: any = { type: "pattern", pattern: "solid", fgColor: { argb: "efefef" } };

const extPerFormat: any = {
  CoolAutomation: "xlsx",
  Hatachana: "csv",
  TAU: "xlsx"
};

export default function ExportExcel({ zones, selections, selectedTime, getPowerUsageAsyncData, pageData, addMessage, basicReolution }: any) {
  const classes = useStyle();
  const types = useStoreState((s) => s.types);

  const [openExport, setOpenExport] = useState<boolean>(false);
  const [exportSelections, setExportSelections] = useState<any>({ systems: {}, zones: {} });
  const [reportFormat, setFormat] = useState<number>(1);
  const [fileName, setFileName] = useState<string>("");
  const [formError, setFormError] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [distributeExcessPower, setDistributeExcessPower] = useState<boolean>(true);
  const [selectedGran, setGran] = useState<number>(2);

  const sites = useStoreState((s) => s.sites.allSites);
  const tenants = zones || {};
  const allUnits = useStoreState((s) => s.units.allUnits);
  const systems = useStoreState((s) => s.systems.allSystems);
  const powerMeters = useStoreState((s) => s.powerMeters.allPowerMeters);

  const siteObj = sites[selections.siteId];

  const ppdTypesMirror = Object.keys(types?.ppdReportFormatTypes).reduce((obj: any, item: any) => {
    const val = types?.ppdReportFormatTypes[item];
    obj[`${val}`] = item;
    return obj;
  }, {});

  const TAUFormat = (dataToExport: any) => {
    const workbook = new ExcelJS.Workbook();
    const timeArrType = selectedGran === 2 ? "dayArr" : "hourArr";

    const header = [
      "Date",
      "Group IU",
      "Running Time (Hr) Charging",
      "Thermo-On Time(min) Charging",
      "Ref. Flow Amount(IU) Charging",
      "Accumulated Running Current",
      "Total Time(min)",
      "",
      "אחוז שימוש חדש",
      "KWh IU",
      "Cost ₪",
      "Business Name",
      "",
      "Room"
    ];

    if (!dataToExport?.zones) {
      return;
    }
    const allTenants: any = Object.values(dataToExport?.zones || {});

    for (let tenant of allTenants) {
      if (!tenant) {
        continue;
      }
      const tenantObj = tenants[tenant.id];

      const sheet = workbook.addWorksheet(tenantObj?.name);
      sheet.addRow(header);
      const tenantUnits = _.orderBy(tenant?.units, (uId) => allUnits[uId]?.name);
      let rowNum = 2;

      for (let unitId of tenantUnits) {
        const unitPPDObj = dataToExport["units"][unitId];
        if (!unitPPDObj) {
          continue;
        }
        const timeArr = unitPPDObj[timeArrType];

        const row = [];
        row.push(moment(timeArr[0].timestamp).format("YYYY/MM/DD-"));
        row.push(unitPPDObj.name || "");
        row.push(Math.round((unitPPDObj.onTime / 60) * 10) / 10 || 0);
        row.push("");
        row.push("");
        row.push("");
        row.push("");
        row.push("");
        row.push(unitPPDObj.power || 0);
        row.push({ formula: `O3*I${rowNum}/100` }); //formula power usage
        row.push("");
        row.push(unitPPDObj.name || "");
        sheet.addRow(row);

        rowNum++;

      }

      sheet.addRow([]);
      sheet.addRow([
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        { formula: `Sum(I2:I${sheet.rowCount - 1})` },
        { formula: `Sum(J2:J${sheet.rowCount - 1})` },
        { formula: `Sum(K2:K${sheet.rowCount - 1})` }
      ]);

      sheet.addRow([
        "",
        "",
        tenant?.onTime,
        Math.round(tenant?.thermOnTime / 60 * 10) / 10,
        "",
        "",
        ""
      ]);

      sheet.getCell("N3").value = "KWh General";
      sheet.getCell("O3").value = 0;
      sheet.getCell("N5").value = "KWh Cost";
      sheet.getCell("O5").value = 0.45;

      autoWidth(sheet, 20);
      sheet.getRow(1).font = boldStyle;
      sheet.getRow(1).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "f2f2f2" } };
      sheet.getColumn("K").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffffcc" } };
      sheet.getColumn("L").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffffcc" } };
      sheet.getCell("I1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffffcc" } };
      sheet.getCell("J1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffffcc" } };
      sheet.getCell("N1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffffcc" } };
      sheet.getCell("O1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffffcc" } };
    }

    return workbook;
  };

  const hatakhanaFormat = (dataToExport: any) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("sheet1");

    const timeArrType = selectedGran === 2 ? "dayArr" : "hourArr";
    const selectedSystems = Object.keys(exportSelections.systems);
    const selectedZones = Object.keys(exportSelections.zones);
    const allRespUnits: any = Object.values(dataToExport.units);
    if (!allRespUnits.length) {
      return [];
    }

    const units: any = allRespUnits.filter((unit: any) => {
      const unitObj: any = allUnits[unit.id];
      if (!unitObj) {
        return false;
      }
      const inSys = selectedSystems.indexOf(unitObj.system) > -1;
      const inZones = unit.zones.filter((z: any) => selectedZones.indexOf(z) > -1);
      if (!inSys && !inZones.length) {
        return false;
      }

      return true;
    });
    const dates: any = allRespUnits?.[0]?.[timeArrType];
    const line1 = ["Date", "Time"];
    for (let unit of units) {
      line1.push(unit.serialNumber || t`Unknown`);
    }

    const timesRows = dates.map((item: any, index: any) => {

      const date = moment(item.timestamp).format("DD.MM.YY");
      const time = moment(item.timestamp).format("HH:mm:ss");
      const row: any = [date, time];

      for (let unit of units) {
        row.push(Math.round(unit[timeArrType][index].power * 100) / 100);
      }

      return row;
    });

    const data = [line1, ...timesRows];

    if (!data.length) {
      setLoading(false);
      closeExport();
    }
    sheet.addRows(data);
    return workbook;

  };

  const CAFormat = (dataToExport: any) => {

    const workbook = new ExcelJS.Workbook();

    const line1 = [t`Period`, t`start`, "", t`end`];
    const line2 = [""];
    const line3 = [t`site`];
    const lineAddress = [t`Site Address`];
    const emptyLine = [""];

    const startTime = selections.dateRange.startDate;
    const endTime = selections.dateRange.endDate;
    line2.push(`${format(startTime, "MM/dd/yyyy")} ${getHours(startTime).toString().padStart(2, "0")}:${getMinutes(startTime).toString().padStart(2, "0")}`);
    line2.push("");
    line2.push(`${format(endTime, "MM/dd/yyyy")} ${getHours(endTime).toString().padStart(2, "0")}:${getMinutes(endTime).toString().padStart(2, "0")}`);

    const site = sites[selections.siteId];
    line3.push(site.name);
    lineAddress.push(`${site.address}, ${site.city}, ${site.country}`);
    const headerData = [line1, line2, line3, lineAddress, emptyLine, emptyLine];

    (() => {
      const sheet = workbook.addWorksheet("Tenants Energy Total");
      const line5 = ["", "", "", t`Energy Consumption - Tenant Breakdown`];
      const line6 = [""];
      const line7 = ["", "", "", t`Tenant Name`, t`Total (kWh)`, t`Cool (kWh)`, t`Heat (kWh)`, t`Other (kWh)`, t`Estimated (kWh)`];
      const tenantLines = [];
      const totals: any = { total: 0, cool: 0, heat: 0, other: 0, est: 0 };
      const totalsLine = ["", "", "", t`Total All Tenants`];

      for (let tenant in dataToExport?.zones) {
        const oneTenantLine = ["", "", ""];
        const tenantObj = dataToExport?.zones[tenant];
        if (Object.keys(exportSelections?.zones).indexOf(tenantObj?.id) === -1) {
          continue;
        }
        oneTenantLine.push(tenants[tenant].name);
        oneTenantLine.push(tenantObj?.power);
        oneTenantLine.push(tenantObj?.powerCool);
        oneTenantLine.push(tenantObj?.powerHeat);
        oneTenantLine.push(tenantObj?.powerOther);
        oneTenantLine.push(tenantObj?.powerMissing);
        tenantLines.push(oneTenantLine);
        totals.total += tenantObj?.power;
        totals.cool += tenantObj?.powerCool;
        totals.heat += tenantObj?.powerHeat;
        totals.other += tenantObj?.powerOther;
        totals.est += tenantObj?.powerMissing;
      }

      totalsLine.push(totals.total, totals.cool, totals.heat, totals.other, totals.est);

      const sheet1Data = [...headerData, line5, line6, line7, ...tenantLines, totalsLine];
      sheet.addRows(sheet1Data);
      autoWidth(sheet);
      sheet.getRow(1).font = boldStyle;
      sheet.getRow(7).font = boldStyle;
      sheet.getRow(9).font = boldStyle;
      sheet.getRow(sheet.rowCount).font = boldStyle;
      sheet.getCell("A3").font = boldStyle;
      sheet.getCell("A4").font = boldStyle;
    })();

    (() => {
      const sheet = workbook.addWorksheet("Tenants By Unit Total");
      const line5 = ["", t`Energy Consumption - Unit Energy by tenants`];
      const headers1 = ["", "", t`Total`, t`Total`, t`Total`, t`Cool`, t`Cool`, t`Cool`, t`Heat`, t`Heat`, t`Heat`, t`Other`, t`Other`, t`Other`, t`Estimated`, t`Estimated`, t`Estimated`, t`Availability`, t`Excess`];
      const headers2 = ["", t`Unit Name`, t`Energy (kWh)`, t`Runtime (Hrs)`, t`Demand (Hrs)`, t`kWh`, t`Runtime`, t`TO time`, t`kWh`, t`Runtime`, t`TO time`, t`kWh`, t`Runtime`, t`TO time`, t`kWh`, t`Runtime`, t`TO time`, `%`, t`kWh`];
      const tenantsLines = [];
      const boldRows = [];
      const grandTotals = {
        power: 0,
        time: 0,
        toTime: 0,
        cool: 0,
        coolTime: 0,
        toCoolTime: 0,
        heat: 0,
        heatTime: 0,
        toHeatTime: 0,
        other: 0,
        otherTime: 0,
        toOtherTime: 0,
        est: 0,
        estTime: 0,
        toEstTime: 0,
        excess: 0
      };

      for (let tenant in dataToExport?.zones) {
        const tenantObj = dataToExport?.zones[tenant];
        if (Object.keys(exportSelections?.zones).indexOf(tenantObj?.id) === -1) {
          continue;
        }
        const tenantTotals = {
          power: 0,
          time: 0,
          toTime: 0,
          cool: 0,
          coolTime: 0,
          toCoolTime: 0,
          heat: 0,
          heatTime: 0,
          toHeatTime: 0,
          other: 0,
          otherTime: 0,
          toOtherTime: 0,
          est: 0,
          estTime: 0,
          toEstTime: 0,
          excess: 0
        };

        tenantsLines.push([tenants[tenant].name]);
        boldRows.push(tenantsLines.length);
        const unitsArr = _.orderBy(tenantObj?.units, (unitId: any) => dataToExport?.units && dataToExport?.units[unitId]?.name?.toLowerCase());
        for (let unitId of unitsArr) {
          const unitLine: any = [""];
          const unitObj = dataToExport?.units && dataToExport?.units[unitId];
          if (!unitObj) {
            continue;
          }

          unitLine.push(unitObj?.name);
          unitLine.push(unitObj?.power);
          unitLine.push(Math.round(unitObj?.onTime / 60 * 10) / 10);
          unitLine.push(Math.round(unitObj?.thermOnTime / 60 * 10) / 10);
          unitLine.push(unitObj?.powerCool);
          unitLine.push(Math.round(unitObj?.onTimeCool / 60 * 10) / 10);
          unitLine.push(Math.round(unitObj?.thermOnTimeCool / 60 * 10) / 10);
          unitLine.push(unitObj?.powerHeat);
          unitLine.push(Math.round(unitObj?.onTimeHeat / 60 * 10) / 10);
          unitLine.push(Math.round(unitObj?.thermOnTimeHeat / 60 * 10) / 10);
          unitLine.push(unitObj?.powerOther);
          unitLine.push(Math.round(unitObj?.onTimeOther / 60 * 10) / 10);
          unitLine.push(Math.round(unitObj?.thermOnTimeOther / 60 * 10) / 10);
          unitLine.push(unitObj?.powerMissing);
          unitLine.push(Math.round(unitObj?.onTimeMissing / 60 * 10) / 10);
          unitLine.push(Math.round(unitObj?.thermOnTimeMissing / 60 * 10) / 10);
          unitLine.push(unitObj?.availability);
          unitLine.push(unitObj?.excessPower);

          tenantTotals.power += unitObj?.power || 0;
          tenantTotals.time += unitObj?.onTime || 0;
          tenantTotals.toTime += unitObj?.thermOnTime || 0;
          tenantTotals.cool += unitObj?.powerCool || 0;
          tenantTotals.coolTime += unitObj?.onTimeCool || 0;
          tenantTotals.toCoolTime += unitObj?.thermOnTimeCool || 0;
          tenantTotals.heat += unitObj?.powerHeat || 0;
          tenantTotals.heatTime += unitObj?.onTimeHeat || 0;
          tenantTotals.toHeatTime += unitObj?.thermOnTimeHeat || 0;
          tenantTotals.other += unitObj?.powerOther || 0;
          tenantTotals.otherTime += unitObj?.onTimeOther || 0;
          tenantTotals.toOtherTime += unitObj?.thermOnTimeOther || 0;
          tenantTotals.est += unitObj?.powerMissing || 0;
          tenantTotals.estTime += unitObj?.onTimeMissing || 0;
          tenantTotals.toEstTime += unitObj?.thermOnTimeMissing || 0;
          tenantTotals.excess += unitObj?.excessPower || 0;

          tenantsLines.push(unitLine);
        }
        tenantsLines.push([
          "",
          t`All Tenant units`,
          tenantTotals.power,
          Math.round(tenantTotals.time / 60 * 10) / 10,
          Math.round(tenantTotals.toTime / 60 * 10) / 10,
          tenantTotals.cool,
          Math.round(tenantTotals.coolTime / 60 * 10) / 10,
          Math.round(tenantTotals.toCoolTime / 60 * 10) / 10,
          tenantTotals.heat,
          Math.round(tenantTotals.heatTime / 60 * 10) / 10,
          Math.round(tenantTotals.toHeatTime / 60 * 10) / 10,
          tenantTotals.other,
          Math.round(tenantTotals.otherTime / 60 * 10) / 10,
          Math.round(tenantTotals.toOtherTime / 60 * 10) / 10,
          tenantTotals.est,
          Math.round(tenantTotals.estTime / 60 * 10) / 10,
          Math.round(tenantTotals.toEstTime / 60 * 10) / 10,
          tenantTotals.excess
        ]);
        tenantsLines.push([""], [""]);

        grandTotals.power += tenantTotals.power;
        grandTotals.time += tenantTotals.time;
        grandTotals.toTime += tenantTotals.toTime;
        grandTotals.cool += tenantTotals.cool;
        grandTotals.coolTime += tenantTotals.coolTime;
        grandTotals.toCoolTime += tenantTotals.toCoolTime;
        grandTotals.heat += tenantTotals.heat;
        grandTotals.heatTime += tenantTotals.heatTime;
        grandTotals.toHeatTime += tenantTotals.toHeatTime;
        grandTotals.other += tenantTotals.other;
        grandTotals.otherTime += tenantTotals.otherTime;
        grandTotals.toOtherTime += tenantTotals.toOtherTime;
        grandTotals.est += tenantTotals.est;
        grandTotals.estTime += tenantTotals.estTime;
        grandTotals.toEstTime += tenantTotals.toEstTime;
        grandTotals.excess += tenantTotals.excess;

      }//unassignedUnits

      /////////////////////////////unassigned units
      if (dataToExport?.unassignedUnits?.length) {
        const tenantTotalsUA = {
          power: 0,
          time: 0,
          toTime: 0,
          cool: 0,
          coolTime: 0,
          toCoolTime: 0,
          heat: 0,
          heatTime: 0,
          toHeatTime: 0,
          other: 0,
          otherTime: 0,
          toOtherTime: 0,
          est: 0,
          estTime: 0,
          toEstTime: 0,
          excess: 0
        };

        tenantsLines.push([t`Unassigned Units`]);
        boldRows.push(tenantsLines.length);
        const unassignedUnitsArr = _.orderBy(dataToExport?.unassignedUnits, (unitId: any) => dataToExport?.units && dataToExport?.units[unitId]?.name?.toLowerCase());
        for (let unitId of unassignedUnitsArr) {
          const unitLine: any = [""];
          const unitObj = dataToExport?.units && dataToExport?.units[unitId];
          if (!unitObj) {
            continue;
          }

          unitLine.push(unitObj?.name);
          unitLine.push(unitObj?.power);
          unitLine.push(Math.round(unitObj?.onTime / 60 * 10) / 10);
          unitLine.push(Math.round(unitObj?.thermOnTime / 60 * 10) / 10);
          unitLine.push(unitObj?.powerCool);
          unitLine.push(Math.round(unitObj?.onTimeCool / 60 * 10) / 10);
          unitLine.push(Math.round(unitObj?.thermOnTimeCool / 60 * 10) / 10);
          unitLine.push(unitObj?.powerHeat);
          unitLine.push(Math.round(unitObj?.onTimeHeat / 60 * 10) / 10);
          unitLine.push(Math.round(unitObj?.thermOnTimeHeat / 60 * 10) / 10);
          unitLine.push(unitObj?.powerOther);
          unitLine.push(Math.round(unitObj?.onTimeOther / 60 * 10) / 10);
          unitLine.push(Math.round(unitObj?.thermOnTimeOther / 60 * 10) / 10);
          unitLine.push(unitObj?.powerMissing);
          unitLine.push(Math.round(unitObj?.onTimeMissing / 60 * 10) / 10);
          unitLine.push(Math.round(unitObj?.thermOnTimeMissing / 60 * 10) / 10);
          unitLine.push(unitObj?.availability);
          unitLine.push(unitObj?.excessPower);

          tenantTotalsUA.power += unitObj?.power || 0;
          tenantTotalsUA.time += unitObj?.onTime || 0;
          tenantTotalsUA.toTime += unitObj?.thermOnTime || 0;
          tenantTotalsUA.cool += unitObj?.powerCool || 0;
          tenantTotalsUA.coolTime += unitObj?.onTimeCool || 0;
          tenantTotalsUA.toCoolTime += unitObj?.thermOnTimeCool || 0;
          tenantTotalsUA.heat += unitObj?.powerHeat || 0;
          tenantTotalsUA.heatTime += unitObj?.onTimeHeat || 0;
          tenantTotalsUA.toHeatTime += unitObj?.thermOnTimeHeat || 0;
          tenantTotalsUA.other += unitObj?.powerOther || 0;
          tenantTotalsUA.otherTime += unitObj?.onTimeOther || 0;
          tenantTotalsUA.toOtherTime += unitObj?.thermOnTimeOther || 0;
          tenantTotalsUA.est += unitObj?.powerMissing || 0;
          tenantTotalsUA.estTime += unitObj?.onTimeMissing || 0;
          tenantTotalsUA.toEstTime += unitObj?.thermOnTimeMissing || 0;
          tenantTotalsUA.excess += unitObj?.excessPower || 0;

          tenantsLines.push(unitLine);
        }
        tenantsLines.push([
          "",
          t`All Tenant units`,
          tenantTotalsUA.power,
          Math.round(tenantTotalsUA.time / 60 * 10) / 10,
          Math.round(tenantTotalsUA.toTime / 60 * 10) / 10,
          tenantTotalsUA.cool,
          Math.round(tenantTotalsUA.coolTime / 60 * 10) / 10,
          Math.round(tenantTotalsUA.toCoolTime / 60 * 10) / 10,
          tenantTotalsUA.heat,
          Math.round(tenantTotalsUA.heatTime / 60 * 10) / 10,
          Math.round(tenantTotalsUA.toHeatTime / 60 * 10) / 10,
          tenantTotalsUA.other,
          Math.round(tenantTotalsUA.otherTime / 60 * 10) / 10,
          Math.round(tenantTotalsUA.toOtherTime / 60 * 10) / 10,
          tenantTotalsUA.est,
          Math.round(tenantTotalsUA.estTime / 60 * 10) / 10,
          Math.round(tenantTotalsUA.toEstTime / 60 * 10) / 10,
          tenantTotalsUA.excess
        ]);
        tenantsLines.push([""], [""]);

        grandTotals.power += tenantTotalsUA.power;
        grandTotals.time += tenantTotalsUA.time;
        grandTotals.toTime += tenantTotalsUA.toTime;
        grandTotals.cool += tenantTotalsUA.cool;
        grandTotals.coolTime += tenantTotalsUA.coolTime;
        grandTotals.toCoolTime += tenantTotalsUA.toCoolTime;
        grandTotals.heat += tenantTotalsUA.heat;
        grandTotals.heatTime += tenantTotalsUA.heatTime;
        grandTotals.toHeatTime += tenantTotalsUA.toHeatTime;
        grandTotals.other += tenantTotalsUA.other;
        grandTotals.otherTime += tenantTotalsUA.otherTime;
        grandTotals.toOtherTime += tenantTotalsUA.toOtherTime;
        grandTotals.est += tenantTotalsUA.est;
        grandTotals.estTime += tenantTotalsUA.estTime;
        grandTotals.toEstTime += tenantTotalsUA.toEstTime;
        grandTotals.excess += tenantTotalsUA.excess;
      }
      ////////////////////////////////end of unassigned units

      tenantsLines.push([
        "",
        t`Total all units`,
        grandTotals.power,
        Math.round(grandTotals.time / 60 * 10) / 10,
        Math.round(grandTotals.toTime / 60 * 10) / 10,
        grandTotals.cool,
        Math.round(grandTotals.coolTime / 60 * 10) / 10,
        Math.round(grandTotals.toCoolTime / 60 * 10) / 10,
        grandTotals.heat,
        Math.round(grandTotals.heatTime / 60 * 10) / 10,
        Math.round(grandTotals.toHeatTime / 60 * 10) / 10,
        grandTotals.other,
        Math.round(grandTotals.otherTime / 60 * 10) / 10,
        Math.round(grandTotals.toOtherTime / 60 * 10) / 10,
        grandTotals.est,
        Math.round(grandTotals.estTime / 60 * 10) / 10,
        Math.round(grandTotals.toEstTime / 60 * 10) / 10,
        grandTotals.excess
      ]);

      const sheet2Data: any = [...headerData, line5, emptyLine, emptyLine, headers1, headers2, emptyLine, ...tenantsLines];

      sheet.addRows(sheet2Data);
      autoWidth(sheet);
      sheet.getRow(1).font = boldStyle;
      sheet.getRow(7).font = boldStyle;
      sheet.getRow(10).font = boldStyle;
      sheet.getRow(11).font = boldStyle;
      sheet.getCell("A3").font = boldStyle;
      sheet.getCell("A4").font = boldStyle;
      for (let rowNum of boldRows) {
        sheet.getRow(rowNum + 12).font = boldStyle;
        sheet.getRow(rowNum + 12).fill = fillStyle;
      }

    })();

    (() => {
      const sheet = workbook.addWorksheet("Tenant Unit Breakdown");
      const timeArrType = selectedGran === 2 ? "dayArr" : "hourArr";
      const line5 = ["", t`Energy Consumption - Unit Energy by tenants`];
      const tenNames = ["", "", ""];
      const serials = ["", "", ""];
      const unitNames = ["", "", ""];
      const gridHeaders = ["", t`Date`, t`Time`];
      const grid: any = [];
      const units: any = Object.values(dataToExport?.units);
      const timesArr = units?.[0]?.[timeArrType];

      for (let timeIdx in timesArr) {
        const timeStr = timesArr[timeIdx];
        grid.push([]);
        grid[timeIdx].push("");
        grid[timeIdx].push(moment(timeStr.timestamp).format("DD-MM-YYYY"));
        grid[timeIdx].push(moment(timeStr.timestamp).format("HH:mm"));

        let total = 0;
        const tenantsIds = Object.keys(dataToExport?.zones);
        for (let tenantIdx in tenantsIds) {
          const tenant: any = tenantsIds[tenantIdx];
          const tenantObj = dataToExport?.zones[tenant];
          if (Object.keys(exportSelections?.zones).indexOf(tenantObj?.id) === -1) {
            continue;
          }
          const tenantName = tenants[tenant].name;

          for (let unitIdx in tenantObj?.units) {
            const unitId = tenantObj?.units[unitIdx];
            const unitObj = dataToExport?.units[unitId];
            if (!unitObj) {
              continue;
            }

            const unitObjOrg: any = allUnits[unitId];
            const times = unitObj[timeArrType];

            if (+timeIdx === 0) {
              tenNames.push(tenantName);
              serials.push(unitObjOrg.serialNumber);
              unitNames.push(unitObjOrg.name);
            }
            const timeObj = times[timeIdx];
            total += +timeObj?.power;

            grid[timeIdx].push(timeObj?.power);
          }
        }
        grid[timeIdx].push(total);

      }
      tenNames.push(t`Total`);

      const sheet3Data: any = [...headerData, line5, emptyLine, emptyLine, tenNames, serials, unitNames, gridHeaders, ...grid];
      sheet.addRows(sheet3Data);
      autoWidth(sheet);
      sheet.getRow(1).font = boldStyle;
      sheet.getRow(7).font = boldStyle;
      sheet.getCell("A3").font = boldStyle;
      sheet.getCell("A4").font = boldStyle;

    })();

    (() => {
      const sheet = workbook.addWorksheet("Power Meters");
      const line5 = ["", t`Power Meters reading (kWh)`];
      const names = ["", t`Meter Name`, t`Total`, t`Distributed`, t`Excess`];
      const data: any = [];

      for (let ppdId in dataToExport?.ppds) {
        const ppdObj = dataToExport?.ppds[ppdId];
        data.push([
          "",
          ppdObj.name,
          ppdObj.power,
          +ppdObj.power - +ppdObj.excessPower,
          ppdObj.excessPower
        ]);

      }

      const sheet4Data: any = [...headerData, line5, emptyLine, emptyLine, names, ...data];
      sheet.addRows(sheet4Data);
      autoWidth(sheet);
      sheet.getRow(1).font = boldStyle;
      sheet.getRow(7).font = boldStyle;
      sheet.getRow(10).font = boldStyle;
      sheet.getCell("A3").font = boldStyle;
      sheet.getCell("A4").font = boldStyle;

    })();

    (() => {
      const sheet = workbook.addWorksheet("Data");
      const line1 = ["Period", "start", "end"];
      const timeLine2: any = [""];
      const siteNameLine3 = ["site"];
      const addressLine4 = ["Site address"];
      const line5 = [""];
      const line6 = [""];
      const line7 = ["Distribution report"];
      const tenantChunkLine9: any = [];
      const systemsData: any = [];
      let tenantAndUnitsChunkSum: any = [];
      const breakLine: any = [];
      const line11 = [["Time breakdown"], []];
      const powerData: any = [];

      const byTimeChunkLine14: any = [];

      const startTime = selections.dateRange.startDate;
      const endTime = selections.dateRange.endDate;
      timeLine2.push(`${format(startTime, "MM/dd/yyyy")} ${getHours(startTime).toString().padStart(2, "0")}: ${getMinutes(startTime).toString().padStart(2, "0")}`);
      timeLine2.push(`${format(endTime, "MM/dd/yyyy")} ${getHours(endTime).toString().padStart(2, "0")}: ${getMinutes(endTime).toString().padStart(2, "0")}`);

      const site = sites[selections.siteId];
      siteNameLine3.push(site.name);
      addressLine4.push(`${site.address}, ${site.city}, ${site.country}`);
      const timeArrType = selectedGran === 2 ? "dayArr" : "hourArr";

      _.forEach(dataToExport.zones, (tenant, tenantId) => {
        if (!exportSelections.zones[tenantId]) {
          return;
        }

        const measureUnit = tenant.measurementUnits || "kWh";

        tenantChunkLine9.push(["Tenant", tenants[tenantId].name]);
        tenantChunkLine9.push(["", "Cool", "", "", "Heat", "", "", "Other", "", "", "Estimated", "", "", "Total", "", "", "Availability", "Excess"]);
        tenantChunkLine9.push(["Unit Name", measureUnit, "Runtime", "Therm on time", measureUnit, "Runtime", "Therm on time", measureUnit, "Runtime", "Therm on time", measureUnit, "Runtime", "Therm on time", measureUnit, "Runtime", "Therm on time", "", measureUnit]);

        byTimeChunkLine14.push(["Tenant", tenants[tenantId].name]);
        const unitNames = _.merge(tenant.indoorUnits, tenant.outdoorUnits).reduce((names: any, unitId: string) => {
          const item = allUnits[unitId];
          let unitName = item?.name || "";
          if (+item?.type === 3) {
            const controlUnitId: any = item.controlUnit;
            const controlName = allUnits[controlUnitId]?.name || t`Unassigned`;
            unitName = `${controlName} (${item.address})`;
          }
          names[0].push(unitName, "");
          names[1].push(measureUnit, "Runtime");
          return names;
        }, [["", ""], ["Date", "Time"]]);
        unitNames[0].push("Total");
        unitNames[1].push(measureUnit, "Runtime");

        byTimeChunkLine14.push(...unitNames);
        const sortedUnits = _.orderBy(_.merge(dataToExport.zones[tenantId].indoorUnits, dataToExport.zones[tenantId].outdoorUnits), [(unitId: any) => allUnits[unitId]?.name?.toUpperCase()],
          ["asc"]);
        for (let unitId of sortedUnits) {
          const row: any = [];
          const unit = dataToExport.units[unitId];
          if (!unit) {
            continue;
          }
          if (unit?.message === "unit not in associated systems") {
            continue;
          }

          const item = allUnits[unitId];
          let unitName = item?.name || "";
          if (+item?.type === 3) {
            const controlUnitId: any = item.controlUnit;
            const controlName = allUnits[controlUnitId]?.name || t`Unassigned`;
            unitName = `${controlName} (${item.address})`;
          }

          row.push(unitName);

          row.push(unit.powerCool || 0);
          row.push(Math.round((unit.onTimeCool / 60) * 10) / 10 || 0);
          row.push(Math.round((unit.thermOnTimeCool / 60) * 10) / 10 || 0);

          row.push(unit.powerHeat || 0);
          row.push(Math.round((unit.onTimeHeat / 60) * 10) / 10 || 0);
          row.push(Math.round((unit.thermOnTimeHeat / 60) * 10) / 10 || 0);

          row.push(unit.powerOther || 0);
          row.push(Math.round((unit.onTimeOther / 60) * 10) / 10 || 0);
          row.push(Math.round((unit.thermOnTimeOther / 60) * 10) / 10 || 0);

          row.push(unit.powerMissing || 0);
          row.push(Math.round((unit.onTimeMissing / 60) * 10) / 10 || 0);
          row.push(Math.round((unit.thermOnTimeMissing / 60) * 10) / 10 || 0);

          row.push(unit.power || 0);
          row.push(Math.round((unit.onTime / 60) * 10) / 10 || 0);
          row.push(Math.round((unit.thermOnTime / 60) * 10) / 10 || 0);

          row.push(`${"availability" in unit ? unit.availability : 100}%`);
          row.push(unit.excessPower || 0);
          tenantChunkLine9.push(row);
        }

        let breakdownLine;
        const timeArr = dataToExport.zones[tenantId][timeArrType];
        for (let timeIndex in timeArr) {
          const timeObj = timeArr[timeIndex];
          breakdownLine = [moment(timeObj.timestamp).format("DD/MM/YYYY"), moment(timeObj.timestamp).format("HH:mm")];

          for (let unitId of _.merge(dataToExport.zones[tenantId].indoorUnits, dataToExport.zones[tenantId].outdoorUnits)) {
            const unitObj = dataToExport.units[unitId];
            if (!unitObj) {
              continue;
            }
            const unitObjTimeSlot = unitObj[timeArrType][timeIndex];
            breakdownLine.push(unitObjTimeSlot.power, `${Math.round((unitObjTimeSlot.onTime / 60) * 10) / 10}`);
          }

          breakdownLine.push(timeObj.power, `${Math.round((timeObj.onTime / 60) * 10) / 10}`);
          byTimeChunkLine14.push(breakdownLine);
        }

        tenantChunkLine9.push([
          "Total",
          dataToExport.zones[tenantId].powerCool || 0,
          Math.round((dataToExport.zones[tenantId].onTimeCool / 60) * 10) / 10 || 0,
          Math.round((dataToExport.zones[tenantId].thermOnTimeCool / 60) * 10) / 10 || 0,
          dataToExport.zones[tenantId].powerHeat || 0,
          Math.round((dataToExport.zones[tenantId].onTimeHeat / 60) * 10) / 10 || 0,
          Math.round((dataToExport.zones[tenantId].thermOnTimeHeat / 60) * 10) / 10 || 0,
          dataToExport.zones[tenantId].powerOther || 0,
          Math.round((dataToExport.zones[tenantId].onTimeOther / 60) * 10) / 10 || 0,
          Math.round((dataToExport.zones[tenantId].thermOnTimeOther / 60) * 10) / 10 || 0,
          dataToExport.zones[tenantId].powerMissing || 0,
          Math.round((dataToExport.zones[tenantId].onTimeMissing / 60) * 10) / 10 || 0,
          Math.round((dataToExport.zones[tenantId].thermOnTimeMissing / 60) * 10) / 10 || 0,
          dataToExport.zones[tenantId].power || 0,
          Math.round((dataToExport.zones[tenantId].onTime / 60) * 10) / 10 || 0,
          Math.round((dataToExport.zones[tenantId].thermOnTime / 60) * 10) / 10 || 0,
          "",
          dataToExport.zones[tenantId].excessPower || 0
        ]);

        tenantChunkLine9.push([], []);
        byTimeChunkLine14.push([], []);

      });

      _.forEach(pageData.systems, (sys, sysId) => {
        if (!exportSelections.systems[sysId]) {
          return;
        }

        if (_.isEmpty(sys.units)) {
          return;
        }

        const measureUnit = sys.measurementUnits || "kWh";

        systemsData.push(["System", systems[sysId].name]);
        systemsData.push(["", "Cool", "", "", "Heat", "", "", "Other", "", "", "Estimated", "", "", "Total", "", "", "Availability", "Excess"]);
        systemsData.push(["Unit Name", measureUnit, "Runtime", "Therm on time", measureUnit, "Runtime", "Therm on time", measureUnit, "Runtime", "Therm on time", measureUnit, "Runtime", "Therm on time", measureUnit, "Runtime", "Therm on time", "", measureUnit]);

        const unitNames = sys.units.reduce((names: any, unitId: string) => {
          const item = allUnits[unitId];
          let unitName = item?.name || "";
          if (+item?.type === 3) {
            const controlUnitId: any = item.controlUnit;
            const controlName = allUnits[controlUnitId]?.name || t`Unassigned`;
            unitName = `${controlName} (${item.address})`;
          }

          names[0].push(unitName, "");
          names[1].push(measureUnit, "Runtime");
          return names;
        }, [["", ""], ["Date", "Time"]]);
        unitNames[0].push("Total");
        unitNames[1].push(measureUnit, "Runtime");
        byTimeChunkLine14.push(...unitNames);

        byTimeChunkLine14.push(["System", systems[sysId].name]);

        let breakdownLine;

        const timeArr = dataToExport.systems[sysId][timeArrType];
        for (let timeIndex in timeArr) {
          const timeObj = timeArr[timeIndex];
          breakdownLine = [moment(timeObj.timestamp).format("DD/MM/YYYY"), moment(timeObj.timestamp).format("HH:mm")];

          for (let unitId of dataToExport.systems[sysId].units) {
            const unitObj = dataToExport.units[unitId];
            if (!unitObj) {
              continue;
            }
            const unitObjTimeSlot = unitObj[timeArrType][timeIndex];
            breakdownLine.push(unitObjTimeSlot.power, `${Math.round((unitObjTimeSlot.onTime / 60) * 10) / 10}`);
          }

          breakdownLine.push(timeObj.power, `${Math.round((timeObj.onTime / 60) * 10) / 10}`);
          byTimeChunkLine14.push(breakdownLine);
        }
        byTimeChunkLine14.push([]);
        byTimeChunkLine14.push([]);

        const sortedUnits = _.orderBy(dataToExport.systems[sysId].units, [(unitId: any) => allUnits[unitId]?.name?.toUpperCase()],
          ["asc"]);
        for (let unitId of sortedUnits) {
          const row: any = [];
          const unit = dataToExport.units[unitId];
          if (!unit) {
            continue;
          }
          if (unit?.message === "unit not in associated systems") {
            continue;
          }

          const item = allUnits[unitId];
          let unitName = item?.name || "";
          if (+item?.type === 3) {
            const controlUnitId: any = item.controlUnit;
            const controlName = allUnits[controlUnitId]?.name || t`Unassigned`;
            unitName = `${controlName} (${item.address})`;
          }

          row.push(unitName);

          row.push(unit.powerCool || 0);
          row.push(Math.round((unit.onTimeCool / 60) * 10) / 10 || 0);
          row.push(Math.round((unit.thermOnTimeCool / 60) * 10) / 10 || 0);

          row.push(unit.powerHeat || 0);
          row.push(Math.round((unit.onTimeHeat / 60) * 10) / 10 || 0);
          row.push(Math.round((unit.thermOnTimeHeat / 60) * 10) / 10 || 0);

          row.push(unit.powerOther || 0);
          row.push(Math.round((unit.onTimeOther / 60) * 10) / 10 || 0);
          row.push(Math.round((unit.thermOnTimeOther / 60) * 10) / 10 || 0);

          row.push(unit.powerMissing || 0);
          row.push(Math.round((unit.onTimeMissing / 60) * 10) / 10 || 0);
          row.push(Math.round((unit.thermOnTimeMissing / 60) * 10) / 10 || 0);

          row.push(unit.power || 0);
          row.push(Math.round((unit.onTime / 60) * 10) / 10 || 0);
          row.push(Math.round((unit.thermOnTime / 60) * 10) / 10 || 0);
          row.push(`${"availability" in unit ? unit.availability : 100}%`);
          row.push(unit.excessPower || 0);
          systemsData.push(row);

        }

        systemsData.push([
          "Total",
          dataToExport.systems[sysId].powerCool || 0,
          Math.round((dataToExport.systems[sysId].onTimeCool / 60) * 10) / 10 || 0,
          Math.round((dataToExport.systems[sysId].thermOnTimeCool / 60) * 10) / 10 || 0,
          dataToExport.systems[sysId].powerHeat || 0,
          Math.round((dataToExport.systems[sysId].onTimeHeat / 60) * 10) / 10 || 0,
          Math.round((dataToExport.systems[sysId].thermOnTimeHeat / 60) * 10) / 10 || 0,
          dataToExport.systems[sysId].powerOther || 0,
          Math.round((dataToExport.systems[sysId].onTimeOther / 60) * 10) / 10 || 0,
          Math.round((dataToExport.systems[sysId].thermOnTimeOther / 60) * 10) / 10 || 0,
          dataToExport.systems[sysId].powerMissing || 0,
          Math.round((dataToExport.systems[sysId].onTimeMissing / 60) * 10) / 10 || 0,
          Math.round((dataToExport.systems[sysId].thermOnTimeMissing / 60) * 10) / 10 || 0,
          dataToExport.systems[sysId].power || 0,
          Math.round((dataToExport.systems[sysId].onTime / 60) * 10) / 10 || 0,
          Math.round((dataToExport.systems[sysId].thermOnTime / 60) * 10) / 10 || 0,
          "",
          dataToExport.systems[sysId].excessPower || 0
        ]);

        systemsData.push([], []);
        systemsData.push([], []);

      });

      const powerMeterTitle = ["Power meters"];
      const powerMeterTotals = ["", "Total power reported", "Total distributed power", "Total excess power"];
      _.forEach(pageData.ppds, (powermeter, id) => {
        const { power = 0, excessPower = 0, isVirtual } = powermeter;
        const { name = "" } = powerMeters[id] || {};

        if (isVirtual) {
          return;
        }

        powerData.push([name, power, power - excessPower, excessPower]);
      });

      const sheetData = [line1, timeLine2, siteNameLine3, addressLine4, line5, line6, line7, [""], ...systemsData, ...tenantChunkLine9, tenantAndUnitsChunkSum, breakLine, ...line11, ...byTimeChunkLine14, powerMeterTitle, powerMeterTotals, ...powerData];
      sheet.addRows(sheetData);
      autoWidth(sheet);
    })();

    return workbook;
  };


  const handleClick = async () => {

    if (!fileName) {
      setFormError({ name: t`Reqired` });
      return;
    }

    if (!Object.keys(exportSelections?.zones || {})?.length) {
      setFormError({ options: true });
      return;
    }

    setLoading(true);
    setFormError(null);


    const formatName = ppdTypesMirror[reportFormat];
    const ext = extPerFormat[formatName];
    const fileNameWithExt = `${fileName}.${ext}`;

    const formatFn = formatName === "Hatachana" ? hatakhanaFormat : (formatName === "TAU" ? TAUFormat : CAFormat);
    const useCurrentData = +basicReolution === +selectedGran;

    try {
      const exportData = useCurrentData ? pageData : await getPowerUsageAsyncData(selections.siteId, selectedTime.startTime, selectedTime.endTime, selectedGran, distributeExcessPower);
      const workbook: any = formatFn(exportData);
      const buffer = await workbook[ext].writeBuffer();
      const blob = new Blob([buffer], { type: "applicationi/xlsx" });
      saveAs(blob, fileNameWithExt);
      closeExport();
      setLoading(false);
      setFormat(1);
    } catch (error: any) {
      setLoading(false);
      addMessage({ message: error.message });
    }
  };

  const selectToExport = (type: "reset" | "systems" | "zones", id?: string) => {
    if (type === "reset") {
      setExportSelections({ systems: {}, zones: {} });
      return;
    }

    if (!id) {
      return;
    }

    if (exportSelections[type][id]) {
      const { [id]: toRemove, ...rest } = exportSelections[type];
      setExportSelections({ ...exportSelections, [type]: { ...rest } });
    }
    else {
      setExportSelections({ ...exportSelections, [type]: { ...exportSelections[type], [id]: true } });
    }
  };

  const selectAll = (type: "systems" | "zones", isChecked: boolean) => {
    if (isChecked) {
      const Ids = type === "systems" ? Object.keys(pageData?.systems) : Object.keys(pageData?.zones);
      const idsMap = Ids.reduce((result: any, item: any) => {
        result[item] = true;
        return result;
      }, {});

      setExportSelections({ ...exportSelections, [type]: idsMap });
      return;
    }
    setExportSelections({ ...exportSelections, [type]: {} });
  };

  const closeExport = () => {
    setOpenExport(false);
    selectToExport("reset");
    setDistributeExcessPower(true);
  };

  return (
    <>
      <Button onClick={() => setOpenExport(true)} variant="outlined">{t`Generate Report`}</Button>
      {isLoading &&
        <div style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          zIndex: 9999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>

          <div id="loader" className={styles.redMainLoader}>
          </div>
        </div>
      }
      {
        openExport &&
        <Dialog
          onClose={closeExport}
          open={openExport}
          maxWidth={"xl"}
        >
          {isLoading && <div className={classes.progressbar}>
            <LinearProgressBar durationInSeconds={WAITING_DELAY} />
          </div>}
          <DialogTitle>{t`Generate Report`}</DialogTitle>

          <div className={classes.exportContent}>
            <div style={{ width: "100%" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={classes.exportField}>
                  <Typography className={clsx(formError?.name?.length && classes.hasError)}>{t`Report Name`}</Typography>
                  <TextField
                    className={classes.exportSelectField}
                    InputProps={{ classes: { root: classes.exportNameFieldRoot } }}
                    variant="outlined"
                    onChange={(e: any) => setFileName(e.target.value)}
                    error={formError && formError.name}
                  />
                  {!!formError?.name?.length && <span style={{ color: "red" }}>{formError?.name}</span>}
                </div>
                <InputLabel className={clsx(classes.itemName, classes.excessLabel)}>
                  <Checkbox
                    checked={distributeExcessPower}
                    onChange={({ target: { checked } }: any) => setDistributeExcessPower(checked)}
                    className={classes.excessCheckbox}
                  />
                  {t`Distribute Excess Power to all units`}
                </InputLabel>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center"
                  // justifyContent: "space-between"
                }}
              >
                <div className={classes.exportField}>
                  <Typography className={classes.exportNameFieldTitle}>{t`Granularity`}</Typography>
                  <Select className={classes.exportSelectField} variant={"outlined"} value={selectedGran} onChange={(e: any) => setGran(e.target.value)}>
                    <MenuItem value={1}>{t`1 Hour`}</MenuItem>
                    <MenuItem value={2}>{t`1 Day`}</MenuItem>
                  </Select>
                </div>
                {!!siteObj?.supportedReportTypes?.length &&
                  <div className={classes.exportField} style={{ marginLeft: "80px" }}>
                    <Typography className={classes.exportNameFieldTitle}>{t`Format`}</Typography>
                    <Select className={classes.exportSelectField} variant={"outlined"} value={reportFormat} onChange={(e: any) => setFormat(+e.target.value)}>
                      {
                        siteObj?.supportedReportTypes?.map((type: any) => (
                          <MenuItem key={`type-${type}`} value={+type}>{`${ppdTypesMirror[+type]}`}</MenuItem>
                        ))
                      }
                    </Select>
                  </div>
                }
              </div>

            </div>
            <Divider
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "#cacaca",
                marginBottom: "10px"
              }}
            />

            <div className={clsx(classes.exportOptions, formError?.options && classes.sectionHasError)}>
              <div className={classes.exportOptionsSection}>
                <Typography className={classes.sectionTitle}>{t`Select Tenants`}</Typography>
                {!isEmpty(pageData) && Object.keys(pageData.zones)?.length > 1 &&
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Object.keys(exportSelections?.zones)?.length === Object.keys(pageData.zones)?.length}
                          indeterminate={Object.keys(exportSelections?.zones)?.length !== Object.keys(pageData.zones)?.length && Object.keys(exportSelections?.zones)?.length > 0}
                          onChange={(e: any) => selectAll("zones", e.target.checked)}
                        />
                      }
                      label={t`All Tenants`}
                      title={t`All Tenants`}
                    />
                  </FormGroup>}
                {
                  pageData?.zones && _.orderBy(pageData.zones, [(item: any) => item?.name?.toUpperCase()],
                    ["asc"])?.map((zone1: any) => {
                      const zId = zone1?.id;
                      const zone: any = zones[zId];
                      const hasUnits = zone1?.indoorUnits?.length || zone1?.outdoorUnits?.length;
                      const isChecked = !!exportSelections?.zones[zId];
                      return (
                        <FormGroup key={`selection-${zId}`}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isChecked}
                                onChange={() => selectToExport("zones", zId)}
                                disabled={!hasUnits}
                              />
                            }
                            label={zone?.name}
                            title={!hasUnits ? t`This System have no units in it` : ""}
                          />
                        </FormGroup>
                      );
                    })
                }
              </div>

            </div>

            <div className={classes.exportBtns}>
              <Button variant="outlined" onClick={closeExport}>{t`Cancel`}</Button>
              <Button color={"primary"} variant="contained" onClick={handleClick}>{t`Download`}</Button>
            </div>
          </div>
        </Dialog>
      }
    </>
  );
}
