import { Card, IconButton, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import clsx from "clsx";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { t } from "ttag";
import {
  OnGrey as SvgPowerOff,
  OnWhite as SvgPowerOn
} from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ModeMenu from "../ModeMenu/ModeMenu";
import Tooltip from "../Tooltip/LightTooltip";
import useStyles from "./UnitControl.style";

interface IProps {
  unit: string;
  temperatureScale: number | undefined;
  device: any;
  isWaterHeater: boolean;
}
interface IObject {
  [key: string]: any;
}

const defaultKeys = { modeKey: "activeOperationMode", powerKey: "activeOperationStatus", ambientTempKey: "ambientTemperature", setpointKeyPerMode: { 0: "activeSetpoint", 1: "activeSetpoint", 2: "activeSetpoint", 3: "activeSetpoint", 5: "activeSetpoint" } };
const keysBySubType: any = {
  0: defaultKeys,
  1: { modeKey: "heaterMode", powerKey: "heaterTankONOFF", ambientTempKey: "heaterRoomTemp", setpointKeyPerMode: { 0: "heaterCoolingSP", 1: "heaterHeatingSP", 2: "", 3: "", 5: "" } },
  2: defaultKeys
};

const defaultMin = 0;
const defaultMax = [100, 140];
const defaultTempLimits = { 1: [16, 32], 2: [60, 90] };
const waterHeaterModesMap: any = { 0: 2, 1: 1, 2: 0 };
const waterHeaterModesToNormalMap: any = { 0: 2, 1: 1, 2: 0 };

export default function UnitControl(props: IProps) {
  const classes = useStyles();

  const { device } = props;
  const setActiveSetpoint = useStoreActions((action) => action.units.setActiveSetpoint);
  const unitTogglePower = useStoreActions((action) => action.units.togglePower);
  const getUnitByIdSDK = useStoreActions((action) => action.units.getUnitByIdSDK);
  const setActiveOperationMode = useStoreActions((action) => action.units.setActiveOperationMode);
  const userTemperatureScale = useStoreState((s) => s.users.me.temperatureScale || 1);
  const allUnits = useStoreState((s) => s.units.allUnits);
  const serviceTypes = useStoreState((s) => s.types);
  const { waterHeaterOperationStatusesEnum: operationStatuses } = serviceTypes;
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const operationStatusesMirror = useStoreState((state) => state.waterHeaterOperationStatusesMirror);
  const { temperatureScaleMirror } = useStoreState((state) => state);
  const isCelsius = +temperatureScaleMirror.celsius === +userTemperatureScale;
  const { operationModesMirror } = useStoreState((state) => state);

  const {
    updateWaterHeaterDHWTankSwitch,
    updateWaterHeaterMode,
    updateWaterHeaterCoolingSetpoint,
    updateWaterHeaterHeatingSetpoint,
    updateUnitLocally
  } = useStoreActions((action) => action.units);

  const { on: powerOnVal, off: powerOffVal } = operationStatusesMirror;

  const [unitTemp, setUnitTemp] = useState<any>(0);
  const [unit, setUnit] = useState<any>({});

  const {
    supportedOperationModes: supportedModes,
    enableCoolMode,
    enableHeatMode,
    enableAutoMode,
    temperatureLimits,
    enableSetpoint,
    enableMode,
    enableOnState,
    enableOnoff,
    isHalfCDegreeEnabled,
    subType = 0,
    capabilityFlags = {},
    permissions = {}
  } = unit;

  const { canControlOperationMode, canControlOperationStatus, canControlSetpoint } = permissions;

  const addScale = isHalfCDegreeEnabled && isCelsius ? 0.5 : 1;
  const isWaterHeater = subType === 1;
  const {
    enableOperationStatusSelection = false, enableOperationModeSelection = false,
    enableSetpointControl = false, setpointControlModes = {}
  } = capabilityFlags;

  const enabledMode: IObject = {
    0: enableCoolMode,
    1: enableHeatMode,
    2: enableAutoMode,
    5: true
  };

  const { modeKey, powerKey, setpointKeyPerMode } = keysBySubType[subType] || defaultKeys;
  const mappedMode = unit[modeKey];
  const mode = isWaterHeater ? waterHeaterModesToNormalMap[mappedMode] : mappedMode;
  const activeSetpoint = unit[setpointKeyPerMode[mode]];
  const power = unit[powerKey];
  const allowedToControlSetpoint = setpointControlModes[mode] && enableSetpointControl && enableOperationModeSelection;

  useEffect(() => {
    if (!unit || !allUnits || _.isEmpty(allUnits)) {
      setUnit({});
      return;
    }
    getUnitByIdSDK(props.unit).then((unit: any) => {
      setUnit(unit || {});
    })
  }, [props.unit, allUnits]);

  useEffect(() => {
    if (isNaN(activeSetpoint)) {
      return;
    }
    const newVal = isHalfCDegreeEnabled && isCelsius ? Math.floor(activeSetpoint * 2) / 2 : Math.round(activeSetpoint);
    setUnitTemp(newVal);
  }, [activeSetpoint, subType === 1 ? mode : false]);

  const delayedSetpointChange = useCallback(_.debounce((newValue: any, mode: any) => {
    if (isWaterHeater) {
      const API = mode === +operationModesMirror.HEAT ? updateWaterHeaterHeatingSetpoint : updateWaterHeaterCoolingSetpoint;
      API({ id: unit.id, data: { setpoint: newValue } })
        .then(() => {
          updateUnitLocally({
            id: unit.id, unit: mode === +operationModesMirror.HEAT ?
              { heaterHeatingSP: newValue } : { heaterCoolingSP: newValue }
          });
        })
        .catch((err: any) => {
          const newVal = isHalfCDegreeEnabled && isCelsius ? Math.floor(activeSetpoint * 2) / 2 : Math.round(activeSetpoint);
          setUnitTemp(newVal);
          addMessage({ message: err.message });
        });
      return;
    }

    setActiveSetpoint({ id: unit.id, setpoint: newValue })
      .catch((err: any) => {
        addMessage({ message: err.message });
        const newVal = isHalfCDegreeEnabled && isCelsius ? Math.floor(activeSetpoint * 2) / 2 : Math.round(activeSetpoint);
        setUnitTemp(newVal);
      });
  }, 1000), [unit]);

  const temperatureChange = (num: number) => {
    const updatedSetPoint = unitTemp + num;
    setUnitTemp(updatedSetPoint);
    delayedSetpointChange(updatedSetPoint, mode);
  };

  const togglePower = () => {
    if (isWaterHeater) {
      const value = +(operationStatuses[power] === "on" ? powerOffVal : powerOnVal);
      setUnit({ ...unit, [powerKey]: value });
      updateWaterHeaterDHWTankSwitch({ id: unit.id, data: { operationStatus: value } })
        .catch((err: any) => {
          setUnit({ ...unit, [powerKey]: value });
          addMessage({ message: err.message });
        });
    } else {
      const toggleStates = [1, 2, 1];
      const value = unit[powerKey];
      setUnit({ ...unit, [powerKey]: toggleStates[power] });
      unitTogglePower({
        id: unit.id,
        activeOperationStatus: value
      })
        .catch((err: any) => {
          setUnit({ ...unit, activeOperationStatus: value });
          addMessage({ message: err.message });
        });
    }
  };

  const switchMode = (mode: any) => {
    if (isWaterHeater) {
      const waterHeaterEnum = waterHeaterModesMap[mode];
      updateWaterHeaterMode({ id: unit.id, data: { operationMode: waterHeaterEnum } })
        .then(() => updateUnitLocally({ id: unit.id, unit: { [modeKey]: waterHeaterEnum } }))
        .catch((err: any) => addMessage({ message: err.message }));
      return;
    }
    const value = unit[modeKey];
    setUnit({ ...unit, [modeKey]: mode });
    setActiveOperationMode({ id: unit.id, mode })
      .catch((err: any) => {
        setUnit({ ...unit, [modeKey]: value });
        addMessage({ message: err.message });
      });
  };

  if (!unit) {
    return null;
  }

  const limits = isCelsius ? defaultTempLimits[1] : defaultTempLimits[2];
  const isUpperLimit = !enabledMode[mode] ? (unitTemp > (isCelsius ? defaultMax[0] : defaultMax[1])) : ((temperatureLimits[mode] ? temperatureLimits[mode][1] : limits[1]) - 1 < unitTemp);
  const isLowerLimit = !enabledMode[mode] ? (unitTemp < defaultMin) : ((temperatureLimits[mode] ? temperatureLimits[mode][0] : limits[0]) + 1 > unitTemp);

  return (
    <Card className={clsx(classes.card, { [classes.disableClicks]: !unit.isConnected, [classes.smallCard]: isWaterHeater })}>
      {(allowedToControlSetpoint) &&
        <Tooltip title={enableSetpoint ? t`Unit is restricted , Please check with the administrator` : (canControlSetpoint && device.isConnected && !enableSetpoint) ? t`Change unit Setpoint` : ""}>
          <div className={classes.controlContainer}>
            {(canControlSetpoint) && <div className={clsx(classes.controlWrapper, { [classes.disableClick]: !device.isConnected || enableSetpoint, [classes.hidden]: isUpperLimit })} onClick={() => temperatureChange(addScale)}>
              <KeyboardArrowUpIcon className={classes.arrowIcon} />
            </div>}
            <Typography className={classes.Number}>
              <span className={classes.setpoint}>{unitTemp}</span>
              <span className={classes.temperatureScale}>
                {userTemperatureScale === 1 ? "°C" : "°F"}
              </span>
            </Typography>
            <Typography display={"inline"} className={classes.minorTempTitleStyle}>
              {t`Setpoint` + `(${unit?.heaterTempControl === 0 ? t`LWT` : t`RC`})`}
            </Typography>
            {(canControlSetpoint) && <div className={clsx(classes.controlWrapper, { [classes.disableClick]: !device.isConnected || enableSetpoint, [classes.hidden]: isLowerLimit })} onClick={() => temperatureChange(-addScale)}>
              <KeyboardArrowDownIcon className={classes.arrowIcon} />
            </div>}
          </div>
        </Tooltip>}

      {enableOperationModeSelection &&
        <Tooltip title={supportedModes.length === 0 ? t`Unit is restricted, please check with the administrator` : !device.isConnected || enableMode ? "" : t`Change Unit Operation Mode`}>
          <div className={clsx(classes.controlContainer, { [classes.smallControlContainer]: isWaterHeater })} >
            <ModeMenu
              onClick={switchMode}
              unit={unit}
              supportedModes={supportedModes}
              serviceTypes={serviceTypes}
              disabled={!canControlOperationMode || !device.isConnected || enableMode || supportedModes.length === 0}
            />
          </div>
        </Tooltip>}
      {enableOperationStatusSelection &&
        <Tooltip title={enableOnoff || (power === 2 && enableOnState) ? t`Unit is restricted , Please check with the administrator` : canControlOperationStatus ? t`Turn unit on/off` : ""}>
          <div className={clsx(classes.controlContainer, { [classes.smallControlContainer]: isWaterHeater })} >
            <IconButton
              disabled={!canControlOperationStatus || !device.isConnected || enableOnoff || (power === 2 && enableOnState)}
              onClick={togglePower} className={clsx(classes.powerButton)}>
              {power === 1 ? (
                <div className={classes.powerOn}>
                  <SvgPowerOn transform="scale(1.5)" className={classes.powerButtonSVG} />
                </div>
              ) : (
                <div className={classes.powerOff}>
                  <SvgPowerOff transform="scale(1.5)" className={classes.powerButtonSVG} />
                </div>
              )}
            </IconButton>
          </div>
        </Tooltip>}
    </Card>
  );
}
