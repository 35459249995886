import {
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Slider,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import StartEndTimePicker from "../../components/StartEndTimePicker/StartEndTimePicker";
import TimePicker from "../../components/TimePickerSettings/TimePicker";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import { modeIcons } from "../../constants/modes";
import SButton from "../../cool_widgets/Button";
import { CoolSwitch } from "../../cool_widgets/CoolSwitch";
import DatesList from "../../cool_widgets/DatesList/DatesList";
import DaysList from "../../cool_widgets/DaysList/DaysList";
import { Close } from "../../icons";
import { EditIcon } from "../../logos";
import { useStoreState } from "../../models/RootStore";
import assetsService from "../../services/assetsService";
import { minsToTime, stringTimeToUTCMins } from "../../services/timeService";
import useStyles from "./Settings.style";
import SwitchItem from "./SwitchItem";

const UnitSetting: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const temperatureScale = props.useTemperatureScale;
  const user = useStoreState((state) => state.users.me);
  const timeFormat = useStoreState((state) => state.users.timeFormat);
  const types = useStoreState((states) => states.types);
  const [scheduleName, setScheduleName] = useState<string | undefined>(undefined);
  const endActionTypesArr: any = Object.values(types.endActionTypes);
  const [endActionType, setEndActionType] = useState<any>(endActionTypesArr[1] ? endActionTypesArr[1].value : "");
  const [openPicker, setOpenPicker] = useState<string>("");
  const [powerOnTime, setPowerOnTime] = useState<string | undefined>(undefined);
  const [powerOffTime, setPowerOfftime] = useState<string | undefined>(undefined);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isSavingInprogress, setIsSavingInprogress] = useState<boolean>(false);
  const [weekDays, setWeekDays] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});
  const [allDay, setAllDay] = useState<boolean>(true);
  const { timeFormat: timeFormatTypes } = types;
  const { timeFormat: myTimeFormat } = user;
  const { canEditScheule } = props;
  const timeFormatObject = myTimeFormat ? timeFormatTypes[myTimeFormat] : timeFormatTypes[0]; //default 24 hours
  const is12Hours = timeFormatObject.text === "12 hours" ? true : false;

  const { weekDays: weekDaysTypes = [] } = types;
  const weekDaysArray = Object.keys(weekDaysTypes);

  const [supportedValues, setSupportedValues] = useState<any>({
    supportedOperationModes: [],
    supportedFanModes: [],
    supportedSwingModes: [],
    temperatureLimits: {
      0: temperatureScale === 1 ? [5, 28] : [41, 82],
      1: temperatureScale === 1 ? [15, 34] : [59, 93],
      2: temperatureScale === 1 ? [0, 28] : [32, 82]
    }
  });
  const [tempLimitFlags, setTempLimitFlags] = useState<any>({
    enableCoolMode: false,
    enableHeatMode: false,
    enableAutoMode: false
  });
  const [tStatFlags, setTStatFlags] = useState<any>({
    enableOnoff: false,
    enableMode: false,
    enableSetpoint: false
  });

  const { capabilityFlags = {} } = props;
  const {
    enableWrcSettingsOnOffLock = false, enableWrcSettingsSetpointChangeLock = false,
    enableWrcSettingsModeLock = false, enableWrcSettingsLimitCoolingSetpoint = false,
    enableWrcSettingsLimitHeatingSetpoint = false
  } = capabilityFlags;

  const hasValue = (value: any) => {
    return !!value || value === 0;
  };
  const { scheduleCategories } = types;
  const { canEdit, selectedSchedule } = props;
  const { dates: selectedDates = [] } = selectedSchedule || {};
  const [scheduleCategory, setScheduleCategory] = useState<number>(1);
  const [dates, setDates] = useState<any>(undefined);

  const updateTstatFlags = (key: string, value: boolean) => setTStatFlags({ ...tStatFlags, [key]: value });
  const handleDayClick = (date: any, { selected }: any) => {
    const selectedDates: any = dates ? dates.concat() : [];
    if (selected) {
      const selectedIndex = selectedDates.findIndex((selectedDate: any) =>
        DateUtils.isSameDay(selectedDate, date)
      );
      selectedDates.splice(selectedIndex, 1);
    } else {
      selectedDates.push(date);
    }
    setDates(selectedDates);
  };
  const today = new Date();

  const aYearFromToday = new Date();
  aYearFromToday.setFullYear(today.getFullYear() + 1);

  useEffect(() => {
    if (props.selectedSchedule) {
      const values: any = {};
      const schedule = _.cloneDeep(props.selectedSchedule);
      if (schedule) {
        setEndActionType(schedule.endActionType || Object.values(types.endActionTypes)[1]);
        if (!props.idDeviceLimit) {
          values.temperatureLimits = schedule.temperatureLimits;
          setTStatFlags({
            enableOnoff: schedule.enableOnoff || false,
            enableMode: schedule.enableMode || false,
            enableSetpoint: schedule.enableSetpoint || false
          });
          setTempLimitFlags({
            enableCoolMode: schedule.enableCoolMode || false,
            enableHeatMode: schedule.enableHeatMode || false,
            enableAutoMode: schedule.enableAutoMode || false
          });
        }

        if (props.idDeviceLimit) {
          values.temperatureLimits = schedule.eWrcTemperatureLimits;
          setTStatFlags({
            enableOnoff: schedule.eWrcEnableOnoff || false,
            enableMode: schedule.eWrcEnableMode || false,
            enableSetpoint: schedule.eWrcEnableSetpoint || false
          });
          setTempLimitFlags({
            enableCoolMode: schedule.eWrcEnableCoolMode || false,
            enableHeatMode: schedule.eWrcEnableHeatMode || false,
            enableAutoMode: schedule.eWrcEnableAutoMode || false
          });
        }
        setScheduleName(schedule.name);
        setWeekDays(schedule.days);
        setDates(canEdit ? selectedDates.map((date: any) => new Date(date)) : selectedDates);
        setIsDisabled(schedule.isDisabled);
        setScheduleCategory(schedule.scheduleCategory);

      }

      const { powerOnTime, powerOffTime } = schedule;
      if (powerOnTime === 0 && powerOffTime === 1439) {
        setAllDay(true);
      } else {
        setAllDay(false);
      }
      setPowerOnTime(hasValue(powerOnTime) ? minsToTime(powerOnTime, timeFormat) : "");
      setPowerOfftime(hasValue(powerOffTime) ? minsToTime(powerOffTime, timeFormat) : "");

      setSupportedValues(values);
    } else {
      setTimeAllDay();
    }
    setErrors({});

  }, [props.selectedSchedule, is12Hours]);

  const setTimeAllDay = () => {
    let startHour, endHour;
    if (is12Hours) {
      startHour = "12".padStart(2, "0") + ":" + "00".padStart(2, "0") + "am";
      endHour = "11".padStart(2, "0") + ":" + "59".padStart(2, "0") + "pm";
    } else {
      startHour = "00".padStart(2, "0") + ":" + "00".padStart(2, "0");
      endHour = "23".padStart(2, "0") + ":" + "59".padStart(2, "0");
    }
    setPowerOnTime(startHour);
    setPowerOfftime(endHour);
  };

  const valueLabelFormat = (value: number) => {
    const valueToShow = value + (temperatureScale === 1 ? " °C" : " °F");
    return `${valueToShow}`;
  };
  const getOnEnd = () => {
    const options: any = {
      "noAction": "no action",
      "revert": "Return to previous settings",
      "doNothing": "Keep schedule settings",
      "allowAll": "Allow all"
    };
    const actionTypesOptions = Object.values(types.endActionTypes).filter((type: any) => {
      return type.text !== "noAction";
    });
    return <Select
      disabled={!canEditScheule}
      labelId="endActionType"
      value={endActionType}
      className={classes.endActionTypeSelect}
      onChange={(event: any) => handleSelectEndActionType(event.target.value)}
      variant="outlined"
      disableUnderline
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        getContentAnchorEl: null
      }}
    >
      {actionTypesOptions.map((type: any) => (
        <MenuItem key={type.value} value={type.value}>{options[type.text]}</MenuItem>
      ))}
    </Select>;
  };

  const toggleAllDay = () => {
    if (allDay) {
      setAllDay(false);
    }
    else {
      setAllDay(true);
      let startHour, endHour;
      if (is12Hours) {
        startHour = "12".padStart(2, "0") + ":" + "00".padStart(2, "0") + "am";
        endHour = "11".padStart(2, "0") + ":" + "59".padStart(2, "0") + "pm";
      } else {
        startHour = "00".padStart(2, "0") + ":" + "00".padStart(2, "0");
        endHour = "23".padStart(2, "0") + ":" + "59".padStart(2, "0");
      }
      setPowerOnTime(startHour);
      setPowerOfftime(endHour);
    }

  };

  const handleSetCooling = (event: any, newValue: number | number[]) => {
    const locValues: any = { ...supportedValues };
    locValues.temperatureLimits[0] = newValue;
    setSupportedValues(locValues);
  };

  const handleSetHeat = (event: any, newValue: number | number[]) => {
    const locValues: any = { ...supportedValues };
    locValues.temperatureLimits[1] = newValue;
    setSupportedValues(locValues);
  };

  const handleSetAuto = (event: any, newValue: number | number[]) => {
    const locValues: any = { ...supportedValues };
    locValues.temperatureLimits[2] = newValue;
    setSupportedValues(locValues);
  };

  const handleSetTempLimitRange = (flagName: string) => {
    const nextFlagState = _.clone(tempLimitFlags);
    nextFlagState[flagName] = !nextFlagState[flagName];
    setTempLimitFlags(nextFlagState);
  };
  const checkValidate = (onSave = false) => {
    setErrors({});
    const newErrors: any = {};
    if ((onSave || scheduleName !== undefined) && !scheduleName) {
      newErrors["scheduleName"] = "Please enter name";
    }

    if ((onSave || weekDays !== undefined) && !weekDays?.length && scheduleCategory === scheduleCategories.weekly) {
      newErrors["weekDays"] = "Please select at least one day";
    }

    if ((onSave || dates !== undefined) && !dates?.length && scheduleCategory === scheduleCategories.calendar) {
      newErrors["dates"] = "Please select at least one day";
    }

    if (onSave || (powerOnTime !== undefined && powerOffTime !== undefined)) {
      const onTime = stringTimeToUTCMins(powerOnTime || "", is12Hours);
      const offTime = stringTimeToUTCMins(powerOffTime || "", is12Hours);

      if (onTime > offTime) {
        newErrors["time"] = "Start time is after end time, please enter valid time";
      }
      if (_.isNil(powerOnTime) || powerOnTime === "" || _.isNil(powerOffTime) || powerOffTime === "") {
        newErrors["time"] = "Add start and end hour";
      }
    }

    if (JSON.stringify(newErrors) !== JSON.stringify(errors))
      setErrors(newErrors);

    for (const key in newErrors)
      if (!!newErrors[key])
        return false;
    return true;
  };

  const addRemoveDay = (selectedDay: string) => {
    let currentDays: any = [...[], ...weekDays];

    currentDays.includes(selectedDay)
      ? (currentDays = weekDays.filter((day: any) => day !== selectedDay))
      : currentDays.push(selectedDay);

    setWeekDays(currentDays);
  };

  const handleSave = async () => {

    const onTime = stringTimeToUTCMins(powerOnTime || "", is12Hours);
    const offTime = stringTimeToUTCMins(powerOffTime || "", is12Hours);

    if (!checkValidate(true))
      return;
    setIsSavingInprogress(true);
    const defaultEndActionToSave = endActionType || types.endActionTypes.revert.value;
    const daysOrDates = scheduleCategory === scheduleCategories.weekly ? { days: weekDays } : { dates };

    const data = {
      temperatureLimits: supportedValues.temperatureLimits,
      enableOnoff: tStatFlags.enableOnoff || false,
      enableMode: tStatFlags.enableMode || false,
      enableSetpoint: tStatFlags.enableSetpoint || false,
      enableOnState: tStatFlags.enableOnState || false,
      enableCoolMode: tempLimitFlags.enableCoolMode || false,
      enableHeatMode: tempLimitFlags.enableHeatMode || false,
      enableAutoMode: tempLimitFlags.enableAutoMode || false,
      name: scheduleName,
      ...daysOrDates,
      powerOnTime: onTime,
      powerOffTime: offTime,
      endActionType: defaultEndActionToSave,
      scheduleCategory
    };
    await props.onSave(data);
    props.onCancle();
    setIsSavingInprogress(false);
  };
  const updateDisableState = () => {
    setIsDisabled(!isDisabled);
    props.onDisableUpdate({ isDisabled: !isDisabled, id: props.selectedSchedule.id });
  };

  const handleCancel = () => {
    props.onCancle();
  };

  const getHeatTempRange = (viewOnly = false) => {
    const limitsTitleText = props.canEdit || tempLimitFlags.enableHeatMode ? t`Limit heating setpoint range` : t`No heat temp limits`;
    const wrcTitleText = props.canEdit || tempLimitFlags.enableHeatMode ? t`WRC Limit heating setpoint range` : t`No heating restrictions`;

    return (
      <Card
        className={clsx(classes.modeContainer, classes.schedulerContainer, classes.wrcScheduleModeContainer, {
          [classes.viewModeContainer]: !props.canEdit
        }, {
          [classes.editModeContainer]: props.canEdit,
          [classes.isWrc]: props.wrcLimits
        })}
      >
        <div className={clsx(classes.modeHeader, classes.tempRangSlider)}>
          <div className={clsx(classes.modeTitle, classes.unitModeTitle)}>
            <img
              src={assetsService.getImagesPath(`${modeIcons[1]}.svg`)}
            ></img>
            {!props.idDeviceLimit &&
              <Typography className={classes.unitTitle}>
                {limitsTitleText}</Typography>
            }

            {props.idDeviceLimit &&
              <Typography className={classes.unitTitle}>
                {wrcTitleText}</Typography>
            }

          </div>

          {props.canEdit &&
            <CoolSwitch
              disabled={!canEditScheule}
              checked={tempLimitFlags.enableHeatMode}
              onChange={() =>
                handleSetTempLimitRange("enableHeatMode")
              }
            />
          }
        </div>
        {tempLimitFlags.enableHeatMode &&
          <div className={classes.sliderAndLabelContainer}>
            <Typography className={classes.leftLabelForamt}>{valueLabelFormat(supportedValues.temperatureLimits[1][0])}</Typography>
            <Slider
              className={classes.heatSlider}
              onChange={handleSetHeat}
              aria-labelledby="discrete-slider-always"
              min={temperatureScale === 1 ? 0 : 32}
              max={temperatureScale === 1 ? 50 : 122}
              value={supportedValues.temperatureLimits[1]}
              valueLabelDisplay="off"
              step={1}
              disabled={!tempLimitFlags.enableHeatMode || !canEditScheule || viewOnly}
            />
            <Typography className={classes.rightLabelForamt}>{valueLabelFormat(supportedValues.temperatureLimits[1][1])}</Typography>
          </div>
        }

      </Card>
    );
  };

  const getCoolTempRange = (viewOnly = false) => {
    const limitsTitleText = props.canEdit || tempLimitFlags.enableCoolMode ? t`Limit cooling setpoint range` : t`no cool temp limits`;
    const wrcTitleText = props.canEdit || tempLimitFlags.enableCoolMode ? t`WRC Limit cooling setpoint range` : t`No cooling restrictions`;

    return (
      <Card
        className={clsx(classes.modeContainer, classes.schedulerContainer, classes.wrcScheduleModeContainer, {
          [classes.viewModeContainer]: !props.canEdit
        }, {
          [classes.editModeContainer]: props.canEdit,
          [classes.isWrc]: props.wrcLimits
        })}
      >
        <div className={clsx(classes.modeHeader, classes.tempRangSlider)}>
          <div className={clsx(classes.modeTitle, classes.unitModeTitle)}>
            <img
              src={assetsService.getImagesPath(`${modeIcons[0]}.svg`)}
            ></img>
            {!props.idDeviceLimit &&
              <Typography className={classes.unitTitle}>
                {limitsTitleText}</Typography>
            }

            {props.idDeviceLimit &&
              <Typography className={classes.unitTitle}>
                {wrcTitleText}</Typography>
            }
          </div>
          {props.canEdit &&
            <CoolSwitch
              disabled={!canEditScheule}
              checked={tempLimitFlags.enableCoolMode}
              onChange={() =>
                handleSetTempLimitRange("enableCoolMode")
              }
            />
          }
        </div>
        {tempLimitFlags.enableCoolMode &&
          <div className={classes.sliderAndLabelContainer}>
            <Typography className={classes.leftLabelForamt}>{valueLabelFormat(supportedValues.temperatureLimits[0][0])}</Typography>
            <Slider
              className={classes.coolSlider}
              onChange={handleSetCooling}
              aria-labelledby="discrete-slider-always"
              min={temperatureScale === 1 ? 0 : 32}
              max={temperatureScale === 1 ? 50 : 122}
              value={supportedValues.temperatureLimits[0]}
              valueLabelDisplay="off"
              step={1}
              disabled={!tempLimitFlags.enableCoolMode || !canEditScheule || viewOnly}
            />
            <Typography className={classes.rightLabelForamt}>{valueLabelFormat(supportedValues.temperatureLimits[0][1])}</Typography>
          </div>
        }

      </Card>

    );
  };

  const getAutoTempRange = (viewOnly = false) => {
    const limitsTitleText = props.canEdit || tempLimitFlags.enableAutoMode ? t`Limit auto setpoint range` : t`no Auto temp limits`;

    return (
      <Card
        className={clsx(classes.modeContainer, classes.schedulerContainer, {
          [classes.viewModeContainer]: !props.canEdit
        }, {
          [classes.editModeContainer]: props.canEdit,
          [classes.isWrc]: props.wrcLimits
        })}
      >
        <div className={clsx(classes.modeHeader, classes.tempRangSlider)}>
          <div className={clsx(classes.modeTitle, classes.unitModeTitle)}>

            <img
              src={assetsService.getImagesPath(`auto.svg`)}
            ></img>
            <Typography className={classes.unitTitle}>
              {limitsTitleText}</Typography>
          </div>
          {props.canEdit &&
            <CoolSwitch
              disabled={!canEditScheule}
              checked={tempLimitFlags.enableAutoMode}
              onChange={() =>
                handleSetTempLimitRange("enableAutoMode")
              }
            />
          }

        </div>
        {tempLimitFlags.enableAutoMode &&
          <div className={classes.sliderAndLabelContainer}>
            <Typography className={classes.leftLabelForamt}>{valueLabelFormat(supportedValues.temperatureLimits[2][0])}</Typography>
            <Slider
              className={classes.autoSlider}
              onChange={handleSetAuto}
              aria-labelledby="discrete-slider-always"
              min={temperatureScale === 1 ? 0 : 32}
              max={temperatureScale === 1 ? 50 : 122}
              value={supportedValues.temperatureLimits[2]}
              valueLabelDisplay="off"
              step={1}
              disabled={!tempLimitFlags.enableAutoMode || !canEditScheule || viewOnly}
            />
            <Typography className={classes.rightLabelForamt}>{valueLabelFormat(supportedValues.temperatureLimits[2][1])}</Typography>
          </div>
        }

      </Card>
    );
  };

  const getEditLimits = (viewOnly = false) => {
    if (!props.canEdit) {
      let enabledModesLength = tempLimitFlags.enableHeatMode ? 1 : 0;
      enabledModesLength = tempLimitFlags.enableCoolMode ? enabledModesLength + 1 : enabledModesLength;
      enabledModesLength = tempLimitFlags.enableAutoMode ? enabledModesLength + 1 : enabledModesLength;

      return (
        < div className={classes.modeCardUnitScheduleSettings}>

          <div className={clsx(classes.viewLimitsSchedulerLineGroup,
            { [classes.viewLimitsSchedulerLineGroup]: enabledModesLength >= 2 },
            { [classes.limitsSchedulerLineGroup]: enabledModesLength < 2 }
          )}>
            {(enableWrcSettingsLimitCoolingSetpoint) && getCoolTempRange(viewOnly)}
            {(enableWrcSettingsLimitHeatingSetpoint) && getHeatTempRange(viewOnly)}
            {!props.idDeviceLimit && getAutoTempRange(viewOnly)}
          </div>

        </div>
      );
    }

    return (
      < div className={classes.modeCardUnitScheduleSettings}>

        <div className={classes.limitsSchedulerLineGroup}>
          {enableWrcSettingsLimitCoolingSetpoint && getCoolTempRange(viewOnly)}
          {enableWrcSettingsLimitHeatingSetpoint && getHeatTempRange(viewOnly)}
          {!props.idDeviceLimit && getAutoTempRange(viewOnly)}
        </div>

      </div>
    );
  };

  const getEditTStats = (viewOnly = false) => {

    return (
      <>
        {(enableWrcSettingsOnOffLock && ((tStatFlags.enableOnoff && viewOnly) || props.canEdit)) &&
          <SwitchItem
            classes={{ switchContainerProps: classes.switchItem }}
            disabled={!canEditScheule}
            onChange={(value: boolean) => updateTstatFlags("enableOnoff", !value)}
            value={!tStatFlags.enableOnoff}
            title={t`Restrict On/OFF`}
            hideSwitch={!props.canEdit}
          />}
        {(enableWrcSettingsModeLock && ((tStatFlags.enableMode && viewOnly) || props.canEdit)) &&
          <SwitchItem
            classes={{ switchContainerProps: classes.switchItem }}
            disabled={!canEditScheule}
            onChange={(value: boolean) => updateTstatFlags("enableMode", !value)}
            value={!tStatFlags.enableMode}
            title={t`Restrict Mode setting`}
            hideSwitch={!props.canEdit}
          />}
        {(enableWrcSettingsSetpointChangeLock && ((tStatFlags.enableSetpoint && viewOnly) || props.canEdit)) &&
          <SwitchItem
            classes={{ switchContainerProps: classes.switchItem }}
            disabled={!canEditScheule}
            onChange={(value: boolean) => updateTstatFlags("enableSetpoint", !value)}
            value={!tStatFlags.enableSetpoint}
            title={t`Restrict Setpoint Change`}
            hideSwitch={!props.canEdit}
          />}
      </>
    );
  };

  const handleSelectEndActionType = (type: any) => {
    setEndActionType(type);
  };
  const onSetTime = (openPicker: string, time: string) => {
    openPicker === "start" ? setPowerOnTime(time) : setPowerOfftime(time);
  };

  const onClear = () => {
    setOpenPicker("");
    openPicker === "start" ? setPowerOnTime("") : setPowerOfftime("");
  };

  const getTimeSettingsView = () => {
    let time = `${powerOnTime && powerOnTime} ${powerOnTime && powerOffTime ? "-" : ""} ${powerOffTime}`;
    return (
      <div>
        {scheduleCategory === scheduleCategories.weekly ?
          <div style={{ marginBottom: "10px" }}>
            <DaysList
              days={weekDaysArray}
              activeDays={weekDays}
              action={addRemoveDay}
              white={false}
              disablePointer
            />
          </div>
          : <DatesList dates={dates} />
        }
        <div className={classes.weekDaysConatiner}>
          {!!time.trim().length &&
            <Button
              disableRipple
              disableElevation
              disabled
              style={{ backgroundColor: "transparent", color: "black" }}
              onClick={() => { }}
              variant="contained"
              className={clsx(classes.cancelActionBtn, classes.rightTimeBtn, classes.timeBtns, classes.viewTimeBtns)}
            >
              {time}
            </Button>

          }
        </div>
      </div>
    );
  };
  const getTimeSettings = () => {
    return (
      <div className={classes.timeContainer}>
        <div>
          <Tabs
            value={scheduleCategory}
            onChange={(event: any, newValue: number) => setScheduleCategory(newValue)}
            variant="fullWidth"
            classes={{ root: classes.root, indicator: classes.indicatorColor }}
          >
            <Tab value={scheduleCategories.weekly} label={t`Weekly`} />
            <Tab value={scheduleCategories.calendar} label={t`Calendar`} />
          </Tabs>
          {scheduleCategory === scheduleCategories.weekly && <Grid container
            className={classes.scheduleContainer}
          >
            <Typography className={clsx(classes.selectModeStyle)}              >
              {t`Choose Days`}
            </Typography>
            <Grid container className={classes.daysContainer} id="days">
              <DaysList
                days={weekDaysArray}
                activeDays={weekDays}
                action={addRemoveDay}
                white={false}
              />
            </Grid>
            {(!!errors.weekDays && weekDays.length === 0) && <Typography variant="body2" className={classes.errorTitle}>{errors.weekDays} </Typography>}
          </Grid>}
          {scheduleCategory === scheduleCategories.calendar && <Grid
            container
            className={classes.tabContainer}
          >
            <Typography className={clsx(classes.selectModeStyle, classes.marginSelectModeStyleDates)}              >
              {t`Choose Dates`}
            </Typography>
            <Grid container className={classes.daysContainer} id="dates">
              <div>
                {!!errors.dates && <Typography variant="body2" className={classes.errorTitle}>{errors.dates} </Typography>}
                <DayPicker
                  selectedDays={dates}
                  onDayClick={handleDayClick}
                  className={classes.calendar}
                  disabledDays={{
                    before: today,
                    after: aYearFromToday
                  }}
                  fromMonth={today}
                  toMonth={aYearFromToday}
                />
              </div>
            </Grid>
          </Grid>}
        </div>
        <div>
          <div className={classes.divider}></div>
          <div style={{ display: "flex", flexFlow: "column", marginLeft: "5px" }}>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "7px" }}>
              <Checkbox
                disabled={!canEditScheule}
                checked={!allDay}
                onChange={toggleAllDay}
                name="checkedB"
                color="default"
              />
              <Typography className={classes.timeCheckbox}>{t`Daily Hours`}</Typography>
            </div>
            <div style={{ width: is12Hours ? "450px" : "350px", marginLeft: "-9px", marginBottom: "9px" }}>
              <StartEndTimePicker
                powerOnTime={powerOnTime}
                powerOffTime={powerOffTime}
                is12Hours={is12Hours}
                onSetTime={onSetTime}
                disabled={allDay || !canEditScheule}
              />
            </div>
          </div>
          {!!errors.time && <Typography variant="body2" className={classes.errorTitle}>{errors.time} </Typography>}
        </div>

        {!!openPicker &&
          <div className={classes.localTimePicker}>
            <TimePicker
              show={!!openPicker}
              onSet={onSetTime}
              time={openPicker === "start" ? powerOnTime : powerOffTime}
              onDismiss={() => setOpenPicker("")}
              onClear={onClear}
              overWriteCss={true}
              is12Hours={is12Hours}
              black={true}
            />
          </div>
        }
      </div>
    );
  };

  if (!props.canEdit) {
    return (
      <Card className={clsx(classes.modeContent, classes.modeContentUnitScheduleSettings, classes.viewModeContent)}>

        <Card className={clsx(classes.nameSettingContainer, classes.viewNameSettingContainer)}>
          <Typography className={clsx(classes.topTitle, classes.topTitlePosition, classes.viewTopTitle)}>{scheduleName}</Typography>
          <div>
            <LightTooltip title={t`Edit Schedule`}>
              <IconButton
                disableRipple
                onClick={props.onEditAction}
                className={classes.iconBtnStyle}
                disabled={props.disableAllSettings}
              >
                <EditIcon
                />
              </IconButton>
            </LightTooltip>

            <Delete
              type={t`Schedule`}
              object={props.selectedSchedule}
              detach={props.onDelete}
              disabled={props.disableAllSettings || !props.canDelete}
            />

            <CoolSwitch
              checked={!isDisabled}
              onChange={updateDisableState}
              disabled={!props.canEditScheule}
            />
          </div>
        </Card>

        <Card className={clsx(classes.timeSettingContainer, classes.viewTimeSettingContainer)}>
          {getTimeSettingsView()}
        </Card>

        <Card className={clsx(classes.tStatContainer, classes.tStatScheduleContainer)}>
          {getEditTStats(true)}
        </Card>

        {getEditLimits(true)}

      </Card>
    );
  }

  return (
    <div className={classes.dialogContainer}>
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{props.title}</Typography>
        <IconButton disableRipple onClick={handleCancel} className={classes.iconBtnStyle}>
          <Close color="#7f7692" />
        </IconButton>
      </div>
      <div className={classes.dialogContent}>
        <Card className={clsx(classes.nameSettingContainer, classes.editNameSettingContainer)}>
          <Typography className={clsx(classes.topTitle, classes.topTitlePosition)}>{t`Name`}</Typography>
          <TextField
            disabled={!canEditScheule}
            classes={{ root: classes.inputField }}
            InputProps={{ classes: { root: classes.nameInput }, disableUnderline: true }}
            value={scheduleName || ""}
            error={(!!errors.scheduleName && !scheduleName)}
            helperText={(!!errors.scheduleName && !scheduleName) && errors.scheduleName}
            onChange={(e) => {
              setScheduleName(e.target.value);
            }}

          />
        </Card>

        <Card className={clsx(classes.timeSettingContainer, classes.editTimeSettingContainer)}>
          {getTimeSettings()}
        </Card>

        <Card className={clsx(classes.tStatContainer, classes.tStatScheduleContainer)}>
          {getEditTStats()}
        </Card>
        {getEditLimits()}
        <Card className={classes.onEndSelect}>
          <div className={classes.endTypeLabel}>{t`On End:`}</div>
          {getOnEnd()}
        </Card>
      </div>
      <div className={classes.actionsHolder}>
        <div>
          <SButton disabled={!canEditScheule} white width={150} marginRight onClick={handleCancel}>
            {t`CANCEL`}
          </SButton>
          <SButton disabled={!canEditScheule || isSavingInprogress} width={150} onClick={handleSave}>
            {t`SAVE`}
          </SButton>
        </div>

      </div>
    </div>

  );

};

export default UnitSetting;
